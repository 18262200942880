import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import PropTypes from 'prop-types';
import { Grid, Paper, CardHeader, FormControl, TextField, ButtonGroup, Icon } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { format_without_time } from '../Utils';
import { AppContext } from '../AppContextProvider';
import ItemSelection from '../components/ItemSelection';
import moment from 'moment';
import { PropertyService } from '../Services/PropertyService';
import SingleDate from '../components/SingleDate';
import { TenantService } from '../Services/TenantService';

const useStyles = makeStyles((theme) => ({
	appBar: {
		position: 'relative',
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1,
		color: theme.palette.white
	},
	page: {
		height: '100%',
		backgroundColor: theme.palette.divider,
		overflow: 'auto'
	},
	section: {
		marginLeft: 16,
		marginRight: 16,
		minHeight: 200,
	},
	avatar: {
		backgroundColor: red[500],
	},
	image: {
		margin: 12,
		position: 'relative',
		height: 200,
		width: '90% !important',
		[theme.breakpoints.down('xs')]: {
			width: '100% !important', // Overrides inline-style
			height: 100,
		},
		'&:hover,  &Mui-focusVisible': {
			zIndex: 1,
			'& $imageBackdrop': {
				opacity: 0.7,
			},
			'& $imageMarked': {
				opacity: 0,
			},
			'& $imageTitle': {
				border: '4px solid currentColor',
			},
		},
	},
	imageBackdrop: {
		borderRadius: 8,
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		backgroundColor: theme.palette.common.black,
		opacity: 0.3,
		transition: theme.transitions.create('opacity'),
	},
	imageTitle: {
		position: 'relative',
		padding: `${theme.spacing(0)}px ${theme.spacing(4)}px ${theme.spacing(0)}px`,
	},
	imageMarked: {
		height: 3,
		width: 18,
		backgroundColor: theme.palette.common.white,
		position: 'absolute',
		bottom: -2,
		left: 'calc(50% - 9px)',
		transition: theme.transitions.create('opacity'),
	},
	imageButton: {
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: theme.palette.common.white,
	},
	imageButtonBottom: {
		position: 'absolute',
		left: 0,
		right: 0,
		// top: 0,
        // bottom: 0,
        bottom: 10,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: theme.palette.common.white,
	},
	formAutoClass: {
		zIndex: 3,
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
	card: {
		margin: 8
	}
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CreatePADialog(props) {
	const {showSnackBar, openModifyTenant, showProgressDialog, closeProgressDialog, showConfirmDialog, closeConfirmDialog, showConfirmDialog1D, closeConfirmDialog1D} = useContext(AppContext);
	const {id} = props;
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);
	const [propertyAgreement, setPropertyAgreement] = React.useState({start_date: moment(), end_date: moment(), renewal_date: moment()});
    const [properties, setProperties] = React.useState([]);
    const [tenants, setTenants] = React.useState([]);
	const [agreementFileOb, setAgreementFileOb] = React.useState(null);

	// const [loading, setLoading] = React.useState(false);
	const [errorList, setErrorList] = React.useState({});
	const [removeList, setRemoveList] = React.useState([]);

	React.useEffect(() => {
		setOpen(props.open);
		if(props.open){
			setPropertyAgreement({start_date: moment(), end_date: moment(), renewal_date: moment()});
			fetchMaster();
		}
		return () => {
			setPropertyAgreement({start_date: moment(), end_date: moment(), renewal_date: moment()});
			setOpen(false);
		}
	},[props.open]);

	const fetchMaster = () => {
		PropertyService.getPropertyNames()
		.then(data => {
			console.log(data);
			setProperties(data.filter(d => d.vacancy_status === 'VACANT'));
		})
		TenantService.getTenantNames()
		.then(data => {
			setTenants(data);
		})
	}

	React.useEffect(() => {
		if(properties && tenants){
			fetchData();
		}
	}, [properties, tenants]);

	const fetchData = () => {

		if(id && properties){
			// setLoading(true);
			showProgressDialog();
			PropertyService.getPTAById(id)
			.then(data => {
				console.log(data);
				if(data){
					let property = null, tenant = null;
					if(data.property_id){
						property = properties.find(p => p.id === data.property_id);
					}
					if(data.tenant_id){
						tenant = tenants.find(t => t.id === data.tenant_id);
					}
					setPropertyAgreement(propertyAgreement => ({...propertyAgreement, 
										id: data.id,
										property_id: data.property_id,
										property: property,
										tenant_id: data.tenant_id,
										tenant: tenant,
										type: 'RENT',
										start_date: data.start_date ? moment(data.start_date, format_without_time) : moment(),
										end_date: data.end_date ? moment(data.end_date, format_without_time) : moment(),
										status: data.status,
										amount: data.amount,
										rate: data.rate,
										escalation: data.escalation,
										renewal_date: data.renewal_date ? moment(data.renewal_date) : moment(),
										notice_period: data.notice_period,
										deposit: data.deposit,
										due_day: data.due_day,
										transaction_id: data.transaction_id || null,
										agreement: data.documents.length > 0 && data.documents.find(d => d.document_type === 'AGREEMENT')
									})			
					);
				}
				closeProgressDialog();
				// setLoading(false);
			})
			.catch(error => {
				console.log(error);
				showConfirmDialog1D('Something went wrong. Please try again.', undefined, () => {
					handleClose(false);
					closeConfirmDialog1D();
				}, 'OK');
				// setLoading(false);
				closeProgressDialog();
			});
		}
	}

	const handleClose = (showConfirm = false, shouldRefresh = false) => {
		if(showConfirm){
			showConfirmDialog('Are you sure you want to close. Any changes made will be lost?', undefined, () => {
				closeConfirmDialog();
				close(shouldRefresh);
			});
		} else {
			close(shouldRefresh);
		}
		
	};

	const close = (shouldRefresh = false) => {
		setPropertyAgreement({start_date: moment(), end_date: moment(), renewal_date: moment()});
		setErrorList({});
		setAgreementFileOb(null);
		props.onClose(shouldRefresh);
		setOpen(false);
	}

	const handleChange = field => e => {
		let value = e.target.value;
		setPropertyAgreement(property => ({...property, [field]: value}));
		setErrorList(e => ({...e, [field] : !value}));
	}

	const changePhoto = (field, file) => {
		console.log(field, file);
		if(field === 'AGREEMENT'){
			setAgreementFileOb(file);
		}

		if(!file){
			setPropertyAgreement(property => ({...property, [field]: null}));
		}
	}

	const savePropertyAgreement = () => {
		if(isValid()){
			showConfirmDialog('Confirm Save Agreement', undefined, () => {
				closeConfirmDialog();
				save();
			})
		}
	}

	const isValid = () => {
		let valid = true;
		if(!propertyAgreement.property){
			valid = false;
		}
		setErrorList(e => ({...e, property: !propertyAgreement.property}));
		if(!propertyAgreement.tenant){
			valid = false;
		}
		setErrorList(e => ({...e, tenant: !propertyAgreement.tenant}));
		if(!propertyAgreement.start_date){
			valid = false;
		}
		setErrorList(e => ({...e, start_date: !propertyAgreement.start_date}));
		if(!propertyAgreement.end_date){
			valid = false;
		}
		setErrorList(e => ({...e, end_date: !propertyAgreement.end_date}))
		if(!propertyAgreement.amount){
			valid = false;
		}
		setErrorList(e => ({...e, amount: !propertyAgreement.amount}));
		if(!propertyAgreement.deposit){
			valid = false;
		}
		setErrorList(e => ({...e, deposit: !propertyAgreement.deposit}));
		if(!propertyAgreement.due_day){
			valid = false;
		}
		setErrorList(e => ({...e, due_day: !propertyAgreement.due_day}));

		return valid;
	}

	const save = () => {
		// setLoading(true);
		const formData = {};
		formData.id = propertyAgreement.id || null;
		formData.property_id = propertyAgreement.property.id;
		formData.tenant_id = propertyAgreement.tenant.id;
		formData.type = propertyAgreement.type || 'RENT';
		formData.start_date = propertyAgreement.start_date ? moment(propertyAgreement.start_date).format(format_without_time) : null;
		formData.end_date = propertyAgreement.end_date ? moment(propertyAgreement.end_date).format(format_without_time) : null;
		formData.renewal_date = propertyAgreement.renewal_date ? moment(propertyAgreement.renewal_date).format(format_without_time) : null;
		formData.amount = propertyAgreement.amount || null;
		formData.rate = propertyAgreement.rate || null;
		formData.escalation = propertyAgreement.escalation || 0;
		formData.notice_period = propertyAgreement.notice_period || null;
		formData.deposit = propertyAgreement.deposit || null;
		formData.due_day = propertyAgreement.due_day || null;
		formData.transaction_id = propertyAgreement.transaction_id || null;
		console.log(formData);
		if(!formData.id){
			showProgressDialog('Creating Agreement...');
		} else {
			showProgressDialog('Saving Agreement...');
		}

		PropertyService.savePTA(formData)
		.then(data => {
			if(data.success && data.id){
				formData.id = data.id;
				step2(formData);
			} else {
				closeProgressDialog();
				showConfirmDialog1D('Something went wrong. Please try again.', undefined, () => {
					closeConfirmDialog1D();
				}, 'OK');
			}
		})
	}
	
	// Save Agreement Doc
	const step2 = async (formData = null) => {
		try{
			if(formData && agreementFileOb){
				showProgressDialog('Saving Document...');
				
				const d = new FormData();
				d.append('file', agreementFileOb || null);
				await PropertyService.addPTADocument(formData.id, 'AGREEMENT', d);
				if(formData && removeList.length > 0){
					await Promise.all(removeList.map((doc) => PropertyService.removePTADocument(doc.id) ));
				}
				closeProgressDialog();
				handleClose(false, true);
			} else {
				closeProgressDialog();
				handleClose(false, true);
			}

		} catch(error){
			closeProgressDialog();
			showConfirmDialog1D('Something went wrong. Please try again.', undefined, () => {
				closeConfirmDialog1D();
			}, 'OK');
		}
	}

	const removeDocument = (type, id) => {
		console.log(id);
		setRemoveList(list => ([...list, {type, id}]));
		if(type === 'PHOTO'){
			setPropertyAgreement(property => ({...property, PHOTO: [...property.PHOTO.filter(p => p.id !== id)]}));
		} else {
			setPropertyAgreement(property => ({...property, [type]: []}));
		}
	}

	const addTenant = () => {
		openModifyTenant(null, () => {
				fetchMaster();
				showSnackBar('Now Select the new Tenant', 'info');
		});
	}

  	return (
		<Dialog fullScreen disableBackdropClick disableEscapeKeyDown open={open} onClose={() => handleClose(true)} TransitionComponent={Transition}>
			<AppBar className={classes.appBar}>
				<Toolbar>
				<IconButton edge="start" color="inherit" onClick={() => handleClose(true)} aria-label="close">
					<CloseIcon />
				</IconButton>
				<Typography variant="h6" className={classes.title}>
					{`${id ? '' : 'New '}Agreement`}
				</Typography>
				<Button autoFocus color="inherit" onClick={savePropertyAgreement}>
					save
				</Button>
				</Toolbar>
			</AppBar>
			<Grid container className={classes.page} justify="center">
				<Grid container item spacing={2} xs={12} lg={10} alignItems="flex-start" justify="center" direction="row">
					<Grid item xs={12} lg={12}>
						<Paper variant="outlined" className={classes.section} elevation={3}>
							<CardHeader
								title="Agreement Information"
								titleTypographyProps={{variant: 'h4'}}
							/>
							<Divider/>
							<div style={{minHeight: 100, marginRight: 16, marginLeft: 16, marginTop: 8, marginBottom: 16}}>
								<Grid container justify="center" spacing={2} alignItems="flex-start">
									<Grid item xs={12} lg={12} container justify="center" spacing={2} alignItems="center">
										<Grid item xs={12} lg={4}>
											<ItemSelection
												clearable={true} 
												size="medium"
												optionLabel='name'
												disabled={propertyAgreement.id ? true : false}
												label='Property' 
												value={propertyAgreement.property || null} 
												labelTemplate={(option) => `${option.name} ${option.nickname ? `(${option.nickname})`: ''}`}
												options={properties} 
												selected={(value) => {
													setPropertyAgreement(propertyAgreement => ({...propertyAgreement, property: value}));
												}}/>
										</Grid>
										<Grid item xs={12} lg={4}>
											<ItemSelection
												clearable={true} 
												size="medium"
												disabled={propertyAgreement.id ? true : false}
												optionLabel='name'
												label='Tenant' 
												value={propertyAgreement.tenant || null}
												options={tenants} 
												selected={(value) => {
													setPropertyAgreement(propertyAgreement => ({...propertyAgreement, tenant: value}));
												}}/>
										</Grid>
										<Grid item xs={12} lg={1}>
											<IconButton disabled={propertyAgreement.id ? true : false} color="primary" onClick={fetchMaster}>
												<Icon>refresh</Icon>
											</IconButton>
										</Grid>
										<Grid item xs={12} lg={3}>
											<Button variant="outlined" disabled={propertyAgreement.id ? true : false} onClick={addTenant}>Add New Tenant</Button>
										</Grid>
										<Grid item xs={12} lg={2}>
											<div style={{maxWidth: '100%'}}>
												<SingleDate 
													label="Start"
													date={propertyAgreement.start_date || moment()}
													maxDate={propertyAgreement.start_date || moment()}
													setDate={(date) => {
														setPropertyAgreement(propertyAgreement => ({...propertyAgreement, start_date: date}));
													}}/>
											</div>
										</Grid>
										<Grid item xs={12} lg={2}>
											<div style={{maxWidth: '100%'}}>
												<SingleDate 
													label="End"
													date={propertyAgreement.end_date || moment()}
													minDate={propertyAgreement.start_date || moment()}
													setDate={(date) => {
														setPropertyAgreement(propertyAgreement => ({...propertyAgreement, end_date: date}));
													}}/>
											</div>
										</Grid>
										<Grid item xs={12} lg={2}>
											<div style={{maxWidth: '100%'}}>
												<SingleDate 
													label="Renewal Date"
													date={propertyAgreement.renewal_date || moment()}
													setDate={(date) => {
														setPropertyAgreement(propertyAgreement => ({...propertyAgreement, renewal_date: date}));
													}}/>
											</div>
										</Grid>
										<Grid item xs={6} lg={2}>
											<FormControl variant="outlined" fullWidth>
												<TextField
													label="Rent"
													margin="none"
													required
													error={errorList.amount}
													id="outlined-adornment-amount"
													value={propertyAgreement.amount || ''}
													onChange={handleChange('amount')}
													aria-describedby="outlined-weight-helper-text"
													inputProps={{
														'aria-label': 'amount',
														maxLength: 15,
														type: 'number'
													}}
													variant="outlined"
												/>
											</FormControl>
										</Grid>
										<Grid item xs={6} lg={2}>
											<FormControl variant="outlined" fullWidth>
												<TextField
													label="Rate Per Area Unit"
													margin="none"
													error={errorList.rate}
													id="outlined-adornment-rate"
													value={propertyAgreement.rate || ''}
													onChange={handleChange('rate')}
													aria-describedby="outlined-weight-helper-text"
													inputProps={{
														'aria-label': 'rate',
														maxLength: 15,
														type: 'number'
													}}
													variant="outlined"
												/>
											</FormControl>
										</Grid>
										<Grid item xs={6} lg={2}>
											<FormControl variant="outlined" fullWidth>
												<TextField
													label="Escalation"
													margin="none"
													error={errorList.escalation}
													id="outlined-adornment-escalation"
													value={propertyAgreement.escalation || ''}
													onChange={handleChange('escalation')}
													aria-describedby="outlined-weight-helper-text"
													inputProps={{
														'aria-label': 'escalation',
														maxLength: 4,
														type: 'number'
													}}
													variant="outlined"
												/>
											</FormControl>
										</Grid>
										<Grid item xs={6} lg={2}>
											<FormControl variant="outlined" fullWidth>
												<TextField
													label="Notice Period"
													margin="none"
													error={errorList.notice_period}
													id="outlined-adornment-escalation"
													value={propertyAgreement.notice_period || ''}
													onChange={handleChange('notice_period')}
													aria-describedby="outlined-weight-helper-text"
													inputProps={{
														'aria-label': 'notice_period',
														maxLength: 2,
														type: 'number'
													}}
													variant="outlined"
													helperText="In Months"
												/>
											</FormControl>
										</Grid>
										<Grid item xs={6} lg={2}>
											<FormControl variant="outlined" fullWidth>
												<TextField
													label="Deposit"
													margin="none"
													required
													error={errorList.deposit}
													id="outlined-adornment-deposit"
													value={propertyAgreement.deposit || ''}
													onChange={handleChange('deposit')}
													aria-describedby="outlined-weight-helper-text"
													inputProps={{
														'aria-label': 'deposit',
														maxLength: 15,
														type: 'number'
													}}
													style={{marginBottom: 24}}
													variant="outlined"
												/>
											</FormControl>
										</Grid>
										<Grid item xs={6} lg={2}>
											<FormControl variant="outlined" fullWidth>
												<TextField
													label="Due On"
													margin="none"
													required
													error={errorList.due_day}
													id="outlined-adornment-due_day"
													value={propertyAgreement.due_day || ''}
													onChange={handleChange('due_day')}
													aria-describedby="outlined-weight-helper-text"
													inputProps={{
														'aria-label': 'due_day',
														maxLength: 2,
														type: 'number'
													}}
													variant="outlined"
													helperText="Day of the Month"
												/>
											</FormControl>
										</Grid>
										<Grid item xs={6} lg={4}>
											<FormControl variant="outlined" fullWidth>
												<TextField
													label="Transaction Reference"
													margin="none"
													error={errorList.transaction_id}
													id="outlined-adornment-transaction_id"
													value={propertyAgreement.transaction_id || ''}
													onChange={handleChange('transaction_id')}
													aria-describedby="outlined-weight-helper-text"
													inputProps={{
														'aria-label': 'transaction_id',
														maxLength: 20
													}}
													style={{marginBottom: 24}}
													variant="outlined"
												/>
											</FormControl>
										</Grid>
										<Grid item xs={6} lg={2}>
										{
											<div style={{marginBottom: 24}}>
												<DocumentPlaceHolder
													error={false}
													title="Add Document"
													uploadText="Upload Agreement"
													link={propertyAgreement.agreement ? propertyAgreement.agreement.link : null} 
													change={(file) => {
														changePhoto('AGREEMENT', file);
													}} 
													remove={(newDocument) => {
														if(newDocument){
															changePhoto('AGREEMENT', null);
														} else {
															removeDocument('AGREEMENT', propertyAgreement.agreement.id)
														}
													}}
												/>
											</div>
										}
										</Grid>
										
									</Grid>
								</Grid>
							</div>
						</Paper>
					</Grid>
					{/* <Grid item xs={12} lg={12}>
						<Paper variant="outlined" style={{marginLeft: 16, marginRight: 16}} elevation={3}>
							<div style={{marginRight: 16, marginLeft: 16, marginTop: 16, marginBottom: 16}}>
								<Grid container justify="flex-start" spacing={2} alignItems="center">
									<Grid item xs={12} lg={12}>
										<div style={{float: 'right'}}>
											<Button variant="contained" color="primary" onClick={saveProperty}>Save</Button>
										</div>
									</Grid>
								</Grid>
							</div>
						</Paper>
					</Grid> */}
				</Grid>
			</Grid>
		</Dialog>
	);
}

function DocumentPlaceHolder(props){
	// let classes = useStyles();
	// const {error} = props;
	const [link, setLink] = React.useState(null);
	const [newFile, setNewFile] = React.useState(null);

	const hiddenFileInput = React.useRef(null);

	React.useEffect(() => {
		setLink(props.link);
	}, [props.link]);

    const handleClick = () => {
        hiddenFileInput.current.click();
	}
	
	const change = (event) => {
		const fileUploaded = event.target.files[0];
		setNewFile(fileUploaded);
		// setLink(URL.createObjectURL(fileUploaded));
		props.change(fileUploaded);
	}
	


	return (
		<div>
			<ButtonGroup disableElevation size="large" color="inherit" aria-label="outlined primary button group">
				{
					link && (
						<Button 
							style={{color: red[500]}} 
							onClick={() => {
								setLink(null);
								props.remove(false)
							}}>Remove</Button>
					)
				}
				{
					!link && !newFile && (
						<Button variant="contained" color="primary" onClick={handleClick}>{props.uploadText || 'Upload'}</Button>
					)
				}
				{
					newFile && (
						<Button 
							style={{color: red[500]}}
							onClick={() => {
								props.remove(true);
								setNewFile(null);
							}}>
							Remove Selected
						</Button>
					)
				}
			</ButtonGroup>
			<input accept="image/*,application/pdf" type="file" onChange={change} onClick={(event) =>  event.target.value = null} ref={hiddenFileInput} style={{display:'none'}} />
		</div>
	)
}

CreatePADialog.prototype = {
    open: PropTypes.bool.isRequired,
    id: PropTypes.string,
    onClose: PropTypes.func.isRequired
};