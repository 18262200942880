export const Constants = {
  EXECUTIVE: "EXECUTIVE",
  CUSTOMER: "CUSTOMER",
  LR: "LR",
  CR: "CR",
  IP: "IP",
  ER: "ER",
};

export const STATUS = {
  VACANT: "VACANT",
  OCCUPIED: "OCCUPIED",
  UNDERMAINTENANCE: "UNDER_MAINTENANCE",
};

export const STATUS_OPTIONS = [
  { key: 'VACANT', value: 'Vacant'},
  { key: 'OCCUPIED', value: 'Occupied'},
  { key: 'UNDER_MAINTENANCE', value: 'Under Maintenance'}
];

export const INVOICE_STATUS_OPTIONS = [ 
  {key: 'Generated', value: 'GENERATED'}, 
  {key: 'Sent', value: 'SENT'},
  {key: 'Paid', value: 'PAID'},
  {key: 'Canceled', value: 'CANCELED'}
];



export const REQUEST_TYPE = {
  LR: "Loan Request",
  ER: "Enhancement Request",
  IP: "Interest Payment",
  CR: "Close Request",
};

export const LOAN_TYPES = [
  { key: "LR", value: "Loan Request" },
  { key: "ER", value: "Enhancement Request" },
  { key: "IP", value: "Interest Payment" },
  { key: "CR", value: "Close Request" },
];

export const SMS_TYPE = {
  C: "cancel",
  U: "unserviceable",
  A: "assigned",
};

export const TABLE_COUNTS = {
  tenant_tab_count: "caretaker.tenant_tab_count",
  property_tab_count: "caretaker.property_tab_count",
  report_outstanding_loan_tab_count: "caretaker.report_outstanding_loan_tab_count",
  report_risk_loan_tab_count: "caretaker.report_risk_loan_tab_count",
}

export const TXN_TYPE = {
  D: "DEBIT",
  C: "CREDIT",
  CASH: "CASH",
  BANK: "BANK",
  JOURNAL: "JOURNAL",
  GENERAL: "GENERAL",
};

export const PAYMENT_TYPE = {
  P : "Payment",
  R : "Receipt"
};