import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import PropTypes from 'prop-types';
import { Grid, Paper, CardHeader, ButtonBase, FormControl, InputAdornment, TextField, Switch, ButtonGroup } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { format_display, format, toInt, format_year } from '../Utils';
import { AppContext } from '../AppContextProvider';
import ItemSelection from '../components/ItemSelection';
import moment from 'moment';
import DeleteIcon from '@material-ui/icons/Delete';
import { PropertyService } from '../Services/PropertyService';

const useStyles = makeStyles((theme) => ({
	appBar: {
		position: 'relative',
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1,
		color: theme.palette.white
	},
	page: {
		height: '100%',
		backgroundColor: theme.palette.divider,
		overflow: 'auto'
	},
	section: {
		marginLeft: 16,
		marginRight: 16,
		minHeight: 200,
	},
	avatar: {
		backgroundColor: red[500],
	},
	image: {
		margin: 12,
		position: 'relative',
		height: 200,
		width: '90% !important',
		[theme.breakpoints.down('xs')]: {
			width: '100% !important', // Overrides inline-style
			height: 100,
		},
		'&:hover,  &Mui-focusVisible': {
			zIndex: 1,
			'& $imageBackdrop': {
				opacity: 0.7,
			},
			'& $imageMarked': {
				opacity: 0,
			},
			'& $imageTitle': {
				border: '4px solid currentColor',
			},
		},
	},
	imageBackdrop: {
		borderRadius: 8,
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		backgroundColor: theme.palette.common.black,
		opacity: 0.3,
		transition: theme.transitions.create('opacity'),
	},
	imageTitle: {
		position: 'relative',
		padding: `${theme.spacing(0)}px ${theme.spacing(4)}px ${theme.spacing(0)}px`,
	},
	imageMarked: {
		height: 3,
		width: 18,
		backgroundColor: theme.palette.common.white,
		position: 'absolute',
		bottom: -2,
		left: 'calc(50% - 9px)',
		transition: theme.transitions.create('opacity'),
	},
	imageButton: {
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: theme.palette.common.white,
	},
	imageButtonBottom: {
		position: 'absolute',
		left: 0,
		right: 0,
		// top: 0,
        // bottom: 0,
        bottom: 10,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: theme.palette.common.white,
	},
	formAutoClass: {
		zIndex: 3,
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
	card: {
		margin: 8
	}
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CreatePropertyDialog(props) {
	const {state, showProgressDialog, closeProgressDialog, showConfirmDialog, closeConfirmDialog, showConfirmDialog1D, closeConfirmDialog1D} = useContext(AppContext);
	const {id} = props;
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);
	const [types, setTypes] = React.useState([]);
	const [property, setProperty] = React.useState({PHOTO: [], alert_config: {"alert_rent_due": false,
		"rent_due_days": 0,
		"alert_agreement_due": false,
		"agreement_due_days": 0,
		"alert_tax_due": false,
		"tax_due_days": 0}
	});

	const [photosFileOb, setPhotosFileOb] = React.useState([]);
	const [landTaxFileOb, setLandTaxFileOb] = React.useState(null);
	const [taxReceiptFileOb, setTaxReceiptFileOb] = React.useState(null);
	const [drawingPlanFileOb, setDrawingPlanFileOb] = React.useState(null);
	const [sketchFileOb, setSketchFileOb] = React.useState(null);

	const [units, setUnits] = React.useState([]);
	// const [loading, setLoading] = React.useState(false);
	const [errorList, setErrorList] = React.useState({});
	const [removeList, setRemoveList] = React.useState([]);

	React.useEffect(() => {
		setOpen(props.open);
		if(props.open){
			setProperty({PHOTO: [], alert_config: {"alert_rent_due": false,
			"rent_due_days": 0,
			"alert_agreement_due": false,
			"agreement_due_days": 0,
			"alert_tax_due": false,
			"tax_due_days": 0}});
			fetchData();
		}
		return () => {
			setProperty({PHOTO: [], alert_config: {"alert_rent_due": false,
			"rent_due_days": 0,
			"alert_agreement_due": false,
			"agreement_due_days": 0,
			"alert_tax_due": false,
			"tax_due_days": 0}});
			setOpen(false);
		}
	},[props.open]);

	const fetchData = () => {

		let types = [];
		types.push({name: 'RESIDENTIAL', value: 'RESIDENTIAL'});
		types.push({name: 'COMMERCIAL', value: 'COMMERCIAL'});
		types.push({name: 'INDUSTRIAL', value: 'INDUSTRIAL'});
		types.push({name: 'LAND', value: 'LAND'});
		setTypes(types);

		let units = [];
		units.push({name: 'sq ft', value: 'sq ft'});
		units.push({name: 'sq m', value: 'sq m'});
		units.push({name: 'sq km', value: 'sq km'});
		units.push({name: 'sq yd', value: 'sq yd'});
		units.push({name: 'Acre', value: 'Acre'});
		units.push({name: 'Hectare', value: 'Hectare'});
		units.push({name: 'Cent', value: 'Cent'});
		setUnits(units);

		if(id){
			// setLoading(true);
			showProgressDialog();
			PropertyService.getPropertyById(id)
			.then(data => {
				console.log(data);
				if(data){
					let unit = null;
					if(data.area_unit){
						unit = units.find(u => u.value === data.area_unit);
					}
					let typeOb = null;
					if(data.type){
						typeOb = types.find(u => u.value === data.type);
					}
					setProperty(property => ({...property, 
										id: data.id, 
										unit: unit,
										name: data.name,
										nickname: data.nickname,
										address1: data.address1,
										address2: data.address2,
										city: data.city,
										state: data.state,
										pincode: data.pincode,
										typeOb,
										identifier: data.identifier,
										geo_coordinates: data.geo_coordinates,
										building_no: data.building_no,
										area: data.area,
										land_tax_date: data.land_tax_date,
										tax_receipt_date: data.tax_receipt_date ? moment(data.tax_receipt_date).format(format_display) : null,
										measurement: data.measurement,
										plinth_area: data.plinth_area,
										carpet_area: data.carpet_area,
										comments: data.comments,
										PHOTO: data.PHOTO || [],
										LANDTAX: data.LANDTAX || [],
										TAXRECEIPT: data.TAXRECEIPT || [],
										DRAWINGPLAN: data.DRAWINGPLAN || [],
										SKETCH: data.SKETCH || [],
										alert_config: data.alert_config,
									})			
					);
				}
				closeProgressDialog();
				// setLoading(false);
			})
			.catch(error => {
				console.log(error);
				showConfirmDialog1D('Something went wrong. Please try again.', undefined, () => {
					handleClose(false);
					closeConfirmDialog1D();
				}, 'OK');
				// setLoading(false);
				closeProgressDialog();
			});
		}
	}

	const handleClose = (showConfirm = false, shouldRefresh = false) => {
		if(showConfirm){
			showConfirmDialog('Are you sure you want to close. Any changes made will be lost?', undefined, () => {
				closeConfirmDialog();
				close(shouldRefresh);
			});
		} else {
			close(shouldRefresh);
		}
		
	};

	const close = (shouldRefresh = false) => {
		setProperty({PHOTO: [], alert_config: {"alert_rent_due": false,
		"rent_due_days": 0,
		"alert_agreement_due": false,
		"agreement_due_days": 0,
		"alert_tax_due": false,
		"tax_due_days": 0}});
		setErrorList({});
		setPhotosFileOb([]);
		setLandTaxFileOb(null);
		setTaxReceiptFileOb(null);
		setDrawingPlanFileOb(null);
		setSketchFileOb(null);
		props.onClose(shouldRefresh);
		setOpen(false);
	}

	const handleChange = field => e => {
		let value = e.target.value;
		setProperty(property => ({...property, [field]: value}));
		setErrorList(e => ({...e, [field] : !value}));
	}

	const handleAlertChange = (type, isCheckbox) => e => {
		e.persist();
		setProperty(property => ({...property, alert_config: {...property.alert_config, [type] : isCheckbox ? e.target.checked : e.target.value}}))
	}

	const changePhoto = (field, file) => {
		console.log(field, file);
		if(field === 'PHOTO'){
			setPhotosFileOb(photos => ([...photos, file]));
		} else if(field === 'LANDTAX'){
			setLandTaxFileOb(file);
		} else if(field === 'TAXRECEIPT'){
			setTaxReceiptFileOb(file);
		} else if(field === 'DRAWINGPLAN'){
			setDrawingPlanFileOb(file);
		} else if(field === 'SKETCH'){
			setSketchFileOb(file);
		}

		if(!file){
			setProperty(property => ({...property, [field]: null}));
		}
	}

	const saveProperty = () => {
		if(isValid()){
			showConfirmDialog('Confirm Save Property', undefined, () => {
				closeConfirmDialog();
				save();
			})
		}
	}

	const isValid = () => {
		let valid = true;
		if(!property.name){
			valid = false;
		}
		setErrorList(e => ({...e, name: !property.name}))
		// if(!property.nickname){
		// 	valid = false;
		// }
		// setErrorList(e => ({...e, nickname: !property.nickname}))
		if(!property.address1){
			valid = false;
		}
		setErrorList(e => ({...e, address1: !property.address1}))
		if(!property.city){
			valid = false;
		}
		setErrorList(e => ({...e, city: !property.city}))
		if(!property.state){
			valid = false;
		}
		setErrorList(e => ({...e, state: !property.state}))
		if(!property.pincode){
			valid = false;
		}
		setErrorList(e => ({...e, pincode: !property.pincode}))
		if(!property.typeOb){
			valid = false;
		}
		setErrorList(e => ({...e, typeOb: !property.typeOb}));
		if(!property.area){
			valid = false;
		}
		setErrorList(e => ({...e, area: !property.area}));
		if(!property.unit){
			valid = false;
		}
		setErrorList(e => ({...e, unit: !property.unit}));
		if(property.tax_receipt_date && property.tax_receipt_date.length > 0){
			let x =  moment(property.tax_receipt_date, format_display);
			if(!x.isValid()){
				valid = false;
				setErrorList(e => ({...e, tax_receipt_date: true}));
			}
		} else {
			setErrorList(e => ({...e, tax_receipt_date: false}));
		}

		return valid;
	}

	const save = () => {
		// setLoading(true);
		const formData = {};
		formData.id = property.id || null;
		formData.name = property.name || '';
		formData.nickname = property.nickname || '';
		formData.address1 = property.address1 || '';
		formData.address2 = property.address2 || null;
		formData.city = property.city || '';;
		formData.state = property.state || '';;
		formData.pincode = property.pincode || '';;
		formData.geo_coordinates = property.geo_coordinates || null;
		formData.area = property.area || null;
		formData.area_unit = property.unit ? property.unit.value : null;
		formData.land_tax_date = property.land_tax_date || null;
		formData.building_no = property.building_no || null;
		formData.identification = property.identification || null;
		formData.tax_receipt_date = property.tax_receipt_date ? moment(property.tax_receipt_date, format_display).startOf('d').format(format) : null;
		formData.purchase_date = property.purchase_date || null;
		formData.measurement = property.measurement || null;
		formData.plinth_area = property.plinth_area || null;
		formData.carpet_area = property.carpet_area || null;
		formData.type = property.typeOb ? property.typeOb.value : 'COMMERCIAL';
		formData.comments = property.comments || null;
		console.log(formData);
		if(!formData.id){
			showProgressDialog('Creating Property...');
		} else {
			showProgressDialog('Saving Property...');
		}

		PropertyService.saveProperty(formData)
		.then(data => {
			if(data.success && data.id){
				formData.id = data.id;
				step2(formData);
			} else {
				closeProgressDialog();
				showConfirmDialog1D('Something went wrong. Please try again.', undefined, () => {
					closeConfirmDialog1D();
				}, 'OK');
			}
		})
	}
	
	//Save Alert
	const step2 = (formData = null) => {
		if(formData){
			showProgressDialog('Saving Alert Config...');
			let alertConfig = property.alert_config || {};
			alertConfig.p_id = formData.id;
			PropertyService.savePropertyAlertConfig({}, alertConfig)
			.then(data => {
				if(data.success){
					step3(formData);
				}
			})
			.catch(error => {
				console.log(error);
				showConfirmDialog1D('Something went wrong. Please try again.', undefined, () => {
					closeConfirmDialog1D();
				}, 'OK');
			})
		}
	}

	//Uploading Files
	const step3 = async (formData) => {
		try{
			if(formData && landTaxFileOb){
				showProgressDialog('Uploading Land tax...');
				const d = new FormData();
				d.append('file', landTaxFileOb || null);
				await PropertyService.addPropertyDocument(formData.id, 'LANDTAX', d);
			}
			if(formData && taxReceiptFileOb){
				showProgressDialog('Uploading Tax Receipt...');
				const d = new FormData();
				d.append('file', taxReceiptFileOb || null);
				await PropertyService.addPropertyDocument(formData.id, 'TAXRECEIPT', d);
			}
			if(formData && drawingPlanFileOb){
				showProgressDialog('Uploading Drawing Plan...');
				const d = new FormData();
				d.append('file', drawingPlanFileOb || null);
				await PropertyService.addPropertyDocument(formData.id, 'DRAWINGPLAN', d);
			}
			if(formData && sketchFileOb){
				showProgressDialog('Uploading Sketch...');
				const d = new FormData();
				d.append('file', sketchFileOb || null);
				await PropertyService.addPropertyDocument(formData.id, 'SKETCH', d);
			}
			if(formData && removeList.length > 0){
				await Promise.all(removeList.map((photo) => PropertyService.removePropertyDocument(photo.id) ));
			}
			if(formData && photosFileOb.length > 0){
				await Promise.all(photosFileOb.map((photo, index) => {
					showProgressDialog(`Uploading Photo ${index+1} / ${photosFileOb.length}`);
					const d = new FormData();
					d.append('file', photo || null);
					return PropertyService.addPropertyDocument(formData.id, 'PHOTO', d);
				}));
			}
			closeProgressDialog();
			handleClose(false, true);

		} catch(error){
			closeProgressDialog();
			showConfirmDialog1D('Something went wrong. Please try again.', undefined, () => {
				closeConfirmDialog1D();
			}, 'OK');
		}
		
	}

	const removeDocument = (type, id) => {
		console.log(id);
		setRemoveList(list => ([...list, {type, id}]));
		if(type === 'PHOTO'){
			setProperty(property => ({...property, PHOTO: [...property.PHOTO.filter(p => p.id !== id)]}));
		} else {
			setProperty(property => ({...property, [type]: []}));
		}
		// showConfirmDialog('Are you sure you want to remove file?', undefined, () => {
		// 	closeConfirmDialog();
		// 	PropertyService.removePropertyDocument(id)
		// 	.then(() => {
		// 		showSnackBar('Removed document', 'info', 1000);
		// 		// fetchData();
		// 	})
		// 	.catch(error => {
		// 		console.log(error);
		// 		showSnackBar('Something went wrong. Please try again.', 'error', 1000);
		// 	})
		// });
	}

  	return (
		<Dialog fullScreen disableBackdropClick disableEscapeKeyDown open={open} onClose={() => handleClose(true)} TransitionComponent={Transition}>
			<AppBar className={classes.appBar}>
				<Toolbar>
				<IconButton edge="start" color="inherit" onClick={() => handleClose(true)} aria-label="close">
					<CloseIcon />
				</IconButton>
				<Typography variant="h6" className={classes.title}>
					{`${id ? '' : 'New '}Property`}
				</Typography>
				{/* <Button autoFocus color="inherit" onClick={handleClose}>
					save
				</Button> */}
				</Toolbar>
			</AppBar>
			{/* <Backdrop className={classes.backdrop} open={loading} onClick={() => {}}>
				<div style={{padding: 50, backgroundColor: 'white', borderRadius: 8}}>
					<CircularProgress 
							size={40}
							thickness={4}/>
				</div>
			</Backdrop> */}
			<Grid container className={classes.page} justify="center">
				<Grid container item spacing={2} xs={12} lg={10} alignItems="flex-start" justify="center" direction="row">
					<Grid item xs={12} lg={12}>
						<Paper variant="outlined" className={classes.section} elevation={3}>
							<CardHeader
								title="Property information"
								titleTypographyProps={{variant: 'h4'}}
							/>
							<Divider/>
							<div style={{minHeight: 100, marginRight: 16, marginLeft: 16, marginTop: 8, marginBottom: 16}}>
								<Grid container justify="center" spacing={2} alignItems="flex-start">
									<Grid item xs={12} lg={12} container justify="center" spacing={2} alignItems="flex-start">
										<Grid item xs={12} lg={4}>
											<FormControl variant="outlined" fullWidth>
												<TextField
													label="Name"
													id="outlined-adornment-name"
													value={property.name || ''}
													required
													error={errorList.name}
													margin="none"
													onChange={handleChange('name')}
													aria-describedby="outlined-weight-helper-text"
													variant="outlined"
												/>
											</FormControl>
										</Grid>
										<Grid item xs={12} lg={4}>
											<FormControl variant="outlined" fullWidth>
												<TextField
													label="Nickname"
													margin="none"
													id="outlined-adornment-nickname"
													value={property.nickname || ''}
													onChange={handleChange('nickname')}
													aria-describedby="outlined-weight-helper-text"
													variant="outlined"
												/>
											</FormControl>
										</Grid>
										<Grid item xs={12} lg={4}>
											<ItemSelection
												required 
												size="medium"
												label='Type'
												value={property.typeOb || null} 
												optionLabel='name'
												error={errorList.typeOb}
												options={types} 
												style={{zIndex: 500, marginTop: 0, marginLeft: 0}}
												formClass={classes.formAutoClass}
												selected={(value) => {
													setProperty(property => ({...property, typeOb: value}));
												}}/>
										</Grid>
										<Grid item xs={12} lg={6}>
											<FormControl variant="outlined" fullWidth>
												<TextField
													label="Address Line 1"
													margin="none"
													id="outlined-adornment-customer-address1"
													value={property.address1 || ''}
													required
													error={errorList.address1}
													onChange={handleChange('address1')}
													aria-describedby="outlined-weight-helper-text"
													InputProps={{
														'aria-label': 'customer-address1',
														maxLength: 100
													}}
													variant="outlined"
												/>
											</FormControl>
										</Grid>
										<Grid item xs={12} lg={6}>
											<FormControl variant="outlined" fullWidth>
												<TextField
													label="Address Line 2"
													margin="none"
													id="outlined-adornment-customer-address2"
													value={property.customer_address2 || ''}
													onChange={handleChange('customer_address2')}
													aria-describedby="outlined-weight-helper-text"
													InputProps={{
														'aria-label': 'customer-address2',
														maxLength: 100
													}}
													variant="outlined"
												/>
											</FormControl>
										</Grid>
										<Grid item xs={12} lg={4}>
											<FormControl variant="outlined" fullWidth>
												<TextField
													label="City"
													margin="none"
													id="outlined-adornment-city"
													value={property.city || ''}
													required
													error={errorList.city}
													onChange={handleChange('city')}
													aria-describedby="outlined-weight-helper-text"
													InputProps={{
														'aria-label': 'city',
														maxLength: 100
													}}
													variant="outlined"
												/>
											</FormControl>
										</Grid>
										<Grid item xs={12} lg={3}>
											<FormControl variant="outlined" fullWidth>
												<TextField
													label="State"
													margin="none"
													id="outlined-adornment-state"
													value={property.state || ''}
													required
													error={errorList.state}
													onChange={handleChange('state')}
													aria-describedby="outlined-weight-helper-text"
													InputProps={{
														'aria-label': 'state',
														maxLength: 100
													}}
													variant="outlined"
												/>
											</FormControl>
										</Grid>
										<Grid item xs={12} lg={2}>
											<FormControl variant="outlined" fullWidth>
												<TextField
													label="Pincode"
													margin="none"
													required
													error={errorList.pincode}
													id="outlined-adornment-customer-pincode"
													value={property.pincode || ''}
													onChange={handleChange('pincode')}
													aria-describedby="outlined-weight-helper-text"
													inputProps={{
														'aria-label': 'customer-pincode',
														maxLength: 6
													}}
													variant="outlined"
												/>
											</FormControl>
										</Grid>
										<Grid item xs={12} lg={3}>
											<FormControl variant="outlined" fullWidth>
												<TextField
													label="Location"
													margin="none"
													id="outlined-adornment-geo_coordinates"
													value={property.geo_coordinates || ''}
													//error={errorList.geo_coordinates}
													onChange={handleChange('geo_coordinates')}
													aria-describedby="outlined-weight-helper-text"
													InputProps={{
														'aria-label': 'geo_coordinates',
														maxLength: 100
													}}
													helperText="GEO Coordinates"
													variant="outlined"
												/>
											</FormControl>
										</Grid>
									</Grid>
								</Grid>
							</div>
						</Paper>
					</Grid>
					<Grid item xs={12} lg={12}>
						<Paper variant="outlined" className={classes.section} elevation={3}>
							<CardHeader
								title="Other information"
								titleTypographyProps={{variant: 'h4'}}
							/>
							<Divider/>
							<div style={{minHeight: 100, marginRight: 16, marginLeft: 16, marginTop: 8, marginBottom: 16}}>
								<Grid container justify="center" spacing={2} alignItems="flex-start">
									<Grid item xs={12} lg={12} container justify="center" spacing={2} alignItems="center">
										<Grid item xs={12} lg={2}>
											<ItemSelection
												required 
												size="medium"
												label='Area Unit'
												value={property.unit || null} 
												optionLabel='name'
												error={errorList.unit}
												options={units} 
												style={{zIndex: 500, marginTop: 0, marginLeft: 0}}
												formClass={classes.formAutoClass}
												selected={(unit) => {
													setProperty(property => ({...property, unit}));
												}}/>
										</Grid>
										<Grid item xs={6} lg={2}>
											<FormControl variant="outlined" fullWidth>
												<TextField
													label="Area"
													margin="none"
													required
													error={errorList.area}
													id="outlined-adornment-customer-area"
													value={property.area || ''}
													onChange={handleChange('area')}
													aria-describedby="outlined-weight-helper-text"
													inputProps={{
														'aria-label': 'customer-area',
														maxLength: 10
													}}
													variant="outlined"
												/>
											</FormControl>
										</Grid>
										<Grid item xs={6} lg={2}>
											<FormControl variant="outlined" fullWidth>
												<TextField
													label="Plinth Area"
													margin="none"
													error={errorList.plinth_area}
													id="outlined-adornment-customer-plinth_area"
													value={property.plinth_area || ''}
													onChange={handleChange('plinth_area')}
													aria-describedby="outlined-weight-helper-text"
													inputProps={{
														'aria-label': 'customer-plinth_area',
														maxLength: 10
													}}
													variant="outlined"
												/>
											</FormControl>
										</Grid>
										<Grid item xs={6} lg={2}>
											<FormControl variant="outlined" fullWidth>
												<TextField
													label="Carpet Area"
													margin="none"
													fullWidth
													error={errorList.carpet_area}
													id="outlined-adornment-customer-carpet_area"
													value={property.carpet_area || ''}
													onChange={handleChange('carpet_area')}
													aria-describedby="outlined-weight-helper-text"
													inputProps={{
														'aria-label': 'customer-carpet_area',
														maxLength: 10
													}}
													variant="outlined"
												/>
											</FormControl>
										</Grid>
										<Grid item xs={12} lg={2}>
											<FormControl variant="outlined" fullWidth>
												<TextField
													label="Measurement"
													margin="none"
													id="outlined-adornment-measurement"
													value={property.measurement || ''}
													//error={errorList.measurement}
													onChange={handleChange('measurement')}
													aria-describedby="outlined-weight-helper-text"
													InputProps={{
														'aria-label': 'measurement'
													}}
													variant="outlined"
												/>
											</FormControl>
										</Grid>
										<Grid item xs={12} lg={2}>
											<FormControl variant="outlined" fullWidth>
												<TextField
													label="Identifier"
													margin="none"
													id="outlined-adornment-identifier"
													value={property.identifier || ''}
													//error={errorList.identifier}
													onChange={handleChange('identifier')}
													aria-describedby="outlined-weight-helper-text"
													InputProps={{
														'aria-label': 'identifier'
													}}
													variant="outlined"
												/>
											</FormControl>
										</Grid>
										<Grid item xs={12} lg={2}>
											<FormControl variant="outlined" fullWidth>
												<TextField
													label="Building No"
													margin="none"
													id="outlined-adornment-building_no"
													value={property.building_no || ''}
													//error={errorList.building_no}
													onChange={handleChange('building_no')}
													aria-describedby="outlined-weight-helper-text"
													InputProps={{
														'aria-label': 'building_no'
													}}
													variant="outlined"
												/>
											</FormControl>
										</Grid>
										<Grid item xs={12} lg={2}>
											<TextField
												label="Tax Due Date"
												placeholder={format_display}
												margin="none"
												id="outlined-adornment-tax_receipt_date"
												value={property.tax_receipt_date || ''}
												error={errorList.tax_receipt_date || false}
												onChange={handleChange('tax_receipt_date')}
												aria-describedby="outlined-weight-helper-text"
												InputProps={{
													'aria-label': 'tax_receipt_date'
												}}
												fullWidth
												variant="outlined"
											/>
										</Grid>
										<Grid item xs={12} lg={2}>
											<TextField
												label="Purchase Year"
												placeholder={format_year}
												margin="none"
												id="outlined-adornment-purchase_date"
												value={property.purchase_date || ''}
												error={errorList.purchase_date || false}
												onChange={handleChange('purchase_date')}
												aria-describedby="outlined-weight-helper-text"
												InputProps={{
													'aria-label': 'purchase_date'
												}}
												fullWidth
												variant="outlined"
											/>
										</Grid>
										<Grid item xs={12} lg={6}>
											<FormControl variant="outlined" fullWidth>
												<TextField
													label="Comments"
													margin="none"
													id="outlined-adornment-comments"
													value={property.comments || ''}
													error={errorList.comments}
													onChange={handleChange('comments')}
													aria-describedby="outlined-weight-helper-text"
													InputProps={{
														'aria-label': 'comments'
													}}
													variant="outlined"
												/>
											</FormControl>
										</Grid>
									</Grid>
								</Grid>
							</div>
						</Paper>
					</Grid>
					<Grid item xs={12} lg={12}>
						<Paper variant="outlined" className={classes.section} elevation={3}>
							<CardHeader
								title="Documents"
								titleTypographyProps={{variant: 'h4'}}
							/>
							<Divider/>
							<div style={{minHeight: 100, marginRight: 16, marginLeft: 16, marginTop: 8, marginBottom: 16}}>
								<Grid container justify="flex-start" spacing={2} alignItems="center">
									<Grid item xs={12} lg={1}>
										<Typography variant="h5" className={classes.header}>Land Tax</Typography>											
									</Grid>
									<Grid item xs={12} lg={3}>
										{
											<div>
												<DocumentPlaceHolder
													error={false}
													title="Add Land Tax"
													link={property.LANDTAX && property.LANDTAX.length > 0 ? property.LANDTAX[0].link : null} 
													change={(file) => {
														changePhoto('LANDTAX', file);
													}} 
													remove={(newDocument) => {
														if(newDocument){
															changePhoto('LANDTAX', null);
														} else {
															removeDocument('LANDTAX', property.LANDTAX[0].id)
														}
													}}
												/>
											</div>
										}
									</Grid>
									<Grid item xs={12} lg={1}>
										<Typography variant="h5" className={classes.header}>Tax Receipt</Typography>
									</Grid>
									<Grid item xs={12} lg={3}>
										{
											<div>
												<DocumentPlaceHolder
													error={false}
													title="Add Tax Receipt"
													link={property.TAXRECEIPT && property.TAXRECEIPT.length > 0 ? property.TAXRECEIPT[0].link : null} 
													change={(file) => {
														changePhoto('TAXRECEIPT', file);
													}} 
													remove={(newDocument) => {
														if(newDocument){
															changePhoto('TAXRECEIPT', null);
														} else {
															removeDocument('TAXRECEIPT', property.TAXRECEIPT[0].id)
														}
													}}
												/>
											</div>
										}
									</Grid>
									<Grid item xs={12} lg={1}>
										<Typography variant="h5" className={classes.header}>Drawing Plan</Typography>
									</Grid>
									<Grid item xs={12} lg={3}>
										{
											<div>
												<DocumentPlaceHolder
													error={false}
													title="Add Drawing Plan"
													link={property.DRAWINGPLAN && property.DRAWINGPLAN.length > 0 ? property.DRAWINGPLAN[0].link : null} 
													change={(file) => {
														changePhoto('DRAWINGPLAN', file);
													}} 
													remove={(newDocument) => {
														if(newDocument){
															changePhoto('DRAWINGPLAN', null);
														} else {
															removeDocument('DRAWINGPLAN', property.DRAWINGPLAN[0].id)
														}
													}}
												/>
											</div>
										}
									</Grid>
									<Grid item xs={12} lg={1}>
										<Typography variant="h5" className={classes.header}>Sketch</Typography>
									</Grid>
									<Grid item xs={12} lg={3}>
										{
											<div>
												<DocumentPlaceHolder
													error={false}
													title="Add Sketch"
													link={property.SKETCH && property.SKETCH.length > 0 ? property.SKETCH[0].link : null} 
													change={(file) => {
														changePhoto('SKETCH', file);
													}} 
													remove={(newDocument) => {
														if(newDocument){
															changePhoto('SKETCH', null);
														} else {
															removeDocument('SKETCH', property.SKETCH[0].id)
														}
													}}
												/>
											</div>
										}
									</Grid>
								</Grid>
							</div>
						</Paper>
					</Grid>
					<Grid item xs={12} lg={12}>
						<Paper variant="outlined" className={classes.section} elevation={3}>
							<CardHeader
								title="Photos"
								titleTypographyProps={{variant: 'h4'}}
							/>
							<Divider/>
							<div style={{minHeight: 100, marginRight: 16, marginLeft: 16, marginTop: 8, marginBottom: 16}}>
								<Grid container justify="flex-start" spacing={2} alignItems="center">
										{
											property && property.PHOTO ?
											property.PHOTO.map((photo) => (
												<Grid item key={photo.id} xs={12} lg={2}>
													<ImageHolder
														
														error={false}
														title={'Remove'}
														link={photo.link || null} 
														remove={(newDocument) => {
															if(newDocument){
																changePhoto('PHOTO', null);
															} else {
																removeDocument('PHOTO', photo.id)
															}
														}}
													/>
												</Grid>
											)) : ''
										}
										{
											photosFileOb.length > 0 ?
											photosFileOb.map((photo, i) => (
												<Grid item key={i} xs={12} lg={2}>
													<AddedImageHolder
														link={photo || null} 
														remove={() => {
															setPhotosFileOb(photos => ([...photos.filter(p => p !== photo)]));
														}}
													/>
												</Grid>
											)) : ''
										}
										{ state.user &&
											property.PHOTO.length + photosFileOb.length < toInt(state.user.photo_limit) && (
												<Grid item xs={12} lg={2}>
													<AddImage
														error={false}
														title="Add Photo"
														change={(file) => {
															changePhoto('PHOTO', file);
														}} 
													/>
												</Grid>
											)
										}
								</Grid>
							</div>
						</Paper>
					</Grid>
					<Grid item xs={12} lg={12}>
						<Paper variant="outlined" className={classes.section} elevation={3}>
							<CardHeader
								title="Alerts"
								titleTypographyProps={{variant: 'h4'}}
							/>
							<Divider/>
							<div style={{minHeight: 100, marginRight: 16, marginLeft: 16, marginTop: 8, marginBottom: 16}}>
								<Grid container justify="flex-start" spacing={2} alignItems="center">
									<Grid item xs={12} lg={6}>
										<Typography variant="h5" className={classes.header}>Rent Due</Typography>
									</Grid>
									<Grid item xs={12} lg={3}>
										<Switch
											checked={property.alert_config && (property.alert_config.alert_rent_due === 1 || property.alert_config.alert_rent_due === true) ? true : false}
											onChange={handleAlertChange('alert_rent_due', true)}
											name="rent-due"
											inputProps={{ 'aria-label': 'secondary checkbox' }}
										/>
									</Grid>
									<Grid item xs={12} lg={3}>
										<TextField 
											id="rent_due_days" 
											label="Alert before due date" 
											name="amount"
											variant="outlined"
											margin="dense"
											value={property.alert_config && property.alert_config.rent_due_days ? property.alert_config.rent_due_days : 0}
											InputProps={{
												startAdornment: <InputAdornment position="start">Days</InputAdornment>,
												min: 0
											}}
											style={{marginTop: 8}}
											type="number"
											onChange={handleAlertChange('rent_due_days', false)}/>
									</Grid>
									<Grid item xs={12} lg={6}>
										<Typography variant="h5" className={classes.header}>Agreement Due</Typography>
									</Grid>
									<Grid item xs={12} lg={3}>
										<Switch
											checked={property.alert_config && (property.alert_config.alert_agreement_due === 1 || property.alert_config.alert_agreement_due === true) ? true : false}
											onChange={handleAlertChange('alert_agreement_due', true)}
											name="agreement-due"
											inputProps={{ 'aria-label': 'secondary checkbox' }}
										/>
									</Grid>
									<Grid item xs={12} lg={3}>
										<TextField 
											id="agreement_due_days" 
											label="Alert before due date" 
											name="amount"
											variant="outlined"
											margin="dense"
											value={property.alert_config && property.alert_config.agreement_due_days ? property.alert_config.agreement_due_days : 0}
											InputProps={{
												startAdornment: <InputAdornment position="start">Days</InputAdornment>,
												min: 0
											}}
											style={{marginTop: 8}}
											type="number"
											onChange={handleAlertChange('agreement_due_days', false)}/>
									</Grid>
									<Grid item xs={12} lg={6}>
										<Typography variant="h5" className={classes.header}>Tax Due</Typography>
									</Grid>
									<Grid item xs={12} lg={3}>
										<Switch
											checked={property.alert_config && (property.alert_config.alert_tax_due === 1 || property.alert_config.alert_tax_due === true) ? true : false}
											onChange={handleAlertChange('alert_tax_due', true)}
											name="tax-due"
											disabled={!property.tax_receipt_date}
											inputProps={{ 'aria-label': 'secondary checkbox' }}
										/>
									</Grid>
									<Grid item xs={12} lg={3}>
										<TextField 
											id="tax_due_days" 
											label="Alert before due date" 
											name="amount"
											disabled={!property.tax_receipt_date}
											variant="outlined"
											margin="dense"
											value={property.alert_config && property.alert_config.tax_due_days ? property.alert_config.tax_due_days : 0}
											InputProps={{
												startAdornment: <InputAdornment position="start">Days</InputAdornment>,
												min: 0
											}}
											style={{marginTop: 8}}
											type="number"
											onChange={handleAlertChange('tax_due_days', false)}/>
									</Grid>
								</Grid>
							</div>
						</Paper>
					</Grid>
					<Grid item xs={12} lg={12}>
						<Paper variant="outlined" style={{marginLeft: 16, marginRight: 16}} elevation={3}>
							<div style={{marginRight: 16, marginLeft: 16, marginTop: 16, marginBottom: 16}}>
								<Grid container justify="flex-start" spacing={2} alignItems="center">
									<Grid item xs={12} lg={12}>
										<div style={{float: 'right'}}>
											<Button variant="contained" color="primary" onClick={saveProperty}>Save</Button>
										</div>
									</Grid>
								</Grid>
							</div>
						</Paper>
					</Grid>
				</Grid>
			</Grid>
		</Dialog>
	);
}

function DocumentPlaceHolder(props){
	// let classes = useStyles();
	// const {error} = props;
	const [link, setLink] = React.useState(null);
	const [newFile, setNewFile] = React.useState(null);

	const hiddenFileInput = React.useRef(null);

	React.useEffect(() => {
		setLink(props.link);
	}, [props.link]);

    const handleClick = () => {
        hiddenFileInput.current.click();
	}
	
	const change = (event) => {
		const fileUploaded = event.target.files[0];
		setNewFile(fileUploaded);
		// setLink(URL.createObjectURL(fileUploaded));
		props.change(fileUploaded);
	}
	


	return (
		<div>
			<ButtonGroup disableElevation size="small" color="inherit" aria-label="outlined primary button group">
				{
					link && (
						<Button style={{color: red[500]}} onClick={() => props.remove(false)}>Remove</Button>
					)
				}
				{
					!link && !newFile && (
						<Button onClick={handleClick}>Upload</Button>
					)
				}
				{
					newFile && (
						<Button 
							style={{color: red[500]}}
							onClick={() => {
								props.remove(true);
								setNewFile(null);
							}}>
							Remove Selected
						</Button>
					)
				}
			</ButtonGroup>
			<input accept="image/*,application/pdf" type="file" onChange={change} onClick={(event) =>  event.target.value = null} ref={hiddenFileInput} style={{display:'none'}} />
		</div>
	)
}

function ImageHolder(props){
	const [link, setLink] = React.useState(null);

	React.useEffect(() => {
		setLink(props.link);
	}, [props.link]);

    const handleClick = (e) => {
		e.stopPropagation();
        props.remove(false);
	}

	let classes = useStyles();
	return (
		<ButtonBase
			focusRipple
			className={classes.image}>
			<a href={link} target="_blank" rel="noopener noreferrer">
				<img 
					src={link} 
					height="200" width="200" 
					alt=""/>
			</a>
			<span className={classes.imageButtonBottom} onClick={handleClick}>
				<Typography
					component="span"
					variant="subtitle1"
					color="inherit"
					className={classes.imageTitle}
				>
					<p style={{margin: 0}}><DeleteIcon fontSize="small"/></p>
					<span className={classes.imageMarked} />
				</Typography>
			</span>
		</ButtonBase>
	)
}

function AddImage(props){
	const hiddenFileInput = React.useRef(null);
	const [link, setLink] = React.useState();

	React.useEffect(() => {
		setLink(props.link);
	}, [props.link])

    const handleClick = () => {
        hiddenFileInput.current.click();
	}
	
	const change = (event) => {
		const fileUploaded = event.target.files[0];
		// setLink(URL.createObjectURL(fileUploaded));
		props.change(fileUploaded);
	}

	let classes = useStyles();
	return (
		<ButtonBase
			focusRipple
			className={classes.image}>
			<img 
				src={link} 
				height="200" width="200" 
				alt=""/>
			<span className={classes.imageBackdrop}/>
			<span className={classes.imageButton} onClick={handleClick}>
				<Typography
					component="span"
					variant="subtitle1"
					color="inherit"
					className={classes.imageTitle}
				>
					<p><AddCircleIcon fontSize="large"/></p>
					{props.title}
					<span className={classes.imageMarked} />
				</Typography>
				<input accept="image/*" type="file" onChange={change} ref={hiddenFileInput} onClick={(event) =>  event.target.value = null} style={{display:'none'}} />
			</span>
		</ButtonBase>
	)
}

function AddedImageHolder(props){
	const [link, setLink] = React.useState(null);

	React.useEffect(() => {
		setLink(URL.createObjectURL(props.link));
	}, [props.link])

	let classes = useStyles();

	const remove = () => {
		props.remove();
	}

	return (
		<ButtonBase
			focusRipple
			className={classes.image}>
			<img 
				src={link} 
				height="200" width="200" 
				alt=""/>
			<span className={classes.imageBackdrop}/>
			<span className={classes.imageButtonBottom} onClick={remove}>
				<Typography
					component="span"
					variant="subtitle1"
					color="inherit"
					className={classes.imageTitle}
				>
					<p style={{margin: 0}}><DeleteIcon fontSize="small"/></p>
					{props.title}
					<span className={classes.imageMarked} />
				</Typography>
			</span>
		</ButtonBase>
	)
}

CreatePropertyDialog.prototype = {
    open: PropTypes.bool.isRequired,
    id: PropTypes.string,
    onClose: PropTypes.func.isRequired
};