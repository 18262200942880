import * as firebase from "firebase/app";
import 'firebase/firebase-messaging';
import { FCMService } from "./Services/FCMService";
import getMAC from 'getmac'

var firebaseConfig = {
    apiKey: "AIzaSyBd8G968LvWjm760CXK2oCrODK36PE27qs",
    authDomain: "sysbrew-caretaker.firebaseapp.com",
    databaseURL: "https://sysbrew-caretaker.firebaseio.com",
    projectId: "sysbrew-caretaker",
    storageBucket: "sysbrew-caretaker.appspot.com",
    messagingSenderId: "692946645454",
    appId: "1:692946645454:web:5991e55e9a1521bbcd7b9f"
    //measurementId: "G-HR74G5KZ51"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const messaginIsSupported = firebase.messaging.isSupported();

const messaging = messaginIsSupported ? firebase.messaging() : undefined;

messaging && messaging.usePublicVapidKey("BJNKi6p8lQt2lkwkckvHBxKSvclsGWLBcM0ePp8M0VEq5tPS5tpRV7e4pl5in3IfEp59m3KykXH6EsDy4XHqCCY");

messaginIsSupported && messaging.onTokenRefresh(() => {
    messaging.getToken().then((refreshedToken) => {
        console.log('Token refreshed.');
        // Indicate that the new Instance ID token has not yet been sent to the
        // app server.
        setTokenSentToServer(false);
        // Send Instance ID token to app server.
        let user = JSON.parse(localStorage.getItem('caretaker.user'));
        if(null != user){
            sendTokenToServer(refreshedToken, user);
        }
        // ...
    }).catch((err) => {
        console.log('Unable to retrieve refreshed token ', err);
        // showToken('Unable to retrieve refreshed token ', err);
    });
});

// messaging.onMessage((payload) => {

//     console.log('Message received. ', payload);
// });

const requestToken = (user) => {
    if(messaginIsSupported){
        if (!isTokenSentToServer()) {
            messaging.getToken()
            .then((currentToken) => {
                console.log("Token : " + currentToken);
                if (currentToken) {
                    sendTokenToServer(currentToken, user);
                    // updateUIForPushEnabled(currentToken);
                } else {
                    // Show permission request.
                    console.log('No Instance ID token available. Request permission to generate one.');
                    // Show permission UI.
                    // updateUIForPushPermissionRequired();
                    setTokenSentToServer(false);
                }
            })
            .catch((err) => {
                console.log("Unable to get permission to notify.", err);
            });
        }
    }
}

const sendTokenToServer = (token, user) => {
    if(messaginIsSupported){
        let mac = "test";
        try{
            mac = getMAC();
        } catch(err){
            console.log(err);
        }
        console.log(require('os').networkInterfaces());
        console.log(mac);
        let header = {
            "device-id": mac,
            "user_id": user.id,
            "mobile-no": user.mobile_no
        };
        let body  = {token: token};

        FCMService.register(header, body)
        .then(data => {
            console.log(data)
            setTokenSentToServer(true);
        })
        .catch(err => console.log(err));
    }
}

const unregister = (user) => {
    if(messaginIsSupported){
        if (isTokenSentToServer()) {
            let mac = "test";
            try{
                mac = getMAC();
            } catch(err){
                console.log(err);
            }
            console.log(require('os').networkInterfaces());
            console.log(mac);
            let header = {
                "device-id": mac,
                "user_id": user.id,
                "mobile-no": user.mobile_no
            };
            let body  = {};

            FCMService.unregister(header, body)
            .then(data => {
                console.log(data)
                setTokenSentToServer(false);
            })
            .catch(err => console.log(err)); 
        }
    }
}

function isTokenSentToServer() {
    return window.localStorage.getItem('caretaker.sentToServer') === '1';
  }

const setTokenSentToServer = (sent) => {
    window.localStorage.setItem('caretaker.sentToServer', sent ? '1' : '0');
}

export { messaging, sendTokenToServer, requestToken, unregister, messaginIsSupported };