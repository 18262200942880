import React from "react";
import { useContext } from "react";
import { AppContext } from "../AppContextProvider";
import { makeStyles, Grid, Paper, Table, TablePagination, TableRow, TableBody, Typography, Divider, Icon, Tooltip, IconButton, Button } from "@material-ui/core";
import { blue, red } from "@material-ui/core/colors";
import { TABLE_COUNTS, INVOICE_STATUS_OPTIONS } from "../Constants";
import { RowCountOptions } from "../Config";
// import EnhancedTableToolbar from "../components/EnhancedTableToolbar";
import EnhancedTableHead from "../components/EnhancedTableHead";
import { toInt, StyledTableCell, BootstrapTooltip, format_display_fancy_notime, formatNumber, openLink, getTimeInIST, format_display_fancy_my } from "../Utils";
import moment from "moment";
import ItemSelection from "../components/ItemSelection";
import { PropertyService } from "../Services/PropertyService";
import { TenantService } from "../Services/TenantService";
import SingleDate from '../components/SingleDate';
import ChangeInvoiceStatus from "../components/ChangeInvoiceStatus";
import ReceiptIcon from '@material-ui/icons/Receipt';
import EditIcon from '@material-ui/icons/Edit';
import GenerateInvoice from "../components/GenerateInvoice";

const useStyles = makeStyles(theme => ({
    section: {
        width: '100%',
        height: '100%',
        marginTop: theme.spacing(1),
        paddingTop : theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    qtyField: {
        width: "40%",
        position: "relative",
        [theme.breakpoints.up('md')]: {
            right: '100px',
            width: "10%",
            marginRight: 10
        },
        right: "22px"
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginBottom : '20px',
    },
    grid: {
        [theme.breakpoints.up('md')]: {
            marginTop: '10px',
        },
        marginTop: '50px',
        marginBottom: '5px'
    },
    icon: {
        padding: '0px',
        [theme.breakpoints.up('md')]: {
            padding: '12px',
        },
    },
    tablecell: {
        fontSize: '16pt',
        padding: theme.spacing(4)
    },
    container: {
        paddingTop : theme.spacing(1)
    },
    redAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: red[500],
    },
    blueAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: blue[500],
    },
    instructions: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },
    button: {
        marginTop: theme.spacing(1),
        // height: theme.spacing(7),
        float: 'right',
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            float: 'inherit',
            marginLeft: theme.spacing(1)
        },
        marginRight: theme.spacing(4),
        
    }, 
    fab: {
        margin: theme.spacing(1),
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    tableWrapper: {
        maxHeight: 407,
        overflow: 'auto',
    },
    list: {
        width: '100%',
        height: 250,
        overflow: "auto"
    },
    summary: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    title: {
        fontSize: 18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    subtitle: {
        fontSize:18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    selectRoot: {
        fontSize: 12,
        [theme.breakpoints.down('md')]: {
            marginLeft: 0,
            marginRight: 0
        },
    },
}));

export default function ViewInvoices(props){

    const {setTitle, setLinearLoading, showProgressDialog, closeProgressDialog, showConfirmDialog, closeConfirmDialog, showSnackBar, openViewProperty, openViewTenant} = useContext(AppContext);
    const classes = useStyles();
    const [selectedTenant, setSelectedTenant] = React.useState(null);
    const [order, setOrder] = React.useState('desc');
    const [tenants, setTenants] = React.useState([]);
    const [orderBy, setOrderBy] = React.useState('rental_month');
    const [selectedProperty, setSelectedProperty] = React.useState(null);
    const [properties, setProperties] = React.useState([]);
    const [statuses, setStatuses] = React.useState([]);
    const [selectedStatus, setSelectedStatus] = React.useState(null);
    const [currentRows, setCurrentRows] = React.useState([]);
    const [generate, setGenerate] = React.useState(true);
    const [loadedMaster, setLoadedMaster] = React.useState(false);
    const [startdate, setStartDate] = React.useState(moment().startOf('day').startOf('month'));
    const [enddate, setEndDate] = React.useState(moment().endOf('day'));
    const columns = [
        { id: 'created_date', align: 'left', numeric: false, disablePadding: false, label: 'Created', sortable: false },
        { id: 'invoice_no', align: 'left', numeric: false, disablePadding: false, label: 'Invoice No', sortable: true },
        { id: 'property_name', align: 'center', numeric: false, disablePadding: false, label: 'Property', sortable: true },
        { id: 'tenant_name', align: 'center', numeric: false, disablePadding: false, label: 'Tenant', sortable: true },
        { id: 'rental_month', align: 'center', numeric: false, disablePadding: true, label: 'Rental Period', sortable: true },
        { id: 'amount', align: 'right', numeric: true, disablePadding: true, label: 'Amount', sortable: true },
        { id: 'paid_amount', align: 'right', numeric: true, disablePadding: true, label: 'Paid', sortable: true },
        { id: 'due_date', align: 'center', numeric: false, disablePadding: true, label: 'Due On', sortable: true },
        { id: 'status', align: 'center', numeric: false, disablePadding: true, label: 'Status', sortable: true },
        { id: 'link', align: 'center', numeric: false, disablePadding: false, label: 'File', sortable: false },
        { id: 'action', align: 'right', numeric: true, disablePadding: false, label: '', sortable: false },
    ];

    //Dialog
    const [openChangeStatus, setOpenChangeStatus] = React.useState(false);
    const [selectedInvoice, setSelectedInvoice] = React.useState({});

    //New Invoice
    const [showNewInvoice, setShowNewInvoice] = React.useState(false);

    //Pagination
    const [totalItems, setTotalItems] = React.useState(0);
    const [numItemsPerPage, setNumItemsPerPage] = React.useState(localStorage.getItem(TABLE_COUNTS.property_tab_count) ? toInt(localStorage.getItem(TABLE_COUNTS.property_tab_count)) : RowCountOptions[0]);
    const [currentPageNumber, setCurrentPageNumber] = React.useState(0);
    const [fetchPage, setFetchPage] = React.useState(false);

    const handleChangeRowsPerPage = event => {
        setNumItemsPerPage(event.target.value);
        setCurrentPageNumber(0);
        setFetchPage(true);
        localStorage.setItem(TABLE_COUNTS.property_tab_count, event.target.value);
    }

    const handleChangePage = (event, page) => {
        if(currentPageNumber > page){
            fetchRow(currentPageNumber - 1);
        } else {
            fetchRow(currentPageNumber + 1);
        }
    };

    const fetchRow = (newCurrentPage) => {
        setCurrentPageNumber(newCurrentPage);
        setFetchPage(true);
    }

    React.useEffect(() => {
        setTitle('Invoices');
        let s = [
            {key: 'All', value: -1}, 
            ...INVOICE_STATUS_OPTIONS
        ];
        setStatuses(s);
        setSelectedStatus(s[0]);
        PropertyService.getPropertyNames()
        .then(data => {
            setProperties(data);
            setLoadedMaster(true);
        })
        TenantService.getTenantNames()
		.then(data => {
			setTenants(data);
		})
        return () => {
            setTitle('');
        }
    }, [true]);

    React.useEffect(() => {
        // console.log(generate, loadedMaster)
        if(generate && loadedMaster){
            setCurrentRows([]);
            setLinearLoading(true);
            setCurrentPageNumber(0);
            setTotalItems(0);
            showProgressDialog();
            let property = selectedProperty ? selectedProperty.id : null;
            let tenant = selectedTenant ? selectedTenant.id : null;
            let current = selectedStatus && selectedStatus.value !== -1  ? selectedStatus.value : null;
            PropertyService.getInvoiceCount(current, property, tenant, startdate, enddate)
            .then(data => {
                console.log(data);
                setTotalItems(toInt(data.count));
                setFetchPage(true);
                if(toInt(data.count) === 0){
                    setCurrentRows([]);
                }
                closeProgressDialog();
                setLinearLoading(false);
            })
            .catch(error => {
                console.log(error);
                setLinearLoading(false);
                closeProgressDialog();
            });
            setGenerate(false);
        }
    }, [generate, loadedMaster]);

    const handleEdit = (invoice) => {
        setOpenChangeStatus(true);
        setSelectedInvoice(invoice);
    }

	const regenerateConfirm = (id) => {
		showProgressDialog('Regenerating Invoice...');
		PropertyService.regenerateInvoice(id)
		.then(data => {
            showSnackBar('Regenerated Invoice', 'info');
            closeProgressDialog();
            setGenerate(true);
		})
		.catch(error => {
			console.log(error);
			showConfirmDialog('Something went wrong. Please try again.', undefined, () => {
				closeConfirmDialog();
			});
			closeProgressDialog();
		})
    }
    
    const regenerate = (id) => {
        console.log(id);
        showConfirmDialog(`Regenerate Invoice?`, undefined, () => {
            closeConfirmDialog();
            regenerateConfirm(id);
        });
    }

    React.useEffect(() => {
        let rows = [];
        if(fetchPage) {
            setLinearLoading(true);
            showProgressDialog();
            let property = selectedProperty ? selectedProperty.id : null;
            let tenant = selectedTenant ? selectedTenant.id : null;
            let current = selectedStatus && selectedStatus.value !== -1  ? selectedStatus.value : null;
            PropertyService.getInvoiceList(current, property, tenant, startdate, enddate, orderBy, order, currentPageNumber * numItemsPerPage, numItemsPerPage)
            .then((data) => {
                console.log(data);
                if(data){
                    data.forEach((element, index) => {
                        rows.push(
                            <TableRow 
                                key={index} 
                                hover
                                >
                                <StyledTableCell scope="row" align="left" padding='none'>
                                    {getTimeInIST(element.created_date).format(format_display_fancy_notime)}
                                </StyledTableCell>
                                <StyledTableCell scope="row" align="left" padding='none'>
                                    {element.invoice_no}
                                </StyledTableCell>
                                <StyledTableCell 
                                    scope="row" 
                                    align="center" 
                                    padding='none'
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        openViewProperty(element.property_id);
                                    }}>
                                    <BootstrapTooltip arrow title="Click to View Property">
                                        <div>
                                            {element.property_name || ''}
                                        </div>
                                    </BootstrapTooltip>
                                </StyledTableCell>
                                <StyledTableCell 
                                    scope="row" 
                                    align="center" 
                                    padding='none'
                                    style={{paddingLeft: 0}}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        openViewTenant(element.tenant_id);
                                    }}>
                                    <BootstrapTooltip arrow title="Click to View Tenant">
                                        <div>
                                            {element.tenant_name}
                                        </div>
                                    </BootstrapTooltip>
                                </StyledTableCell>
                                <StyledTableCell scope="row" align="center" padding='none'>
                                    {element.rental_month ? getTimeInIST(element.rental_month).format(format_display_fancy_my) : '-'}
                                </StyledTableCell>
                                <StyledTableCell scope="row" align="right" padding='none'>
                                    {formatNumber(element.amount)}
                                </StyledTableCell>
                                <StyledTableCell scope="row" align="right" padding='none'>
                                    {formatNumber(element.paid_amount || 0)}
                                </StyledTableCell>
                                <StyledTableCell scope="row" align="center" padding='none'>
                                    {element.due_date ? moment(element.due_date).format(format_display_fancy_notime) : '-'}
                                </StyledTableCell>
                                <StyledTableCell scope="row" align="center" padding='none' style={{paddingRight: 16}}>
                                    {element.status ? element.status : '-' }
                                </StyledTableCell>
                                <StyledTableCell scope="row" align="center" padding='none'>
                                    {
                                        element.link ? (<Button variant="text" onClick={() => openLink(element.link)} color="primary">View</Button>) : '-'
                                    }
                                </StyledTableCell>
                                <StyledTableCell scope="row" align="right" padding='none' style={{paddingRight: 16}}>
                                    <BootstrapTooltip arrow title="Change Status">
                                        <IconButton 
                                            fontSize="small" 
                                            style={{marginRight: 8}} 
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleEdit(element)
                                            }}><EditIcon/></IconButton>
                                    </BootstrapTooltip>
                                    <BootstrapTooltip arrow title="Regenerate Invoice">
                                        <IconButton 
                                            fontSize="small" 
                                            style={{marginRight: 8}} 
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                regenerate(element.id)
                                            }}><ReceiptIcon/></IconButton>
                                    </BootstrapTooltip>
                                </StyledTableCell>
                            </TableRow>
                        );
                    });
                }
                setCurrentRows(rows);
                setLinearLoading(false);
                closeProgressDialog();
            })
            .catch(error => {
                console.log(error);
                setLinearLoading(false);
                closeProgressDialog();
            });
        }
        setFetchPage(false);
    }, [fetchPage]);

    const sortBy = (event, property) => {
        let o = 'desc';
        if (orderBy === property && order === 'desc') {
            o = 'asc';
        }
        setOrder(o);
        setOrderBy(property);
        setGenerate(true);
    }

    const newInvoice = (event) => {
        setShowNewInvoice(true);
    }

    return (
        <div className="Home">
            <div className="lander">
                <Grid container direction="row" spacing={1} justify="center">
                    <Grid item xl={11} lg={12} xs={12}>
                        <Paper className={classes.section}>
                            <Grid container spacin={1} alignItems="center">
                                <Grid item xs={12} lg={2}>
                                    <ItemSelection
                                        clearable={true} 
                                        optionLabel='name'
                                        label='Tenant' 
                                        value={selectedTenant}
                                        options={tenants} 
                                        selected={(value) => {
                                            setSelectedTenant(value);
                                            setGenerate(true);
                                        }}/>
                                </Grid>
                                <Grid item xs={12} lg={2}>
                                    <ItemSelection
                                        clearable={true} 
                                        optionLabel='name'
                                        label='Property' 
                                        value={selectedProperty} 
                                        labelTemplate={(option) => `${option.name} ${option.nickname ? `(${option.nickname})`: ''}`}
                                        options={properties} 
                                        selected={(value) => {
                                            setSelectedProperty(value);
                                            setGenerate(true);
                                        }}/>
                                </Grid>
                                <Grid item xs={12} lg={1}>
                                    <ItemSelection
                                        label='Status' 
                                        optionLabel='key'
                                        value={selectedStatus || null}
                                        options={statuses} 
                                        selected={(value) => {
                                            setSelectedStatus(value);
                                            setGenerate(true);
                                        }}/>
                                </Grid>
                                <Grid item container xs={12} lg={3} spacing={1}>
                                    <Grid item>
                                        <div style={{maxWidth: 170}}>
                                            <SingleDate 
                                                label="From"
                                                margin="dense"
                                                date={startdate}
                                                maxDate={moment()}
                                                setDate={(date) => {
                                                    setStartDate(date);
                                                }}
                                                onKeyUp={(event) => {
                                                    event.preventDefault();
                                                    if (event.key === 'Enter'){
                                                        !generate && setGenerate(true)
                                                    }
                                                }}/>
                                        </div>
                                    </Grid>
                                    <Grid item>
                                        <div style={{maxWidth: 170}}>
                                            <SingleDate 
                                                label="To"
                                                margin="dense"
                                                date={enddate}
                                                minDate={startdate}
                                                setDate={(date) => {
                                                    setEndDate(date);
                                                }}
                                                onKeyUp={(event) => {
                                                    event.preventDefault();
                                                    if (event.key === 'Enter'){
                                                        !generate && setGenerate(true)
                                                    }
                                                }}/>
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Tooltip title="Refresh">
                                        <IconButton size="medium" style={{marginTop: 8}} aria-label="Refresh" color="primary" onClick={() => {!generate && setGenerate(true)}}>
                                            <Icon>refresh</Icon>
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                <Grid item>
                                    <Tooltip title="Refresh">
                                        <Button variant="outlined" style={{marginTop: 8, marginLeft: 16}} aria-label="Refresh" color="primary" onClick={newInvoice}>
                                            Generate
                                        </Button>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <Divider style={{marginBottom: 0, marginTop: 10}}/>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <div style={{minHeight: 100, overflowY: 'auto', display: 'block'}}>
                                        <Table className={classes.table} stickyHeader>
                                            <EnhancedTableHead
                                                order={order}
                                                orderBy={orderBy}
                                                onRequestSort={sortBy}
                                                rows={columns} />
                                            <TableBody>
                                                {currentRows}
                                            </TableBody>
                                        </Table>
                                    </div>
                                    {
                                        totalItems === 0 && 
                                            <Typography color="error" variant="subtitle2">No Invoices</Typography>
                                    }
                                    <TablePagination
                                        rowsPerPageOptions={RowCountOptions}
                                        component="div"
                                        count={totalItems}
                                        rowsPerPage={numItemsPerPage}
                                        page={currentPageNumber}
                                        backIconButtonProps={{
                                            'aria-label': 'Previous Page',
                                        }}
                                        nextIconButtonProps={{
                                            'aria-label': 'Next Page',
                                        }}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                        classes={{ selectRoot : classes.selectRoot}}/>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
            <ChangeInvoiceStatus 
                open={openChangeStatus} 
                id={selectedInvoice.id} 
                subtitle={selectedInvoice.invoice_no}
                fullWidth={true}
                maxWidth={'xs'} 
                refresh={() => {
                    setGenerate(true);
                }} 
                handleClose={() => {
                    setOpenChangeStatus(false);
                    setSelectedInvoice({});
                }}/>
            <GenerateInvoice 
                open={showNewInvoice} 
                fullWidth={true}
                maxWidth={'md'} 
                refresh={() => {
                    setGenerate(true);
                }} 
                handleClose={() => {
                    setShowNewInvoice(false);
                }}/>
            {/* <BootstrapTooltip arrow title="Add Agreement">
                <Fab disabled={state.linearLoading} onClick={() => handleEdit(null)} color="primary" aria-label="Add" className={classes.fab}>
                    <AddIcon />
                </Fab>
            </BootstrapTooltip> */}
        </div>
    )
}