import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import PropTypes from 'prop-types';
import { Grid, Paper, CardHeader, ButtonBase, Button, Switch, TextField, InputAdornment, ButtonGroup } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { formatNumber, format_display_fancy_notime, getVacancyStatusBadge, getColor, openMap, toInt } from '../Utils';
import { AppContext } from '../AppContextProvider';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import moment from 'moment';
import { STATUS } from '../Constants';
import DeleteIcon from '@material-ui/icons/Delete';
import { PropertyService } from '../Services/PropertyService';
import { useEffect } from 'react';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
	appBar: {
		position: 'relative',
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1,
		color: theme.palette.white
	},
	header: {
		fontWeight: 700
	},
	page: {
		height: '100%',
		backgroundColor: theme.palette.divider,
		overflow: 'auto'
	},
	section: {
		margin: 16,
		minHeight: 200,
	},
	avatar: {
		backgroundColor: red[500],
	},
	image: {
		margin: 12,
		position: 'relative',
		height: 200,
		width: 200,
		[theme.breakpoints.down('xs')]: {
			width: '100% !important', // Overrides inline-style
			height: 100,
		},
		'&:hover,  &Mui-focusVisible': {
			zIndex: 1,
		'& $imageBackdrop': {
			opacity: 0.7,
		},
		'& $imageMarked': {
			opacity: 0,
		},
		'& $imageTitle': {
			border: '4px solid currentColor',
		},
		},
	},
	imageBackdrop: {
		borderRadius: 8,
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		backgroundColor: theme.palette.common.black,
		opacity: 0.3,
		transition: theme.transitions.create('opacity'),
	},
	imageTitle: {
		position: 'relative',
		padding: `${theme.spacing(0)}px ${theme.spacing(4)}px ${theme.spacing(0)}px`,
	},
	imageMarked: {
		height: 3,
		width: 18,
		backgroundColor: theme.palette.common.white,
		position: 'absolute',
		bottom: -2,
		left: 'calc(50% - 9px)',
		transition: theme.transitions.create('opacity'),
	},
	imageButton: {
		position: 'absolute',
		left: 0,
		right: 0,
		bottom: 0,
		top: 0,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: theme.palette.common.white,
	},
	imageButtonBottom: {
		position: 'absolute',
		left: 0,
		right: 0,
		// top: 0,
        // bottom: 0,
        bottom: 1,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: theme.palette.common.white,
	},
	formAutoClass: {
		zIndex: 3,
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
	card: {
		margin: 8
	}
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ViewPropertyDialog(props) {
	const {state, showSnackBar, showProgressDialog, closeProgressDialog, showConfirmDialog, closeConfirmDialog, showConfirmDialog1D, closeConfirmDialog1D} = useContext(AppContext);
	const {id} = props;
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);
	const [property, setProperty] = React.useState({});
	// const [loading, setLoading] = React.useState(true);

	const [returnUrl, setReturnURL] = useState(false);
	const history = useHistory();
	const [alertConfig, setAlertConfig] = React.useState({
		"alert_rent_due": false,
        "rent_due_days": 0,
        "alert_agreement_due": false,
        "agreement_due_days": 0,
        "alert_tax_due": false,
        "tax_due_days": 0,
	});

	React.useEffect(() => {
		if(props.location && props.location.search){
            let qString = queryString.parse(props.location.search);
            if(qString.redirectUrl){
				setReturnURL(qString.redirectUrl);
			}
		}
		setOpen(props.open);
		if(props.open){
			showProgressDialog();
			fetchData();
		}
		return () => {
			setProperty({});
			setOpen(false);
		}
	},[props.open]);

	const fetchData = () => {
		// setLoading(true);
		PropertyService.getPropertyById(id)
		.then(data => {
			console.log(data);
			if(data){
				let days = data.end_date ? moment(data.end_date).diff(moment().startOf('d'), 'days') : 0;
				setProperty({...data, days});
				if(data.alert_config){
					setAlertConfig({...data.alert_config, alert_rent_due: data.alert_config.alert_rent_due === 1, alert_agreement_due :data.alert_config.alert_agreement_due === 1, alert_tax_due :data.alert_config === 1});
				}
			}
			closeProgressDialog();
			// setLoading(false);
		})
		.catch(error => {
			console.log(error);
			showConfirmDialog1D('Something went wrong. Please try again.', undefined, () => {
				handleClose();
				closeConfirmDialog1D();
			});
			// setLoading(false);
		  	closeProgressDialog();
		});
	}

	const handleClose = (callback = false) => {
		props.onClose(callback);
		setOpen(false);
		if(returnUrl){
			history.push(returnUrl);
		}
	};

	const save = () => {
		showConfirmDialog('Are you sure you want to save the alert configuration?', undefined, () => {
			closeConfirmDialog();
			saveConfirm();
		}, 'Back')
	}

	const saveConfirm = () => {
		// setLoading(true);
		PropertyService.savePropertyAlertConfig({}, alertConfig)
		.then(data => {
			if(data.success){
				showSnackBar('Alert Saved', 'info');
				fetchData();
			}
			// setLoading(false);
		})
		.catch(error => {
			console.log(error);
			showConfirmDialog('Something went wrong. Please try again.', undefined, () => {
				handleClose();
				closeConfirmDialog();
			});
			// setLoading(false);
		})
	}

	const handleAlertChange = (type, isCheckbox) => e => {
		// let e = event;
		e.persist();
		if(!alertConfig.p_id){
			setAlertConfig(config => ({...config, modified: true, p_id : property.id, [type] : isCheckbox ? e.target.checked : e.target.value}))
		} else {
			setAlertConfig(config => ({...config, modified: true, [type] : isCheckbox ? e.target.checked : e.target.value}))
		}
	}

	const changePhoto = (field, file) => {
		console.log(field, file);
		const formData = new FormData();
		formData.append('file', file || null);
		showProgressDialog('Uploading...');
		// setLoading(true);
		PropertyService.addPropertyDocument(property.id, field, formData)
		.then(data => {
			// setLoading(false);
			closeProgressDialog();
			showSnackBar('Added document', 'info', 1000);
			fetchData();
		})
		.catch(error => {
			console.log(error);
			showSnackBar('Something went wrong. Please try again.', 'error', 1000);
		})
	}

	const removeDocument = (id) => {
		console.log(id);
		showConfirmDialog('Are you sure you want to remove file?', undefined, () => {
			closeConfirmDialog();
			PropertyService.removePropertyDocument(id)
			.then(() => {
				showSnackBar('Removed document', 'info', 1000);
				fetchData();
			})
			.catch(error => {
				console.log(error);
				showSnackBar('Something went wrong. Please try again.', 'error', 1000);
			})
		});
	}

  	return (
		<Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
			<AppBar className={classes.appBar}>
				<Toolbar>
				<IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
					<CloseIcon />
				</IconButton>
				<Typography variant="h6" className={classes.title}>
					{property.name && `Property : ${property.name} ${property.nickname ? `(${property.nickname})`: '' }` }
				</Typography>
				</Toolbar>
			</AppBar>
			{/* <Backdrop className={classes.backdrop} open={loading} onClick={() => {}}>
				<div style={{padding: 50, backgroundColor: 'white', borderRadius: 8}}>
					<CircularProgress 
							size={40}
							thickness={4}/>
				</div>
			</Backdrop> */}
			<Grid container className={classes.page} style={property.status && property.status === STATUS.CANCELLED ? {backgroundColor: getColor(property.status)} : {}} justify="center">
				<Grid container item spacing={0} xs={12} lg={12} alignItems="flex-start" justify="flex-start" direction="row">
					{
						property.id && (
						<Grid item xs={12} lg={12}>
							<Paper variant="outlined" className={classes.section} elevation={3}>
								<CardHeader
									title="Property information"
									titleTypographyProps={{variant: 'h4'}}
								/>
								<Divider/>
								<div style={{minHeight: 100, marginRight: 16, marginLeft: 16, marginTop: 8, marginBottom: 8}}>
									<Grid container justify="center" spacing={2} alignItems="center">
										<Grid item xs={12} lg={12} container justify="flex-start" spacing={2} alignItems="center">
											<Grid item xs={12} lg={3}>
												<Typography variant="h5" className={classes.header}>Name</Typography>
											</Grid>
											<Grid item xs={12} lg={3}>
												<Typography variant="subtitle1">{property.name || ''}</Typography>
											</Grid>
											<Grid item xs={12} lg={3}>
												<Typography variant="h5" className={classes.header}>Nickname</Typography>
											</Grid>
											<Grid item xs={12} lg={3}>
												<Typography variant="subtitle1">{property.nickname || '-'}</Typography>
											</Grid>
											<Grid item xs={12} lg={3}>
												<Typography variant="h5" className={classes.header}>Address</Typography>
											</Grid>
											<Grid item xs={12} lg={9}>
												<Typography variant="subtitle1">{property.address1 || ''}{`, ${property.address2}` || ''}, {property.city || ''}, {property.state || ''}, {property.pincode || ''}</Typography>
											</Grid>
											<Grid item xs={12} lg={3}>
												<Typography variant="h5" className={classes.header}>Location</Typography>
											</Grid>
											<Grid item xs={12} lg={3}>
												<Typography variant="subtitle1" color="primary" style={{cursor: 'pointer'}} onClick={() => property.geo_coordinates && openMap(property.geo_coordinates)}>{property.geo_coordinates ? property.geo_coordinates : ''}</Typography>
											</Grid>
											<Grid item xs={12} lg={3}>
												<Typography variant="h5" className={classes.header}>Area</Typography>
											</Grid>
											<Grid item xs={12} lg={3}>
												<Typography variant="subtitle1">{property.area ? `${property.area} ${property.area_unit || ''}` : '-'}</Typography>
											</Grid>
											<Grid item xs={12} lg={3}>
												<Typography variant="h5" className={classes.header}>Plinth Area</Typography>
											</Grid>
											<Grid item xs={12} lg={3}>
												<Typography variant="subtitle1">{property.plinth_area ? `${property.plinth_area} ${property.area_unit || ''}` : '-'}</Typography>
											</Grid>
											<Grid item xs={12} lg={3}>
												<Typography variant="h5" className={classes.header}>Carpet Area</Typography>
											</Grid>
											<Grid item xs={12} lg={3}>
												<Typography variant="subtitle1">{property.carpet_area ? `${property.carpet_area} ${property.area_unit || ''}` : '-'}</Typography>
											</Grid>
											<Grid item xs={12} lg={3}>
												<Typography variant="h5" className={classes.header}>Measurement</Typography>
											</Grid>
											<Grid item xs={12} lg={3}>
												<Typography variant="subtitle1">{property.measurement ? property.measurement : '-'}</Typography>
											</Grid>
											<Grid item xs={12} lg={3}>
												<Typography variant="h5" className={classes.header}>Type</Typography>
											</Grid>
											<Grid item xs={12} lg={3}>
												<Typography variant="subtitle1">{property.type}</Typography>
											</Grid>
											<Grid item xs={12} lg={3}>
												<Typography variant="h5" className={classes.header}>Vacancy Status</Typography>
											</Grid>
											<Grid item xs={12} lg={3}>
												<Typography variant="subtitle1">{getVacancyStatusBadge(property.vacancy_status) }</Typography>
											</Grid>
											
											<Grid item xs={12} lg={3}>
												<Typography variant="h5" className={classes.header}>Identification</Typography>
											</Grid>
											<Grid item xs={12} lg={3}>
												<Typography variant="subtitle1">{property.identification || '-'}</Typography>
											</Grid>
											<Grid item xs={12} lg={3}>
												<Typography variant="h5" className={classes.header}>Building No</Typography>
											</Grid>
											<Grid item xs={12} lg={3}>
												<Typography variant="subtitle1">{property.building_no || '-'}</Typography>
											</Grid>
											<Grid item xs={12} lg={3}>
												<Typography variant="h5" className={classes.header}>Comments</Typography>
											</Grid>
											<Grid item xs={12} lg={3}>
												<Typography variant="subtitle1">{property.comments || '-'}</Typography>
											</Grid>
											<Grid item xs={12} lg={3}>
												<Typography variant="h5" className={classes.header}>Tax Due Date</Typography>
											</Grid>
											<Grid item xs={12} lg={3}>
												<Typography variant="subtitle1">{property.tax_receipt_date ? moment(property.tax_receipt_date).format(format_display_fancy_notime) : '-'}</Typography>
											</Grid>
											<Grid item xs={12} lg={3}>
												<Typography variant="h5" className={classes.header}>Purchase Year</Typography>
											</Grid>
											<Grid item xs={12} lg={3}>
												<Typography variant="subtitle1">{property.purchase_date || '-'}</Typography>
											</Grid>
										</Grid>
									</Grid>
								</div>
							</Paper>
						</Grid>)
					}
					{
						property.id && (
						<Grid item xs={12} lg={12}>
							<Paper variant="outlined" className={classes.section} elevation={3}>
								<CardHeader
									title="Documents"
									titleTypographyProps={{variant: 'h4'}}
								/>
								<Divider/>
								<div style={{minHeight: 100, marginRight: 16, marginLeft: 16, marginTop: 8, marginBottom: 16}}>
									<Grid container justify="flex-start" spacing={2} alignItems="center">
										<Grid item xs={12} lg={1}>
											<Typography variant="h5" className={classes.header}>Land Tax</Typography>											
										</Grid>
										<Grid item xs={12} lg={3}>
											{
												<div>
													<DocumentPlaceHolder
														error={false}
														title="Add Land Tax"
														link={property.LANDTAX && property.LANDTAX.length > 0 ? property.LANDTAX[0].link : null} 
														change={(file) => {
															changePhoto('LANDTAX', file);
														}} 
														remove={() => {
															removeDocument(property.LANDTAX[0].id)
														}}
													/>
												</div>
											}
										</Grid>
										<Grid item xs={12} lg={1}>
											<Typography variant="h5" className={classes.header}>Tax Receipt</Typography>
										</Grid>
										<Grid item xs={12} lg={3}>
											{
												<div>
													<DocumentPlaceHolder
														error={false}
														title="Add Tax Receipt"
														link={property.TAXRECEIPT && property.TAXRECEIPT.length > 0 ? property.TAXRECEIPT[0].link : null} 
														change={(file) => {
															changePhoto('TAXRECEIPT', file);
														}} 
														remove={() => {
															removeDocument(property.TAXRECEIPT[0].id)
														}}
													/>
												</div>
											}
										</Grid>
										<Grid item xs={12} lg={1}>
											<Typography variant="h5" className={classes.header}>Drawing Plan</Typography>
										</Grid>
										<Grid item xs={12} lg={3}>
											{
												<div>
													<DocumentPlaceHolder
														error={false}
														title="Add Drawing Plan"
														link={property.DRAWINGPLAN && property.DRAWINGPLAN.length > 0 ? property.DRAWINGPLAN[0].link : null} 
														change={(file) => {
															changePhoto('DRAWINGPLAN', file);
														}} 
														remove={() => {
															removeDocument(property.DRAWINGPLAN[0].id)
														}}
													/>
												</div>
											}
										</Grid>
										<Grid item xs={12} lg={1}>
											<Typography variant="h5" className={classes.header}>Sketch</Typography>
										</Grid>
										<Grid item xs={12} lg={3}>
											{
												<div>
													<DocumentPlaceHolder
														error={false}
														title="Add Sketch"
														link={property.SKETCH && property.SKETCH.length > 0 ? property.SKETCH[0].link : null} 
														change={(file) => {
															changePhoto('SKETCH', file);
														}} 
														remove={() => {
															removeDocument(property.SKETCH[0].id)
														}}
													/>
												</div>
											}
										</Grid>
									</Grid>
								</div>
							</Paper>
						</Grid>)
					}
					{
						property.id && (
						<Grid item xs={12} lg={12}>
							<Paper variant="outlined" className={classes.section} elevation={3}>
								<CardHeader
									title="Photos"
									titleTypographyProps={{variant: 'h4'}}
								/>
								<Divider/>
								<div style={{minHeight: 100, marginRight: 16, marginLeft: 16, marginTop: 8, marginBottom: 16}}>
									<Grid container justify="flex-start" spacing={2} alignItems="center">
										
										<Grid item xs={12} lg={12}>
											{
												property.PHOTO && property.PHOTO.length > 0 &&
												property.PHOTO.map((photo) => (
													<ImageHolder
														key={photo.id}
														error={false}
														title={'Remove'}
														link={photo.link || null} 
														remove={() => {
															removeDocument(photo.id)
														}}
													/>
												))
											}
											{
												property.PHOTO.length < toInt(state.user.photo_limit) && (
													<AddImage
														error={false}
														title="Add Photo"
														change={(file) => {
															changePhoto('PHOTO', file);
														}} 
													/>
												)
											}
											{/* {
												property.LANDTAX && property.LANDTAX.length > 0 && (
													<Link href={property.LANDTAX[0].link} target="_blank" download>
														<Button variant="outlined" size="small" style={{marginLeft: 72}}>Download</Button>
													</Link>
												)
											} */}
										</Grid>
									</Grid>
								</div>
							</Paper>
						</Grid>)
					}
					{
						property.agreement_type && (
						<Grid item xs={12} lg={12}>
							<Paper variant="outlined" className={classes.section} elevation={3}>
								<CardHeader
									title="Current Agreement"
									titleTypographyProps={{variant: 'h4'}}
								/>
								<Divider/>
								<div style={{minHeight: 100, marginRight: 16, marginLeft: 16, marginTop: 8, marginBottom: 8}}>
									<Grid container justify="center" spacing={2} alignItems="center">
										<Grid item xs={12} lg={12} container justify="center" spacing={2} alignItems="center">
											<Grid item xs={12} lg={3}>
												<Typography variant="h5" className={classes.header}>Type</Typography>
											</Grid>
											<Grid item xs={12} lg={3}>
												<Typography variant="subtitle1">{property.agreement_type || 'RENT'}</Typography>
											</Grid>
											<Grid item xs={12} lg={3}>
												<Typography variant="h5" className={classes.header}>Start</Typography>
											</Grid>
											<Grid item xs={12} lg={3}>
												<Typography variant="subtitle1">{property.start_date ? moment(property.start_date).format(format_display_fancy_notime) : '-' }</Typography>
											</Grid>
											<Grid item xs={12} lg={3}>
												<Typography variant="h5" className={classes.header}>End</Typography>
											</Grid>
											<Grid item xs={12} lg={3}>
												<Typography variant="subtitle1">{property.end_date ? moment(property.end_date).format(format_display_fancy_notime) : '-' }</Typography>
											</Grid>
											<Grid item xs={12} lg={3}>
												<Typography variant="h5" className={classes.header}>Rent</Typography>
											</Grid>
											<Grid item xs={12} lg={3}>
												<Typography variant="subtitle1">{property.agreement_amount ? formatNumber(property.agreement_amount || 0) : '-' }</Typography>
											</Grid>
											<Grid item xs={12} lg={3}>
												<Typography variant="h5" className={classes.header}>Rate</Typography>
											</Grid>
											<Grid item xs={12} lg={3}>
												<Typography variant="subtitle1">{property.agreement_rate ? formatNumber(property.agreement_rate || 0) : '-' }</Typography>
											</Grid>
											<Grid item xs={12} lg={3}>
												<Typography variant="h5" className={classes.header}>Deposit</Typography>
											</Grid>
											<Grid item xs={12} lg={3}>
												<Typography variant="subtitle1">{property.agreement_deposit ? formatNumber(property.agreement_deposit || 0) : '-' }</Typography>
											</Grid>
											<Grid item xs={12} lg={3}>
												<Typography variant="h5" className={classes.header}>Escalation Percentage</Typography>
											</Grid>
											<Grid item xs={12} lg={3}>
												<Typography variant="subtitle1">{property.agreement_escalation ? property.agreement_escalation : '0' }%</Typography>
											</Grid>
											<Grid item xs={12} lg={3}>
												<Typography variant="h5" className={classes.header}>Notice Period</Typography>
											</Grid>
											<Grid item xs={12} lg={3}>
												<Typography variant="subtitle1">{property.agreement_notice_period ? property.agreement_notice_period : '0' } Months</Typography>
											</Grid>
											<Grid item xs={12} lg={3}>
												<Typography variant="h5" className={classes.header}>Renewal Date</Typography>
											</Grid>
											<Grid item xs={12} lg={3}>
												<Typography variant="subtitle1">{property.agreement_renewal_date ? moment(property.agreement_renewal_date).format(format_display_fancy_notime) : '-' }</Typography>
											</Grid>
											<Grid item xs={12} lg={3}>
												<Typography variant="h5" className={classes.header}>Agreement Initial Txn ID</Typography>
											</Grid>
											<Grid item xs={12} lg={3}>
												<Typography variant="subtitle1">{property.agreement_transaction_id ? property.agreement_transaction_id : '-' }</Typography>
											</Grid>
										</Grid>
									</Grid>
								</div>
							</Paper>
						</Grid>)
					}
					{
						property.id && (
						<Grid item xs={12} lg={12}>
							<Paper variant="outlined" className={classes.section} elevation={3}>
								<CardHeader
									title="Alerts"
									titleTypographyProps={{variant: 'h4'}}
								/>
								<Divider/>
								<div style={{minHeight: 100, marginRight: 16, marginLeft: 16, marginTop: 8, marginBottom: 16}}>
									<Grid container justify="flex-start" spacing={2} alignItems="center">
										<Grid item xs={12} lg={6}>
											<Typography variant="h5" className={classes.header}>Rent Due</Typography>
										</Grid>
										<Grid item xs={12} lg={3}>
											<Switch
												checked={alertConfig && alertConfig.alert_rent_due ? alertConfig.alert_rent_due : false}
												onChange={handleAlertChange('alert_rent_due', true)}
												name="rent-due"
												inputProps={{ 'aria-label': 'secondary checkbox' }}
											/>
										</Grid>
										<Grid item xs={12} lg={3}>
											<TextField 
												id="rent_due_days" 
												label="Alert before due date" 
												name="amount"
												variant="outlined"
												margin="dense"
												value={alertConfig && alertConfig.rent_due_days ? alertConfig.rent_due_days : 0}
												InputProps={{
													startAdornment: <InputAdornment position="start">Days</InputAdornment>,
													min: 0
												}}
												style={{marginTop: 8}}
												type="number"
												onChange={handleAlertChange('rent_due_days', false)}/>
										</Grid>
										<Grid item xs={12} lg={6}>
											<Typography variant="h5" className={classes.header}>Agreement Due</Typography>
										</Grid>
										<Grid item xs={12} lg={3}>
											<Switch
												checked={alertConfig && alertConfig.alert_agreement_due ? alertConfig.alert_agreement_due : false}
												onChange={handleAlertChange('alert_agreement_due', true)}
												name="agreement-due"
												inputProps={{ 'aria-label': 'secondary checkbox' }}
											/>
										</Grid>
										<Grid item xs={12} lg={3}>
											<TextField 
												id="agreement_due_days" 
												label="Alert before due date" 
												name="amount"
												variant="outlined"
												margin="dense"
												value={alertConfig && alertConfig.agreement_due_days ? alertConfig.agreement_due_days : 0}
												InputProps={{
													startAdornment: <InputAdornment position="start">Days</InputAdornment>,
													min: 0
												}}
												style={{marginTop: 8}}
												type="number"
												onChange={handleAlertChange('agreement_due_days', false)}/>
										</Grid>
										<Grid item xs={12} lg={6}>
											<Typography variant="h5" className={classes.header}>Tax Due</Typography>
										</Grid>
										<Grid item xs={12} lg={3}>
											<Switch
												checked={alertConfig && alertConfig.alert_tax_due ? alertConfig.alert_tax_due : false}
												onChange={handleAlertChange('alert_tax_due', true)}
												name="tax-due"
												disabled={!property.tax_receipt_date}
												inputProps={{ 'aria-label': 'secondary checkbox' }}
											/>
										</Grid>
										<Grid item xs={12} lg={3}>
											<TextField 
												id="tax_due_days" 
												label="Alert before due date" 
												name="amount"
												disabled={!property.tax_receipt_date}
												variant="outlined"
												margin="dense"
												value={alertConfig && alertConfig.tax_due_days ? alertConfig.tax_due_days : 0}
												InputProps={{
													startAdornment: <InputAdornment position="start">Days</InputAdornment>,
													min: 0
												}}
												style={{marginTop: 8}}
												type="number"
												onChange={handleAlertChange('tax_due_days', false)}/>
										</Grid>
										<Grid item xs={12} lg={12}>
											<Divider style={{marginTop: 8, marginBottom: 8}}/>
										</Grid>
										<Grid item xs={12} lg={12}>
											<div style={{float: 'right'}}>
												<Button variant="contained" disabled={!alertConfig.modified} color="primary" onClick={save} style={{marginLeft: 8}}>Save</Button>											
											</div>
										</Grid>
									</Grid>
								</div>
							</Paper>
						</Grid>)
					}
				</Grid>
			</Grid>
		</Dialog>
	);
}

function DocumentPlaceHolder(props){
	// let classes = useStyles();
	// const {error} = props;
	const [link, setLink] = useState(null);

	const hiddenFileInput = React.useRef(null);

	useEffect(() => {
		setLink(props.link);
	}, [props.link]);

    const handleClick = () => {
        hiddenFileInput.current.click();
	}
	
	const change = (event) => {
		const fileUploaded = event.target.files[0];
		// setLink(URL.createObjectURL(fileUploaded));
		props.change(fileUploaded);
	}

	return (
		<div>
			<ButtonGroup disableElevation size="small" color="inherit" aria-label="outlined primary button group">
				{
					link && (
						<Button href={link} color="inherit" target="_blank">View</Button>
					)
				}
				{
					link && (
						<Button onClick={() => props.remove()}>Remove</Button>
					)
				}
				{
					!link && (
						<Button onClick={handleClick}>Upload</Button>
					)
				}
			</ButtonGroup>
			<input accept="image/*,application/pdf" type="file" onChange={change} onClick={(event) =>  event.target.value = null} ref={hiddenFileInput} style={{display:'none'}} />
		</div>
	)
}

function ImageHolder(props){
	const [link, setLink] = React.useState(null);

	useEffect(() => {
		setLink(props.link);
	}, [props.link]);

    const handleClick = (e) => {
		e.stopPropagation();
        props.remove()
	}

	let classes = useStyles();
	return (
		<ButtonBase
			focusRipple
			className={classes.image}>
			<a href={link} target="_blank" rel="noopener noreferrer">
				<img 
					src={link} 
					height="200" width="200" 
					alt=""/>
			</a>
			<span className={classes.imageButtonBottom} onClick={handleClick}>
				<Typography
					component="span"
					variant="subtitle1"
					color="inherit"
					className={classes.imageTitle}
				>
					<p style={{margin: 0}}><DeleteIcon fontSize="small"/></p>
					<span className={classes.imageMarked} />
				</Typography>
			</span>
		</ButtonBase>
	)
}

function AddImage(props){
	const hiddenFileInput = React.useRef(null);
	const [link, setLink] = React.useState(props.link);

    const handleClick = () => {
        hiddenFileInput.current.click();
	}
	
	const change = (event) => {
		const fileUploaded = event.target.files[0];
		setLink(URL.createObjectURL(fileUploaded));
		props.change(fileUploaded);
	}

	let classes = useStyles();
	return (
		<ButtonBase
			focusRipple
			className={classes.image}>
			<img 
				src={link} 
				height="200" width="200" 
				alt=""/>
			<span className={classes.imageBackdrop}/>
			<span className={classes.imageButton} onClick={handleClick}>
				<Typography
					component="span"
					variant="subtitle1"
					color="inherit"
					className={classes.imageTitle}
				>
					<p><AddCircleIcon fontSize="large"/></p>
					{props.title}
					<span className={classes.imageMarked} />
				</Typography>
				<input accept="image/*" type="file" onChange={change} ref={hiddenFileInput} onClick={(event) =>  event.target.value = null} style={{display:'none'}} />
			</span>
		</ButtonBase>
	)
}

ViewPropertyDialog.prototype = {
    open: PropTypes.bool.isRequired,
    id: PropTypes.string,
    onClose: PropTypes.func.isRequired
};