import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { blue } from '@material-ui/core/colors';
import { ListSubheader, ListItemSecondaryAction, Icon, Fade, CircularProgress, Typography } from '@material-ui/core';
import { BootstrapTooltip, convertVacancyStatusToString } from '../Utils';
import { AppContext } from '../AppContextProvider';
import { PropertyService } from '../Services/PropertyService';
const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

export default function SearchDialog(props) {
    const classes = useStyles();
    const {openViewProperty, showSnackBar, openNewProperty} = useContext(AppContext);
    const { onClose, text, open } = props;
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);

    React.useEffect(() => {
      if(open && text.length  > 0){
        PropertyService.searchProperties(text, 0, 20)
        .then(data => {
          console.log(data);
          setItems(data.properties || []);
          setLoading(false);
        })
        .catch(error => {
          console.log(error);
          showSnackBar('Something weng wrong. Please try Again');
          setLoading(false);
          handleClose();
        })
      }
      return () => {
        setItems([]);
      }
    }, [open, text])

    const handleClose = () => {
      setItems([]);
      onClose();
    };

    const viewClick = (value) => {
        openViewProperty(value.id);
        handleClose();
    };
    
    const editClick = (value) => {
        openNewProperty(value.id);
        handleClose();
    };

    return (
      <Dialog fullWidth maxWidth="md" onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
        <DialogTitle id="simple-dialog-title">{`Searching for ${text} `}</DialogTitle>
        <Fade
            in={loading}
            style={{
                transitionDelay: loading ? '10ms' : '0ms'
            }}
            unmountOnExit
            >
              <div style={{height: 200}}>
                <CircularProgress style={{position: 'relative', top: '30%', left: '45%'}}/>
              </div>
        </Fade>
        {
          !loading &&  (
              <List subheader={<ListSubheader></ListSubheader>}>
                {items.length > 0 ? 
                  items.map((item, index) => (
                    <ListItem key={index}>
                      <ListItemAvatar>
                        <Avatar className={classes.avatar}>
                          {index + 1}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText 
                        primary={`Name: ${item.name}, ${item.nickname ? `NickName: ${item.nickname}`: ``}, City: ${item.city}, State: ${item.state}, Pin: ${item.pincode}`} 
                        secondary={`Type ${item.type} Vacancy: ${convertVacancyStatusToString(item.vacancy_status)}`}
                        />
                      <ListItemSecondaryAction>
                        <BootstrapTooltip arrow title="View">
                            <Icon 
                                fontSize="small" 
                                style={{marginTop: 8, marginRight: 8}} 
                                onClick={(e) => {
                                    e.stopPropagation();
                                    viewClick(item)
                                }}>library_books</Icon>
                        </BootstrapTooltip>
                        <BootstrapTooltip arrow title="Edit">
                            <Icon 
                                fontSize="small" 
                                style={{marginTop: 8, marginRight: 8}} 
                                onClick={(e) => {
                                    e.stopPropagation();
                                    editClick(item)
                                }}>edit</Icon>
                        </BootstrapTooltip>
                      </ListItemSecondaryAction>
                    </ListItem>
                    ))
                    : 
                    <Typography color="error" style={{marginLeft: 32}}>No matching results..</Typography>
                }
              </List>
          )
        }
        {
          items.length >= 20 &&
          <Typography variant="h5">Showing first 20 matching results...</Typography>
        }
      </Dialog>
    );
}

SearchDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired
};