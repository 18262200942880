import React from "react";
import { useContext } from "react";
import { AppContext } from "../AppContextProvider";
import { makeStyles, Grid, Paper, IconButton, Typography, GridList, GridListTile, GridListTileBar, Fab } from "@material-ui/core";
import { blue, red, grey } from "@material-ui/core/colors";
import { getTimeInIST, format_display_date, BootstrapTooltip, openLink, toInt } from "../Utils";
import { DocumentService } from "../Services/DocumentService";
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles(theme => ({
    section: {
        width: '100%',
        height: '100%',
        marginTop: theme.spacing(1),
        paddingTop : theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: 16,
        paddingRight: 16
    },
    fab: {
        margin: theme.spacing(1),
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    qtyField: {
        width: "40%",
        position: "relative",
        [theme.breakpoints.up('md')]: {
            right: '100px',
            width: "10%",
            marginRight: 10
        },
        right: "22px"
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginBottom : '20px',
    },
    grid: {
        [theme.breakpoints.up('md')]: {
            marginTop: '10px',
        },
        marginTop: '50px',
        marginBottom: '5px'
    },
    tablecell: {
        fontSize: '16pt',
        padding: theme.spacing(4)
    },
    container: {
        paddingTop : theme.spacing(1)
    },
    redAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: red[500],
    },
    blueAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: blue[500],
    },
    instructions: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },
    button: {
        marginTop: theme.spacing(1),
        // height: theme.spacing(7),
        float: 'right',
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            float: 'inherit',
            marginLeft: theme.spacing(1)
        },
        marginRight: theme.spacing(4),
        
    }, 
    tableWrapper: {
        maxHeight: 407,
        overflow: 'auto',
    },
    list: {
        width: '100%',
        height: 250,
        overflow: "auto"
    },
    summary: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        width: '40vw',
        [theme.breakpoints.down('md')]: {
           width: '100vw'
        },
    },
    title: {
        fontSize: 12,
        textAlign: 'left',
    },
    subtitle: {
        fontSize: 12,
        textAlign: 'left'
    },
    icon: {
        color: grey[50],
        padding: 8
    },
    selectRoot: {
        fontSize: 12,
        [theme.breakpoints.down('md')]: {
            marginLeft: 0,
            marginRight: 0
        },
    },
    gridList: {
        margin: 16,
        width: '100%'
    },
    titleBar: {
        background:
          'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
          'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
}));

export default function QuestionnairePage(props){

    const {state, setTitle, setLinearLoading, showProgressDialog, closeProgressDialog, showSnackBar, showConfirmDialog, closeConfirmDialog, showConfirmDialog1D, closeConfirmDialog1D} = useContext(AppContext);
    const classes = useStyles();
    const [data, setData] = React.useState([]);
    const type = 'questionnaire';

    React.useEffect(() => {
        setTitle('Manage Questionnaire');
        // console.log(state);
        return () => {
            setTitle('');
        }
    }, [true]);

    React.useEffect(() => {
        fetchData();
    }, []);
    
    const fetchData = () => {
        setLinearLoading(true);
        showProgressDialog();
        DocumentService.getList(type)
        .then(data => {
            console.log(data);
            if(data){
                setData(data);
            }
            setLinearLoading(false);
            closeProgressDialog();
        })
        .catch(error => {
            console.log(error);
            setLinearLoading(false);
            closeProgressDialog();
        });
    }

    const hiddenFileInput = React.useRef(null);

    const handleClick = () => {
        if(data.length < toInt(state.user.property_document_limit)){
            hiddenFileInput.current.click();
        } else {
            showConfirmDialog1D('You have reached maximum upload limit. Please contact Admin to increase the limit.', undefined, () => {
                closeConfirmDialog1D();
            }, 'OK')
        }
	}
	
	const change = (event) => {
        const fileUploaded = event.target.files[0];
        const formData = new FormData();
		formData.append('file', fileUploaded || null);
		showProgressDialog('Uploading...');
		// setLoading(true);
		DocumentService.addDocument(type, formData)
		.then(data => {
			closeProgressDialog();
			showSnackBar('Uploaded document', 'info', 1000);
			fetchData();
		})
		.catch(error => {
			console.log(error);
			showSnackBar('Something went wrong. Please try again.', 'error', 1000);
		})
    }
    
    const remove = (id) => {
        console.log(id);
		showConfirmDialog('Are you sure you want to remove file?', undefined, () => {
			closeConfirmDialog();
			DocumentService.removeDocument(id, type)
			.then(() => {
				showSnackBar('Removed document', 'info', 1000);
				fetchData();
			})
			.catch(error => {
				console.log(error);
				showSnackBar('Something went wrong. Please try again.', 'error', 1000);
			})
		});
    }
	

    return (
        <div className="Home">
            <div className="lander">
                <Grid container direction="row" spacing={1} justify="center">
                    <Grid item xl={8} lg={10} xs={12}>
                        <Paper className={classes.section}>
                            <Grid container spacin={1} alignItems="center">                                
                                <Grid item xs={12}>
                                    <GridList cellHeight={330} className={classes.gridList} cols={'auto'}>
                                    {
                                        data.map((data, i) => 
                                            <GridListTile key={i}>
                                                <embed 
                                                    width="300" 
                                                    height="330" 
                                                    name="plugin" 
                                                    src={data.link}
                                                    type={data.type}/>
                                                <GridListTileBar
                                                    classes={{root: classes.titleBar, title : classes.title, subtitle: classes.subtitle}}
                                                    title={data.original_file_name || ''}
                                                    subtitle={data.created_date ? getTimeInIST(data.created_date).format(format_display_date) : ''}
                                                    actionIcon={
                                                        <>
                                                            <IconButton aria-label={`download`} className={classes.icon} size="small" onClick={() => openLink(data.link)}>
                                                                <GetAppIcon />
                                                            </IconButton>
                                                            <IconButton aria-label={`remove`} className={classes.icon} size="small" onClick={() => {remove(data.id)}}>
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </>
                                                    }
                                                />
                                            </GridListTile>
                                        )
                                    }
                                    </GridList>
                                    {
                                        !state.linearLoading && data.length === 0 && (
                                            <div style={{height: 100, paddingTop: 40, cursor: 'pointer'}}  onClick={handleClick}>
                                                <Typography color="primary" variant="subtitle2">No Documents Available. Click here to get Started.</Typography>
                                            </div>
                                        )
                                    }
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
            <BootstrapTooltip arrow title="Upload New Document">
                <Fab color="primary" aria-label="Add" className={classes.fab} onClick={handleClick}>
                    <AddIcon />
                </Fab>
            </BootstrapTooltip>
            <input 
                accept="image/*,application/pdf, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain" 
                type="file" 
                onChange={change} 
                onClick={(event) =>  event.target.value = null} ref={hiddenFileInput} 
                style={{display:'none'}} />
        </div>
    )
}