import React from 'react';
import { withRouter, useHistory } from "react-router-dom";
// import logo from './logo.svg';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import './css/App.css';
import Routes from "./routes";
import { UserService } from './Services/UserService';
import MySnackbarContent from './components/SnackBar';
import ProgressDialog from './components/ProgressDialog';
import { messaging, requestToken, messaginIsSupported } from './init-fcm';
import {
  Snackbar,
  Dialog as DialogMUI,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  DialogTitle,
  CssBaseline,
  Fade,
  LinearProgress,
  FormControl,
  OutlinedInput,
  ThemeProvider,
  makeStyles,
  FormHelperText,
} from '@material-ui/core';
import * as Chart from "chart.js";
import AppDrawer from './AppDrawer';
import MyAppBar from './MyAppBar';
import { AppContext } from './AppContextProvider';
import { useContext } from 'react';
import CreatePropertyDialog from './PropertyPage/CreatePropertyDialog';
import ViewPropertyDialog from './PropertyPage/ViewPropertyDialog';
import ViewTenantDialog from './TenantPage/ViewTenantDialog';
import ModifyTenant from './TenantPage/ModifyTenant';
import CreatePADialog from './PAPage/CreatePADialog';
import ViewPADialog from './PAPage/ViewPADialog';

Chart.Tooltip.positioners.custom = function(elements, position) {
  if (!elements.length) {
    return false;
  }
  var offset = 0;
  //adjust the offset left or right depending on the event position
  if (elements[0]._chart.width / 2 > position.x) {
    offset = 20;
  } else {
    offset = -120;
  }

  var offsetY = 0;
  if (elements[0]._chart.height / 2 > position.y) {
      offsetY = 20;
  } else {
      offsetY = -200;
  }
  return {
    x: position.x + offset,
    y: position.y + offsetY
  }
}

Chart.Tooltip.positioners.sales = function(elements, position) {
	// if (!elements.length) {
	//   return false;
	// }
	// var offset = 0;
	// //adjust the offset left or right depending on the event position
	// if (elements[0]._chart.width / 2 > position.x) {
	//   offset = 20;
	// } else {
	//   offset = -120;
	// }
  
	// var offsetY = 0;
	// if (elements[0]._chart.height / 2 > position.y) {
	// 	offsetY = 20;
	// } else {
	// 	offsetY = -200;
	// }
	var tooltip = this;

	if (!elements.length) {
	  return false;
	}
	var offset = 0;
	//adjust the offset left or right depending on the event position
	if (elements[0]._chart.width / 2 > position.x) {
	  offset = 20;
	} else {
	  offset = -(tooltip._view.width + 20);
	}
  
	var offsetY = 0;
	if (elements[0]._chart.height / 2 > position.y) {
		offsetY = 20;
	} else {
		offsetY = -140;
	}

	return {
	  x: position.x + offset,
	  y: position.y + offsetY
	}
  }

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
	},
	toolbar: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: '0 8px',
		...theme.mixins.toolbar,
	},
	sectionMobile: {
		display: 'none',
		[theme.breakpoints.up('md')]: {
		display: 'flex',
		},
	},
	accountCircle: {
		marginRight: theme.spacing(3)
	},
}));


function App(props) {

	const classes = useStyles();
	const {state,
		initializeTheme,
		handleSBClose,
		closeConfirmDialog,
		closeConfirmDialog1D,
		handleConnectionChange,
		userHasAuthenticated,
		handleLogout,
		updateUser,
		showSnackBar,
		setAuthenticating,
		showProgressDialog,
		closeProgressDialog,
		setDialogFieldText,
		closeNewProperty,
		closeViewProperty,
		closeViewTenant,
		closeModifyTenant,
		closeViewPA,
		closeNewPA
	} = useContext(AppContext);
	const history = useHistory();

	React.useEffect(() => {
		handleConnectionChange();
		window.addEventListener('online', handleConnectionChange());
		window.addEventListener('offline', handleConnectionChange());
		initializeTheme();
		if(props.location.pathname === '/reset/password'){
			history.push(props.location.pathname);
		} else if(state.user === null) {
			history.push("/login");
		}
		try {
			// if (state.user !== null) {
			// 	userHasAuthenticated(true);
			// }
			if(state.user){
				showProgressDialog('Logging in...');
				UserService.authenticateToken()
				.then((data) => {
					if (data.id && data.status === '1') {
						updateUser(data);
						localStorage.setItem('caretaker.user', JSON.stringify(data));
						localStorage.setItem('caretaker.session', data['sysbrew-x-token']);
						userHasAuthenticated(true);
						// OrganisationService.getOrganisation()
						// .then((data) => {
						// 	localStorage.setItem('caretaker.organisation', JSON.stringify(data));
						// })
						// .catch(error => {
						// 	console.log("Error fetching organisation: ", error);
						// });

						//FCM
						if(messaginIsSupported){
							messaging.requestPermission()
							.then( () => {
								console.log("Have Permission");
								requestToken(state.user);
							})
							.catch((err) => {
							  console.log("Unable to get permission to notify.", err);
							});  
							messaging.onMessage((payload) => {
								let title = payload.notification.title;
								showSnackBar(title, 'info');
								console.log('Message received. ', payload);
							});
						}
						if(data['app-version'] && data['app-version'] !== global.appVersion){
							showSnackBar('New App Update Available. Please click Reload or CTRL+SHIFT+R', 'info', 10000, 'RELOAD', () => {
								window.location.reload(true)
							})
						}  
						history.push(props.location.pathname);
					} else {
						handleLogout();
						history.push("/login");
					}
					closeProgressDialog();
					setAuthenticating(false);
				})
				.catch((error) => {
					handleLogout();
					closeProgressDialog();
					setAuthenticating(false);
				});
			}
		} catch(e) {
			if (e !== 'No current user') {
				alert(e);
			}
			closeProgressDialog();
			setAuthenticating(false);
		}
		
		return () => {
			window.removeEventListener('online', handleConnectionChange);
			window.removeEventListener('offline', handleConnectionChange);
		}
	}, [true]);

    return (
        <ThemeProvider theme={state.theme}>
			<div className="App container">
				<div className={classes.root}>
					<Fade
						in={state.linearLoading}
						style={{
							transitionDelay: state.linearLoading ? '50ms' : '0ms',
						}}
						unmountOnExit
						>
						<LinearProgress 
							id="test" 
							style={{height: 5, position: "fixed", top: 64, width: "98.5%"}} />
					</Fade>
					<CssBaseline />
					<MyAppBar />
					{ state.isAuthenticated  &&
						<AppDrawer />
					}
				</div>
				<Routes style={{width: "50%"}}/>
				<Snackbar
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					open={state.sb.open}
					autoHideDuration={state.sb.autohide}
					onClose={handleSBClose}>
						<MySnackbarContent
							onClose={handleSBClose}
							actionText={state.sb.actionText}
							actionCallback={state.sb.actionCallback}
							variant={state.sb.varient}
							message={state.sb.message}
						/>
				</Snackbar>
				<ProgressDialog open={state.progressOpen} message={state.progressMessage}/>
				<DialogMUI
					style={{zIndex: 2000}}
					disableEnforceFocus
					open={state.openDialog}
					onClose={closeConfirmDialog}
					fullWidth={true}
					maxWidth='sm'
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description">
						{state.dialog_title ? <DialogTitle id="alert-dialog-title">{state.dialog_title}</DialogTitle> : ''}
						<DialogContent>
							<DialogContentText id="alert-dialog-description">
								{state.dialog_message}
							</DialogContentText>
							{
								state.showfield && 
								<div>
									<FormControl variant="outlined" key="field" fullWidth>
										<OutlinedInput
											fullWidth
											placeholder={state.fieldHint}
											defaultValue={state.fieldValue}
											id="outlined-adornment-field"
											onChange={(e) => { 
												e.target.value && e.target.value.length > 0 && setDialogFieldText(e.target.value) }}
											aria-describedby="outlined-weight-helper-text"
											inputProps={{
											'aria-label': 'rate',
											}}
											labelWidth={0}
											multiline
											error={state.fieldError || false}
											rowsMax={2}
										/>
										{
											state.fieldError && 
											<FormHelperText error={true}>{state.fieldError}</FormHelperText>
										}
									</FormControl>
								</div>
							}
						</DialogContent>
						<DialogActions>
							<Button onClick={closeConfirmDialog} color="primary">
								{state.confirmAction ? state.cancel_title : "Ok"}
							</Button>
							{
								state.confirmAction &&
								<Button onClick={(e) => 
									state.showfield ? 
										state.confirmAction(state.dialog_field_text) 
									: 
										state.confirmAction()
									} 
									color="primary" 
									autoFocus>
										{state.accept_title}
								</Button>
							}
						</DialogActions>
				</DialogMUI>
				<DialogMUI
					style={{zIndex: 2000}}
					disableEnforceFocus
					open={state.openDialog_1D}
					onClose={closeConfirmDialog1D}
					fullWidth={true}
					maxWidth='sm'
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description">
						{state.dialog_title_1D ? <DialogTitle id="alert-dialog-title">{state.dialog_title_1D}</DialogTitle> : ''}
						<DialogContent>
							<DialogContentText id="alert-dialog-description">
								{state.dialog_message_1D}
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button 
								onClick={() => {
									closeConfirmDialog();
									if(state.confirmAction_1D){
										state.confirmAction_1D();
									}
								}} color="primary">
								{state.button_title_1D ? state.button_title_1D : "Ok"}
							</Button>
						</DialogActions>
				</DialogMUI>
				<CreatePropertyDialog 
					open={state.openNewProperty}
					id={state.openNewPropertyId}
					onClose={closeNewProperty}/>
				<ViewPropertyDialog 
					open={state.openViewProperty}
					id={state.openViewPropertyId}
					onClose={closeViewProperty}/>
				<ModifyTenant 
					open={state.openModifyTenant}
					id={state.openModifyTenantId}
					onClose={closeModifyTenant}/>
				<ViewTenantDialog
					open={state.openViewTenant}
					id={state.openViewTenantId}
					onClose={closeViewTenant}/>
				<CreatePADialog 
					open={state.openNewPA}
					id={state.openNewPAId}
					onClose={closeNewPA}/>
				<ViewPADialog 
					open={state.openViewPA}
					id={state.openViewPAId}
					onClose={closeViewPA}/>
			</div>
        </ThemeProvider>
    );
}

export default withRouter(App);
