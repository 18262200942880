import React from 'react';
import MyDialog from "./MyDialog";
import PropTypes from 'prop-types';
import {INVOICE_STATUS_OPTIONS} from '../Constants';
import { List, ListItem, ListItemText } from '@material-ui/core';
import { PropertyService } from '../Services/PropertyService';
import { useContext } from 'react';
import { AppContext } from '../AppContextProvider';

const ChangeInvoiceStatus = ({open, fullWidth, maxWidth, id, subtitle, refresh, handleClose}) => {

    const {showConfirmDialog, closeConfirmDialog, showProgressDialog, closeProgressDialog, showSnackBar} = useContext(AppContext);

    const changeStatus = (status) => {
        showConfirmDialog("Confirm Change Status?", undefined, () => {
            showProgressDialog();
            PropertyService.changeInvoiceStatus(id, status.value)
            .then(data => {
                if(data.success){
                    handleClose();
                    refresh();
                }
                closeProgressDialog();
            })
            .catch(error => {
                showSnackBar('Something went wrong. Please try again later.', 'error');
                handleClose();
                closeProgressDialog();
            })
            closeConfirmDialog();
        });
    }

    return (
        <MyDialog
            maxWidth={maxWidth || false}
            fullWidth={fullWidth || false}
            content={
                <div style={{paddingLeft: 16, paddingRight: 16}}>
                    <List aria-label="secondary">
                        {
                            INVOICE_STATUS_OPTIONS.map((status) => 
                                <ListItem key={status.key} button onClick={() => changeStatus(status)}>
                                    <ListItemText primary={status.key} />
                                </ListItem>
                            )
                        }
                    </List>
                </div>
            }
            title={'Change Status'}
            subtitle={subtitle || ''}
            open={open}
            close={() => {
                handleClose();
            }}/>
    )
}

ChangeInvoiceStatus.propTypes = {
    id: PropTypes.string,
    subtitle: PropTypes.string,
    handleClose: PropTypes.func.isRequired,
    refresh: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};

export default ChangeInvoiceStatus;