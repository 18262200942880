import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import PropTypes from 'prop-types';
import { Grid, Paper, CardHeader, Button, ButtonGroup, Hidden } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { formatNumber, format_display_fancy_notime } from '../Utils';
import { AppContext } from '../AppContextProvider';
import moment from 'moment';
import { TenantService } from '../Services/TenantService';

const useStyles = makeStyles((theme) => ({
	appBar: {
		position: 'relative',
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1,
		color: theme.palette.white
	},
	header: {
		fontWeight: 700
	},
	value: {
		paddingLeft: theme.spacing(1)
	},
	page: {
		height: '100%',
		backgroundColor: theme.palette.divider,
		overflow: 'auto'
	},
	section: {
		marginLeft: 16,
		marginRight: 16,
		marginTop: 8, 
		marginBottom: 8,
		minHeight: 200,
	},
	avatar: {
		backgroundColor: red[500],
	},
	image: {
		margin: 12,
		position: 'relative',
		height: 200,
		width: 200,
		[theme.breakpoints.down('xs')]: {
			width: '100% !important', // Overrides inline-style
			height: 100,
		},
		'&:hover,  &Mui-focusVisible': {
			zIndex: 1,
		'& $imageBackdrop': {
			opacity: 0.7,
		},
		'& $imageMarked': {
			opacity: 0,
		},
		'& $imageTitle': {
			border: '4px solid currentColor',
		},
		},
	},
	imageBackdrop: {
		borderRadius: 8,
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		backgroundColor: theme.palette.common.black,
		opacity: 0.3,
		transition: theme.transitions.create('opacity'),
	},
	imageTitle: {
		position: 'relative',
		padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`,
	},
	imageMarked: {
		height: 3,
		width: 18,
		backgroundColor: theme.palette.common.white,
		position: 'absolute',
		bottom: -2,
		left: 'calc(50% - 9px)',
		transition: theme.transitions.create('opacity'),
	},
	imageButton: {
		position: 'absolute',
		left: 0,
		right: 0,
		bottom: 0,
		top: 0,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: theme.palette.common.white,
	},
	imageButtonBottom: {
		position: 'absolute',
		left: 0,
		right: 0,
		// top: 0,
        // bottom: 0,
        bottom: 4,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: theme.palette.common.white,
	},
	formAutoClass: {
		zIndex: 3,
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
	card: {
		margin: 8
	}
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ViewTenantDialog(props) {
	const {showProgressDialog, closeProgressDialog, showConfirmDialog1D, closeConfirmDialog1D} = useContext(AppContext);
	const {id} = props;
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);
	const [tenant, setTenant] = React.useState({});
	// const [loading, setLoading] = React.useState(true);

	React.useEffect(() => {
		setOpen(props.open);
		if(props.open){
			showProgressDialog();
			fetchData();
		}
		return () => {
			setTenant({});
			setOpen(false);
		}
	},[props.open]);

	const fetchData = () => {
		// setLoading(true);
		TenantService.getTenantById(id)
		.then(data => {
			console.log(data);
			if(data){
				setTenant(data);
			}
			closeProgressDialog();
			// setLoading(false);
		})
		.catch(error => {
			console.log(error);
			showConfirmDialog1D('Something went wrong. Please try again.', undefined, () => {
				handleClose();
				closeConfirmDialog1D();
			});
			// setLoading(false);
		  	closeProgressDialog();
		});
	}

	const handleClose = (callback = false) => {
		props.onClose(callback);
		setOpen(false);
	};

  	return (
		<Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
			<AppBar className={classes.appBar}>
				<Toolbar>
				<IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
					<CloseIcon />
				</IconButton>
				<Typography variant="h6" className={classes.title}>
					{tenant.name && `Tenant : ${tenant.name} ${tenant.type ? `(${tenant.type})`: '' }` }
				</Typography>
				</Toolbar>
			</AppBar>
			{/* <Backdrop className={classes.backdrop} open={loading} onClick={() => {}}>
				<div style={{padding: 50, backgroundColor: 'white', borderRadius: 8}}>
					<CircularProgress 
							size={40}
							thickness={4}/>
				</div>
			</Backdrop> */}
			<Grid container className={classes.page} spacing={0} justify="center">
				<Grid container item spacing={1} xs={12} lg={12} alignContent="flex-start" alignItems="flex-start" justify="flex-start">
					{
						tenant.id && (
						<Grid item xs={12} lg={12}>
							<Paper variant="outlined" className={classes.section} elevation={3}>
								<CardHeader
									title="Tenant information"
									titleTypographyProps={{variant: 'h4'}}
								/>
								<Divider/>
								<div style={{minHeight: 100, marginRight: 16, marginLeft: 16, marginTop: 8, marginBottom: 8}}>
									<Grid container justify="center" spacing={2} alignItems="center">
										<Grid item xs={12} lg={12} container justify="flex-start" spacing={1} alignItems="center">
											<Grid item xs={12} lg={3}>
												<Typography variant="h5" className={classes.header}>Name</Typography>
											</Grid>
											<Grid item xs={12} lg={3}>
												<Typography variant="subtitle1">{tenant.name || ''}</Typography>
											</Grid>
											<Grid item xs={12} lg={3}>
												<Typography variant="h5" className={classes.header}>{tenant.type !== 'INDIVIDUAL' ? 'Designation' : ''}</Typography>
											</Grid>
											<Grid item xs={12} lg={3}>
												<Typography variant="subtitle1">{tenant.designation || '-'}</Typography>
											</Grid>
											<Grid item xs={12} lg={3}>
												<Typography variant="h5" className={classes.header}>Address</Typography>
											</Grid>
											<Grid item xs={12} lg={9}>
												<Typography variant="subtitle1">{tenant.address1 || ''}{`, ${tenant.address2}` || ''}, {tenant.city || ''}, {tenant.state || ''}, {tenant.pincode || ''}</Typography>
											</Grid>
											{
												tenant.type !== 'INDIVIDUAL' && (
													<Grid item xs={12} lg={3}>
														<Typography variant="h5" className={classes.header}>GST</Typography>
													</Grid>
												)
											}
											{
												tenant.type !== 'INDIVIDUAL' && (
													<Grid item xs={12} lg={3}>
														<Typography variant="subtitle1" color="primary">{tenant.gst || '-'}</Typography>
													</Grid>
												)
											}
											<Grid item xs={12} lg={3}>
												<Typography variant="h5" className={classes.header}>Mobile</Typography>
											</Grid>
											<Grid item xs={12} lg={3}>
												<Typography variant="subtitle1">{tenant.mobile || '-'}</Typography>
											</Grid>
											<Grid item xs={12} lg={3}>
												<Typography variant="h5" className={classes.header}>Email</Typography>
											</Grid>
											<Grid item xs={12} lg={3}>
												<Typography variant="subtitle1">{tenant.email || '-'}</Typography>
											</Grid>
											<Grid item xs={12} lg={3}>
												<Typography variant="h5" className={classes.header}>Comments</Typography>
											</Grid>
											<Grid item xs={12} lg={3}>
												<Typography variant="subtitle1">{tenant.comments ? tenant.comments : '-'}</Typography>
											</Grid>
										</Grid>
									</Grid>
								</div>
							</Paper>
						</Grid>)
					}
					{
						tenant.contacts && (
						<Grid item xs={12} lg={12}>
							<Paper variant="outlined" className={classes.section} elevation={3}>
								<CardHeader
									title="Contact Information"
									titleTypographyProps={{variant: 'h4'}}
								/>
								<Divider/>
								<div style={{minHeight: 100, marginRight: 16, marginLeft: 16, marginTop: 8, marginBottom: 8}}>
									<Grid container justify="center" spacing={2} alignItems="center">
										<Hidden mdDown>
											<Grid item xs={12} lg={12} container justify="center" spacing={2} alignItems="center">
												<Grid item xs={12} lg={3}>
													<Typography variant="h5" className={classes.header}>Name</Typography>
												</Grid>
												<Grid item xs={12} lg={1}>
													<Typography variant="h5" className={classes.header}>Mobile</Typography>
												</Grid>
												<Grid item xs={12} lg={3}>
													<Typography variant="h5" className={classes.header}>Email</Typography>
												</Grid>
												<Grid item xs={12} lg={2}>
													<Typography variant="h5" className={classes.header}>Department</Typography>
												</Grid>
												<Grid item xs={12} lg={3}>
													<Typography variant="h5" className={classes.header}>Address</Typography>
												</Grid>
												{
													tenant.contacts.map(contact => (
														<Grid key={contact.id} item container xs={12} lg={12}>
															<Grid item xs={12} lg={3}>
																<Typography variant="subtitle1">{contact.name || '-'}</Typography>
															</Grid>
															<Grid item xs={12} lg={1}>
																<Typography variant="subtitle1">{contact.mobile || '-' }</Typography>
															</Grid>
															<Grid item xs={12} lg={3}>
																<Typography variant="subtitle1">{contact.email || '-'}</Typography>
															</Grid>
															<Grid item xs={12} lg={2}>
																<Typography variant="subtitle1" className={classes.value}>{contact.department || '-' }</Typography>
															</Grid>
															<Grid item xs={12} lg={3}>
																<Typography variant="subtitle1" className={classes.value}>{contact.address || '-' }</Typography>
															</Grid>
														</Grid>
													))
												}
											</Grid>
										</Hidden>
										<Hidden mdUp>
											<div style={{overflow: "auto"}}>
											{
												tenant.contacts.map(contact => (
													<Grid item key={contact.id} xs={12} lg={12} container justify="center" spacing={2} alignItems="center">
														<Grid item xs={5}>
															<Typography variant="h5" className={classes.header}>Name</Typography>
														</Grid>
														<Grid item xs={7}>
															<Typography variant="subtitle1">{contact.name || '-'}</Typography>
														</Grid>
														<Grid item xs={5}>
															<Typography variant="h5" className={classes.header}>Mobile</Typography>
														</Grid>
														<Grid item xs={7}>
																<Typography variant="subtitle1">{contact.mobile || '-' }</Typography>
															</Grid>
														<Grid item xs={5}>
															<Typography variant="h5" className={classes.header}>Email</Typography>
														</Grid>
														<Grid item xs={7}>
																<Typography variant="subtitle1">{contact.email || '-'}</Typography>
															</Grid>
														<Grid item xs={5}>
															<Typography variant="h5" className={classes.header}>Department</Typography>
														</Grid>
														<Grid item xs={7}>
																<Typography variant="subtitle1" className={classes.value}>{contact.department || '-' }</Typography>
															</Grid>
														<Grid item xs={5}>
															<Typography variant="h5" className={classes.header}>Address</Typography>
														</Grid>
														<Grid item xs={7}>
															<Typography variant="subtitle1" className={classes.value}>{contact.address || '-' }</Typography>
														</Grid>
														<Grid item xs={12}>
															<Divider/>
														</Grid>
													</Grid>
												))
											}
											</div>
										</Hidden>
									</Grid>
								</div>
							</Paper>
						</Grid>)
					}
					{
						tenant.agreements && (
						<Grid item xs={12} lg={12}>
							<Paper variant="outlined" className={classes.section} elevation={3}>
								<CardHeader
									title="Agreements"
									titleTypographyProps={{variant: 'h4'}}
								/>
								<Divider/>
								<div style={{minHeight: 100, marginRight: 16, marginLeft: 16, marginTop: 8, marginBottom: 8}}>
									<Grid container justify="center" spacing={2} alignItems="center">
										<Hidden mdDown>
											<Grid item xs={12} lg={12} container justify="center" spacing={2} alignItems="center">
												<Grid item xs={12} lg={2}>
													<Typography variant="h5" className={classes.header}>Property</Typography>
												</Grid>
												<Grid item xs={12} lg={2}>
													<Typography variant="h5" className={classes.header}>Start</Typography>
												</Grid>
												<Grid item xs={12} lg={2}>
													<Typography variant="h5" className={classes.header}>End</Typography>
												</Grid>
												<Grid item xs={12} lg={2}>
													<Typography variant="h5" className={classes.header}>Rent</Typography>
												</Grid>
												<Grid item xs={12} lg={2}>
													<Typography variant="h5" className={classes.header}>Deposit</Typography>
												</Grid>
												<Grid item xs={12} lg={2}>
													<Typography variant="h5" className={classes.header}>Agreement</Typography>
												</Grid>
												{
													tenant.agreements.map((agreement, i) => (
														<Grid key={i} item container xs={12} lg={12}>
															<Grid item xs={12} lg={2}>
																<Typography variant="subtitle1">{agreement.property_name || '-'}</Typography>
															</Grid>
															<Grid item xs={12} lg={2}>
																<Typography variant="subtitle1">{agreement.start_date ? moment(agreement.start_date).format(format_display_fancy_notime) : '-'}</Typography>
															</Grid>
															<Grid item xs={12} lg={2}>
																<Typography variant="subtitle1">{agreement.end_date ? moment(agreement.end_date).format(format_display_fancy_notime) : '-' }</Typography>
															</Grid>
															<Grid item xs={12} lg={2}>
																<Typography variant="subtitle1" className={classes.value}>{agreement.agreement_amount ? formatNumber(agreement.agreement_amount) : '-'}</Typography>
															</Grid>
															<Grid item xs={12} lg={2}>
																<Typography variant="subtitle1" className={classes.value}>{agreement.agreement_deposit ? formatNumber(agreement.agreement_deposit) : '-'}</Typography>
															</Grid>
															<Grid item xs={12} lg={2}>
																{
																	agreement.link && (
																		<ButtonGroup disableElevation className={classes.value} size="small" color="inherit" aria-label="outlined primary button group">
																			<Button href={agreement.link} color="inherit" target="_blank">View</Button>
																		</ButtonGroup>
																	)
																}
															</Grid>
														</Grid>
													))
												}
											</Grid>
										</Hidden>
										<Hidden mdUp>
											{
												tenant.agreements.map((agreement, i) => (
													<Grid item key={i} xs={12} lg={12} container justify="center" spacing={2} alignItems="center">
														<Grid item xs={5}>
															<Typography variant="h5" className={classes.header}>Property</Typography>
														</Grid>
														<Grid item xs={7}>
															<Typography variant="subtitle1">{agreement.property_name || '-'}</Typography>
														</Grid>
														<Grid item xs={5}>
															<Typography variant="h5" className={classes.header}>Start</Typography>
														</Grid>
														<Grid item xs={7}>
															<Typography variant="subtitle1">{agreement.start_date ? moment(agreement.start_date).format(format_display_fancy_notime) : '-'}</Typography>
														</Grid>
														<Grid item xs={5}>
															<Typography variant="h5" className={classes.header}>End</Typography>
														</Grid>
														<Grid item xs={7}>
															<Typography variant="subtitle1">{agreement.end_date ? moment(agreement.end_date).format(format_display_fancy_notime) : '-' }</Typography>
														</Grid>
														<Grid item xs={5}>
															<Typography variant="h5" className={classes.header}>Rent</Typography>
														</Grid>
														<Grid item xs={7}>
															<Typography variant="subtitle1" className={classes.value}>{agreement.agreement_amount ? formatNumber(agreement.agreement_amount) : '-'}</Typography>
														</Grid>
														<Grid item xs={5}>
															<Typography variant="h5" className={classes.header}>Deposit</Typography>
														</Grid>
														<Grid item xs={7}>
															<Typography variant="subtitle1" className={classes.value}>{agreement.agreement_deposit ? formatNumber(agreement.agreement_deposit) : '-'}</Typography>
														</Grid>
														<Grid item xs={6}>
															<Typography variant="h5" className={classes.header}>Agreement</Typography>
														</Grid>
														<Grid item xs={6}>
															{
																agreement.link && (
																	<ButtonGroup disableElevation className={classes.value} size="small" color="inherit" aria-label="outlined primary button group">
																		<Button href={agreement.link} color="inherit" target="_blank">View</Button>
																	</ButtonGroup>
																)
															}
														</Grid>
														<Grid item xs={12}>
															<Divider/>
														</Grid>
													</Grid>
												))
											}
										</Hidden>
									</Grid>
								</div>
							</Paper>
						</Grid>)
					}
				</Grid>
			</Grid>
		</Dialog>
	);
}

ViewTenantDialog.prototype = {
    open: PropTypes.bool.isRequired,
    id: PropTypes.string,
    onClose: PropTypes.func.isRequired
};