import React, { useContext, useEffect } from 'react'
import { AppContext } from '../AppContextProvider'

function ViewProperty({match}) {

    const {openViewProperty, closeViewProperty} = useContext(AppContext);
    const id = match.params.id || null;

    useEffect(() => {
        if(id){
            openViewProperty(id);
        }
        return () => {
            closeViewProperty();
        }
    }, [id])

    return (
        <div>
            
        </div>
    )
}

export default ViewProperty
