import React, { useContext, useEffect } from 'react'
import { AppContext } from '../AppContextProvider'

function ViewPA({match}) {

    const {openViewPA, closeViewPA} = useContext(AppContext);
    const id = match.params.id || null;

    useEffect(() => {
        if(id){
            openViewPA(id);
        }
        return () => {
            closeViewPA();
        }
    }, [id])

    return (
        <div>
            
        </div>
    )
}

export default ViewPA;
