import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button, TextField, Card, CardHeader, CardContent, Divider, CardActions, colors, IconButton, Typography, Switch, FormControlLabel } from "@material-ui/core";
import { UserService } from "../Services/UserService";
import clsx from 'clsx';
import { AppContext } from "../AppContextProvider";
import ItemSelection from "../components/ItemSelection";
import {STATES} from '../States';
import PublishIcon from '@material-ui/icons/Publish';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
let counter = 1;
const useStyles = makeStyles(theme => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        float: 'left'
    },
    root: {},
    saveButton: {
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
    actions: {
        float: 'right',
    },
    stateSelection: {
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(1),
        },
    }
}));

export default function InvoiceConfigPage(props) {
    const classes = useStyles();
    const {state, updateUser, setTitle, showConfirmDialog, showSnackBar, closeConfirmDialog, setLinearLoading, closeProgressDialog, showProgressDialog, handleLogout} = useContext(AppContext);
    const hiddenFileInput = React.useRef(null);
    const [invoiceConfig, setInvoiceConfig] = useState({});
    const [profile, setProfile] = useState({...state.user, auto_rental_invoicing: state.user.auto_rental_invoicing === '1' });

    React.useEffect(() => {
        setTitle('Invoice Configuration');
        fetchData();
    }, [true]);
    
    const fetchData = () => {
        counter++;
        showProgressDialog();
        UserService.getInvoiceConfig()
        .then(data => {
            setInvoiceConfig(data);
            closeProgressDialog();
        })
        .catch(error => {
            console.log(error);
            closeProgressDialog();
            showSnackBar('Something went wrong while fetching. Please try again.');
        })
    }
    
    const handleChange = (e) => {
        e.persist();
        setInvoiceConfig(config => ({...config, [e.target.name]: e.target.value}));
    }
    
    const handleChangeProfile = (e) => {
        e.persist();
        setProfile(profile => ({...profile, [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value}));
    }

    const submit = () => {
        showConfirmDialog("Confirm changes?", undefined, () => {
            confirmChange();
        }, "Cancel", "Yes");
    }
    
    const confirmChange = () => {
        setLinearLoading(true);
        showProgressDialog();
        let data = Object.assign({}, invoiceConfig);
        delete data.stateOb;
        UserService.saveInvoiceConfig(data)
        .then((data) => {
            console.log(data);
            if(data.success){
                showSnackBar(data.message, "success");
                fetchData();
            } else {
                showSnackBar(data.message, "error");
            }
            closeConfirmDialog();
            closeProgressDialog();
            setLinearLoading(false);
        }).catch((error) => {
            showSnackBar("Something went wrong. Try again later.", "error");
            closeConfirmDialog();
            closeProgressDialog();
            setLinearLoading(false);
        });
    }

    const submitProfile = () => {
        showConfirmDialog("Confirm changes?", undefined, () => {
            confirmChangeProfile();
            closeConfirmDialog();
        }, "Cancel", "Yes");
    }
    
    const confirmChangeProfile = () => {
        setLinearLoading(true);
        showProgressDialog();
        let data = {};
        data.name = profile.user_name;
        data.email = profile.user_email;
        data.mobile = profile.user_mobile;
        data.org_name = profile.name;
        data.gst = profile.gst;
        data.auto_rental_invoicing = profile.auto_rental_invoicing;
        data.rental_invoicing_days = profile.rental_invoicing_days;
        UserService.saveUserData(data)
        .then((data) => {
            console.log(data);
            if(data.success_org){
                showSnackBar(data.message, "success");
                UserService.authenticateToken()
				.then((data) => {
                    updateUser(data);
                    localStorage.setItem('caretaker.user', JSON.stringify(data));
                    localStorage.setItem('caretaker.session', data['sysbrew-x-token']);
                })
                .catch(error => {
                    console.log(error);
                    handleLogout();
                });
            } else {
                showSnackBar(data.message, "error");
            }
            closeProgressDialog();
            setLinearLoading(false);
        }).catch((error) => {
            showSnackBar("Something went wrong. Try again later.", "error");
            closeProgressDialog();
            setLinearLoading(false);
        });
    }
    
    const uploadLogo = (event) => {
        if(event.target.files.length > 0){
            setLinearLoading(true);
            showProgressDialog('Uploading...');
            const fileUploaded = event.target.files[0];
            const formData = new FormData();
            formData.append('file', fileUploaded || null);
            UserService.uploadLogo(formData)
            .then((data) => {
                console.log(data);
                if(data.success){
                    showSnackBar(data.message, "success");
                    fetchData();
                } else {
                    showSnackBar(data.message, "error");
                }
                closeProgressDialog();
                setLinearLoading(false);
            }).catch((error) => {
                showSnackBar("Something went wrong. Try again later.", "error");
                closeProgressDialog();
                setLinearLoading(false);
            });
        }
    }
    
    const removeLogo = () => {
        if(invoiceConfig.logo){
            showConfirmDialog("Remove Logo?", undefined, () => {
                closeConfirmDialog();
                setLinearLoading(true);
                showProgressDialog('Removing...');
                UserService.removeLogo()
                .then((data) => {
                    fetchData();
                    closeProgressDialog();
                    setLinearLoading(false);
                }).catch((error) => {
                    showSnackBar("Something went wrong. Try again later.", "error");
                    closeProgressDialog();
                    setLinearLoading(false);
                });
            }, "Cancel", "Remove");
        }
    }

    return (
        <div className="Home">
            <div className="lander">
                <div >
                    <Grid container direction="row" spacing={4} justify="center">
                        <Grid item xl={10} lg={10} xs={12}>
                            <Card className={clsx(classes.root)}>
                                <CardHeader 
                                    title="Profile"
                                    titleTypographyProps={{align: 'left'}} />
                                <Divider />
                                <CardContent>
                                    <Grid container justify="flex-start" alignItems="flex-start" spacing={1}>
                                        <Grid item xs={12} lg={3}>
                                            <TextField
                                                fullWidth
                                                id="standard-name-input-1"
                                                label="Name"
                                                className={classes.textField}
                                                type="text"
                                                margin="none"
                                                name="name"
                                                value={profile.name || ''}
                                                variant="outlined"
                                                onChange={handleChangeProfile}
                                                />
                                        </Grid>
                                        <Grid item xs={12} lg={2}>
                                            <TextField
                                                fullWidth
                                                id="standard-name-input-30"
                                                label="GST"
                                                className={classes.textField}
                                                type="text"
                                                margin="none"
                                                name="gst"
                                                defaultValue={profile.gst || ''}
                                                variant="outlined"
                                                onChange={handleChangeProfile}
                                                />
                                        </Grid>
                                        <Grid item xs={12} lg={2}>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={profile.auto_rental_invoicing}
                                                        onChange={handleChangeProfile}
                                                        name="auto_rental_invoicing"
                                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                        />
                                                }
                                                label="Auto generate Rental Invocies"
                                                labelPlacement="bottom"
                                                />
                                        </Grid>
                                        <Grid item xs={12} lg={2}>
                                            <TextField
                                                fullWidth
                                                id="standard-mobile-input-2"
                                                label="Invoicing Days"
                                                name="rental_invoicing_days"
                                                className={classes.textField}
                                                type="number"
                                                margin="none"
                                                variant="outlined"
                                                defaultValue={profile.rental_invoicing_days || 0}
                                                onChange={handleChangeProfile}
                                                helperText={'No of days before the Rental Date, Invoices should be autogenerated'}
                                                inputProps={{ maxLength: 4 }}
                                                />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                <Divider />
                                <CardActions className={classes.actions}>
                                    <Button variant="contained" className={classes.saveButton} color="primary" onClick={submitProfile}>
                                        Save
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                        <Grid item xl={10} lg={10} xs={12}>
                            <Card className={clsx(classes.root)}>
                                <CardHeader 
                                    title="Invoice Details"
                                    titleTypographyProps={{align: 'left'}} />
                                <Divider />
                                <CardContent>
                                    <Grid container justify="flex-start" alignItems="flex-start" spacing={1}>
                                        <Grid item xs={12} lg={4}>
                                            <TextField
                                                fullWidth
                                                id="standard-name-input-1"
                                                label="Address"
                                                multiline={true}
                                                rowsMax={4}
                                                className={classes.textField}
                                                type="text"
                                                margin="none"
                                                name="address"
                                                value={invoiceConfig.address || ''}
                                                variant="outlined"
                                                onChange={handleChange}
                                                />
                                        </Grid>
                                        <Grid item xs={12} lg={2}>
                                            <TextField
                                                fullWidth
                                                id="standard-name-input-2"
                                                label="City"
                                                className={classes.textField}
                                                type="text"
                                                margin="none"
                                                name="city"
                                                value={invoiceConfig.city || ''}
                                                variant="outlined"
                                                onChange={handleChange}
                                                />
                                        </Grid>
                                        <Grid item xs={12} lg={2}>
                                            <ItemSelection 
                                                required 
                                                clearable={true} 
                                                formClass={classes.stateSelection}
                                                label='State' 
                                                value={(invoiceConfig.state && STATES.find(s => s.name === invoiceConfig.state)) || null} 
                                                optionLabel='name' 
                                                size="medium"
                                                options={STATES} 
                                                style={{zIndex: 500}}
                                                selected={(state) => setInvoiceConfig(config => ({...config, stateOb: state, state: state.name}))}/>
                                        </Grid>
                                        <Grid item xs={12} lg={2}>
                                            <TextField
                                                fullWidth
                                                id="standard-name-input-3"
                                                label="Pincode"
                                                className={classes.textField}
                                                type="text"
                                                name="pincode"
                                                value={invoiceConfig.pincode || ''}
                                                variant="outlined"
                                                onChange={handleChange}
                                                />
                                        </Grid>
                                        <Grid item xs={12} lg={2}>
                                            <TextField
                                                fullWidth
                                                id="standard-mobile-input-2"
                                                label="Mobile"
                                                name="mobile"
                                                className={classes.textField}
                                                type="tel"
                                                margin="none"
                                                variant="outlined"
                                                value={invoiceConfig.mobile || ''}
                                                onChange={handleChange}
                                                helperText={'Should be 10 digit number without country code'}
                                                inputProps={{ maxLength: 10 }}
                                                />
                                        </Grid>
                                        <Grid item xs={12} lg={2}>
                                            <TextField
                                                fullWidth
                                                id="standard-name-input-4"
                                                label="Email"
                                                className={classes.textField}
                                                type="email"
                                                margin="none"
                                                name="email"
                                                value={invoiceConfig.email || ''}
                                                variant="outlined"
                                                onChange={handleChange}
                                                />
                                        </Grid>
                                        <Grid item xs={12} lg={1}>
                                            <TextField
                                                fullWidth
                                                id="standard-mobile-input-2"
                                                label="IGST"
                                                name="igst"
                                                className={classes.textField}
                                                type="number"
                                                margin="none"
                                                variant="outlined"
                                                value={invoiceConfig.igst || 0}
                                                onChange={handleChange}
                                                helperText={'IGST percentage'}
                                                inputProps={{ maxLength: 4 }}
                                                />
                                        </Grid>
                                        <Grid item xs={12} lg={1}>
                                            <TextField
                                                fullWidth
                                                id="standard-mobile-input-2"
                                                label="SGST"
                                                name="sgst"
                                                className={classes.textField}
                                                type="number"
                                                margin="none"
                                                variant="outlined"
                                                value={invoiceConfig.sgst || 0}
                                                onChange={handleChange}
                                                helperText={'SGST percentage'}
                                                inputProps={{ maxLength: 4 }}
                                                />
                                        </Grid>
                                        <Grid item xs={12} lg={1}>
                                            <TextField
                                                fullWidth
                                                id="standard-mobile-input-2"
                                                label="CGST"
                                                name="cgst"
                                                className={classes.textField}
                                                type="number"
                                                margin="none"
                                                variant="outlined"
                                                value={invoiceConfig.cgst || 0}
                                                onChange={handleChange}
                                                helperText={'CGST percentage'}
                                                inputProps={{ maxLength: 4 }}
                                                />
                                        </Grid>
                                        <Grid item xs={12} lg={2}>
                                            <TextField
                                                fullWidth
                                                id="5"
                                                label="Invoice Prefix"
                                                className={classes.textField}
                                                type="text"
                                                margin="none"
                                                name="invoice_prefix"
                                                value={invoiceConfig.invoice_prefix || ''}
                                                variant="outlined"
                                                onChange={handleChange}
                                                helperText={'Prefixed to the invoice No'}
                                                />
                                        </Grid>
                                        <Grid item xs={12} lg={2}>
                                            <TextField
                                                fullWidth
                                                id="standard-mobile-input-2"
                                                label="Next Invoice No"
                                                name="invoice_no"
                                                className={classes.textField}
                                                type="number"
                                                margin="none"
                                                variant="outlined"
                                                value={invoiceConfig.invoice_no || 0}
                                                onChange={handleChange}
                                                helperText={'This no will be used next'}
                                                inputProps={{ maxLength: 4 }}
                                                />
                                        </Grid>
                                        <Grid item xs={12} lg={3}></Grid>
                                        <Grid item xs={12} lg={4}>
                                            <TextField
                                                fullWidth
                                                id="standard-name-input-6"
                                                label="Terms and Conditions"
                                                multiline={true}
                                                rowsMax={4}
                                                rows={2}
                                                className={classes.textField}
                                                type="text"
                                                margin="none"
                                                name="terms"
                                                value={invoiceConfig.terms || ''}
                                                variant="outlined"
                                                helperText={'Max 4 Lines'}
                                                onChange={handleChange}
                                                inputProps={{ maxLength: 500 }}
                                                />
                                        </Grid>
                                        <Grid item xs={12} lg={4}>
                                            <TextField
                                                fullWidth
                                                id="standard-name-input-7"
                                                label="Payment Details"
                                                multiline={true}
                                                rowsMax={4}
                                                rows={2}
                                                className={classes.textField}
                                                type="text"
                                                margin="none"
                                                name="payment_details"
                                                value={invoiceConfig.payment_details || ''}
                                                variant="outlined"
                                                helperText={'Max 4 Lines'}
                                                onChange={handleChange}
                                                inputProps={{ maxLength: 500 }}
                                                />
                                        </Grid>
                                        <Grid item xs={12} lg={4}>
                                            <div style={{border: '1px solid rgb(0,0,0, 0.1)', marginLeft: 8}}>
                                                <Grid container justify="flex-start" alignItems="center" alignContent="center">
                                                    <Grid item xs={12} lg={4}>
                                                        <Typography variant="h6">
                                                            Logo:
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        {
                                                            invoiceConfig.logo && (
                                                                <img style={{margin: 8, marginTop: 16}} src={`${invoiceConfig.link}?${counter}`} height={40} alt="Invoice Logo"/>
                                                            )
                                                        }
                                                    </Grid>
                                                    <Grid item>
                                                        <IconButton style={{marginLeft: 8}} onClick={() => hiddenFileInput.current.click()}>
                                                            <PublishIcon />
                                                        </IconButton>
                                                        <input accept="image/*" type="file" onChange={uploadLogo} ref={hiddenFileInput} onClick={(event) =>  event.target.value = null} style={{display:'none'}} />
                                                    </Grid>
                                                    <Grid item>
                                                        {
                                                            invoiceConfig.logo && (
                                                                <IconButton style={{marginLeft: 8}} onClick={removeLogo}>
                                                                    <DeleteForeverIcon color="error" />
                                                                </IconButton>
                                                            )
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </div>
                                            {/* <Button 
                                                variant="outlined" 
                                                style={{height: 60}}
                                                onClick={() => hiddenFileInput.current.click()} 
                                                startIcon={
                                                    <img src={invoiceConfig.link || null} height={50}/>
                                                }>
                                                    Upload New Logo
                                                </Button>
                                            <input accept="image/*" type="file" onChange={uploadLogo} ref={hiddenFileInput} onClick={(event) =>  event.target.value = null} style={{display:'none'}} />
                                            <Button 
                                                variant="contained">
                                                    Remove Logo
                                                </Button> */}
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                <Divider />
                                <CardActions className={classes.actions}>
                                    <Button variant="contained" className={classes.saveButton} color="primary" onClick={submit}>
                                        Save
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    );
}
 