import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button, TextField, Card, CardHeader, CardContent, Divider, CardActions, colors } from "@material-ui/core";
import { UserService } from "../Services/UserService";
import clsx from 'clsx';
import { AppContext } from "../AppContextProvider";
const useStyles = makeStyles(theme => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        float: 'left'
    },
    root: {},
    saveButton: {
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
    actions: {
        float: 'right',
    },
    stateSelection: {
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(1),
        },
    }
}));

export default function Profile(props) {
    const classes = useStyles();
    const {setTitle, showConfirmDialog, showSnackBar, closeConfirmDialog, setLinearLoading, handleLogout} = useContext(AppContext);
    
    const [password, setPassword] = React.useState(null);
    const [newPassword, setNewPassword] = React.useState(null);
    const [reenterNewPassword, setReenterNewPassword] = React.useState(null);
    const [messageNew, setMessageNew] = React.useState('');
    const [messageNew2, setMessageNew2] = React.useState('');
    const [errorPass, setErrorPass] = React.useState(false);
    const [errorNewPass, setErrorNewPass] = React.useState(false);
    const [errorNewPass2, setErrorNewPass2] = React.useState(false);

    React.useEffect(() => {
        setTitle('Profile');
    }, [true]);

    const handleChange = (type, value) => {
        setErrorPass(false);
        setErrorNewPass(false);
        setErrorNewPass2(false);
        
        if(value.length === 0){
            switch(type){
                case 'password': setErrorPass(true); break;
                case 'newPassword': setErrorNewPass(true);break;
                case 'reenterNewPassword': setErrorNewPass2(true); break;
                default:;
            }
        } else {
            switch(type){
                case 'password': setErrorPass(false); setPassword(value); break;
                case 'newPassword': setErrorNewPass(false); setNewPassword(value); break;
                case 'reenterNewPassword': setErrorNewPass2(false); setReenterNewPassword(value); break;
                default:;
            }
                   
        }

        if(type === 'reenterNewPassword' && value !== newPassword){
            setErrorNewPass2(true);
            setMessageNew2('Re-enter and new passwords are not same');
            return;
        } else {
            setErrorNewPass2(false);
            setMessageNew2('');
        }

        if(type === 'newPassword' && password.length > 0 && value === password){
            setErrorNewPass(true);
            setMessageNew('New password cannot be same as old');
            return;
        } else {
            setErrorNewPass(false);
            setMessageNew('');
        }
    }

    const submit = () => {
        if(password.length === 0 || newPassword.length === 0 || reenterNewPassword.length === 0 || reenterNewPassword !== newPassword || password === newPassword){
            setErrorPass(true);
            setErrorNewPass(true);
            setErrorNewPass2(true);
        } else {
            showConfirmDialog("Confirm change of password? ", undefined, () => {
                confirmChange();
            }, "Cancel", "Yes");
        }
    }

    const confirmChange = () => {
        let body = {
            "password": password,
            "new_password": newPassword
        };
        setLinearLoading(true);
        UserService.changePassword(body)
        .then((data) => {
            console.log(data);
            if(data.success){
                showSnackBar(data.message, "success");
                handleLogout();
            } else {
                showSnackBar(data.message, "error");
            }
            closeConfirmDialog();
            setLinearLoading(false);
        }).catch((error) => {
            showSnackBar("Something went wrong. Try again later.", "error");
            closeConfirmDialog();
            setLinearLoading(false);
        });
    }
    

    return (
        <div className="Home">
            <div className="lander">
                <div >
                    <Grid container direction="row" spacing={4} justify="center">
                        <Grid item xl={8} lg={8} xs={12}>
                            <Card className={clsx(classes.root)}>
                                <CardHeader 
                                    title="Change Password"
                                    titleTypographyProps={{align: 'left'}} />
                                <Divider />
                                <CardContent>
                                    <Grid container justify="flex-start" spacing={1}>
                                        <Grid item xs={12} lg={4}>
                                            <TextField
                                                fullWidth
                                                error={errorPass}
                                                id="standard-password-input-1"
                                                label="Old Password"
                                                className={classes.textField}
                                                type="password"
                                                autoComplete="current-password"
                                                margin="none"
                                                variant="outlined"
                                                onChange={(e) => handleChange('password', e.target.value)}
                                                />
                                        </Grid>
                                        <Grid item xs={12} lg={4}>
                                            <TextField
                                                fullWidth
                                                error={errorNewPass}
                                                id="standard-password-input-2"
                                                label="New Password"
                                                className={classes.textField}
                                                type="password"
                                                margin="none"
                                                variant="outlined"
                                                onChange={(e) => handleChange('newPassword', e.target.value)}
                                                helperText={messageNew}
                                                />
                                        </Grid>
                                        <Grid item xs={12} lg={4}>
                                            <TextField
                                                fullWidth
                                                error={errorNewPass2}
                                                id="standard-password-input-3"
                                                label="Re-enter New Password"
                                                className={classes.textField}
                                                type="password"
                                                margin="none"
                                                variant="outlined"
                                                onChange={(e) => handleChange('reenterNewPassword', e.target.value)}
                                                helperText={messageNew2}
                                                />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                <Divider />
                                <CardActions className={classes.actions}>
                                    <Button variant="contained" className={classes.saveButton} color="primary" onClick={submit}>
                                        Change Password
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    );
}
 