import Config from '../Config';
import {RestService} from './RestService';

export const TenantService = {
    getTenantCount: async function(property = null, type = null, current = false, filter = ''){
        return RestService.get(`${Config.list_tenant_url}?count=true&filter=${filter}${property ? `&property=${property}` : ''}${type ? `&type=${type}` : ''}${current ? `&current=${current}` : ''}`, {})
        .then(response => response.json());
    },
    getTenantList: async function(property = null, type = null, current = false, filter = '', sort = null, direction = null, start = 0, offset = 20){
        return RestService.get(`${Config.list_tenant_url}?filter=${filter}${property ? `&property=${property}` : ''}${type ? `&type=${type}` : ''}${current ? `&current=${current}` : ''}&from=${start}&offset=${offset}${sort ? `&sort=${sort}` : ''}${direction ? `&direction=${direction}` : ''}`, {})
        .then(response => response.json());
    },
    getTenantHistory: async function(id = null){
        return RestService.get(Config.list_property_tenant_history_url.replace('{id}', id), {})
        .then(response => response.json());
    },
    getCurrentTenant: async function(id = null){
        return RestService.get(Config.get_property_current_tenant_url.replace('{id}', id), {})
        .then(response => response.json());
    },
    getTenantAgreement: async function(id = null){
        return RestService.get(Config.tenant_agreement_url.replace('{id}', id), {})
        .then(response => response.json());
    },
    getTenantById: async function(id = null){
        return RestService.get(Config.tenant_id_url.replace('{id}', id), {})
        .then(response => response.json());
    },
    saveTenant: async function(body, headers = {}) {
        return RestService.post(Config.tenant_url, headers, body)
        .then(response => response.json());
    },
    removeTenant: async function(id) {
        return RestService.delete(Config.tenant_id_url.replace("{id}", id), {});
    },
    getTenantNames: async function(){
        return RestService.get(Config.list_tenant_names_url, {})
        .then(response => response.json());
    },
};