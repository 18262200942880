import Config from '../Config';
import {RestService} from './RestService';
import moment from 'moment';
import {format} from '../Utils';

export const PropertyService = {
    //Property
    getPropertyCount: async function(vacancy_status = null, type = null, filter = ''){
        return RestService.get(`${Config.list_properties_url}?count=true&filter=${filter}${vacancy_status ? `&vacancy_status=${vacancy_status}` : ''}${type ? `&type=${type}` : ''}`, {})
        .then(response => response.json());
    },
    getPropertyList: async function(vacancy_status = null, type = null, filter = '', sort = null, direction = null, start = 0, offset = 20){
        return RestService.get(`${Config.list_properties_url}?filter=${filter}${vacancy_status ? `&vacancy_status=${vacancy_status}` : ''}${type ? `&type=${type}` : ''}&from=${start}&offset=${offset}${sort ? `&sort=${sort}` : ''}${direction ? `&direction=${direction}` : ''}`, {})
        .then(response => response.json());
    },
    getPropertyNames: async function(){
        return RestService.get(Config.list_property_names_url, {})
        .then(response => response.json());
    },
    getPropertyOccupiedNames: async function(){
        return RestService.get(`${Config.list_property_names_url}?vacancy_status=OCCUPIED`, {})
        .then(response => response.json());
    },
    searchProperties: async function(filter = '', start = 0, offset = 20){
        return RestService.get(`${Config.search_url}?filter=${filter}&from=${start}&offset=${offset}`, {})
        .then(response => response.json());
    },
    getPropertyById: async function(id = "") {
        return RestService.get(Config.get_property_detail_url.replace("{id}", id), {})
        .then(response => response.json());
    },
    saveProperty: async function(body, headers = {}) {
        return RestService.post(Config.save_property_url, headers, body)
        .then(response => response.json());
    },
    removeProperty: async function(id) {
        return RestService.delete(Config.get_property_detail_url.replace("{id}", id), {});
    },
    getPropertyAssetList: async function(id = null, vacancy_status = null, type = null, filter = '', sort = null, direction = null, start = 0, offset = 20){
        return RestService.get(Config.list_property_asset_url.replace('{propertyId}', id), {})
        .then(response => response.json());
    },
    addPropertyDocument: async function(id, type, body, headers = {}) {
        return RestService.postFormData(Config.add_property_document_url.replace('{id}', id).replace('{type}', type), headers, body)
        .then(response => response.json());
    },
    removePropertyDocument: async function(id, headers = {}) {
        return RestService.delete(Config.remove_property_document_url.replace('{id}', id), headers);
    },
    savePropertyAsset: async function(headers, body) {
        return RestService.post(Config.add_property_asset_url, headers, body)
        .then(response => response.json());
    },
    removePropertyAsset: async function(id) {
        return RestService.delete(Config.get_property_asset_url.replace("{id}", id), {});
    },
    savePropertyAlertConfig: async function(headers, body) {
        return RestService.post(Config.save_property_alert_config_url, headers, body)
        .then(response => response.json());
    },
    changePropertyStatus: async function(id, status, headers = {}) {
        return RestService.post(Config.change_property_status_url.replace('{id}', id).replace('{status}', status), headers, {})
        .then(response => response.json());
    },
    //PTA
    getPTACount: async function(property = null, tenant = null, current = null, type = null, filter = ''){
        return RestService.get(`${Config.agreement_list_url}?count=true&filter=${filter}${property ? `&property=${property}` : ''}${tenant ? `&tenant=${tenant}` : ''}${current ? `&current=${current}` : ''}${type ? `&type=${type}` : ''}`, {})
        .then(response => response.json());
    },
    getPTAList: async function(property = null, tenant = null, current = null, type = null, filter = '', sort = null, direction = null, start = 0, offset = 20){
        return RestService.get(`${Config.agreement_list_url}?filter=${filter}${property ? `&property=${property}` : ''}${tenant ? `&tenant=${tenant}` : ''}${current ? `&current=${current}` : ''}${type ? `&type=${type}` : ''}&from=${start}&offset=${offset}${sort ? `&sort=${sort}` : ''}${direction ? `&direction=${direction}` : ''}`, {})
        .then(response => response.json());
    },
    getPTA: async function(propertyId = null, tenantId = null){
        return RestService.get(Config.get_property_tenant_agreement_url.replace("propertyId", propertyId).replace("tenantId", tenantId))
    },
    savePTA: async function(body, headers = {}) {
        return RestService.post(Config.agreement_url, headers, body)
        .then(response => response.json());
    },
    getPTAById: async function(id) {
        return RestService.get(Config.agreement_id_url.replace("{id}", id), {})
        .then(response => response.json());
    },
    removePTA: async function(id) {
        return RestService.delete(Config.agreement_id_url.replace("{id}", id), {});
    },
    addPTADocument: async function(id, type, body, headers = {}) {
        return RestService.postFormData(Config.agreement_document_url.replace('{id}', id).replace('{type}', type), headers, body)
        .then(response => response.json());
    },
    removePTADocument: async function(id) {
        return RestService.delete(Config.agreement_document_id_url.replace("{id}", id), {});
    },
    //REPORT
    getAgreementEndingInXDaysCount: async function(days = 0){
        return RestService.get(Config.report_agreement_ending_count_url.replace('{days}', days), {})
        .then(response => response.json());
    },
    getPendingPaymentsCount: async function(){
        return RestService.get(`${Config.report_pending_payment_count_url}?count=true`, {})
        .then(response => response.json());
    },
    //Invoices
    getInvoiceCount: async function(status = null, property = null, tenant = null, startdate = null, enddate = null){
        return RestService.get(`${Config.list_invoices_url}?count=true${status ? `&status=${status}` : ''}${property ? `&property=${property}` : ''}${tenant ? `&tenant=${tenant}` : ''}${ startdate ? `&fromdate=${moment(startdate).startOf('day').format(format)}` : '' }&todate=${moment(enddate).startOf('day').format(format)}`, {})
        .then(response => response.json());
    },
    getInvoiceList: async function(status = null, property = null, tenant = null, startdate = null, enddate = null, sort = null, direction = null, start = 0, offset = 20){
        return RestService.get(`${Config.list_invoices_url}?${status ? `&status=${status}` : ''}${property ? `&property=${property}` : ''}${tenant ? `&tenant=${tenant}` : ''}&from=${start}&offset=${offset}${sort ? `&sort=${sort}` : ''}${direction ? `&direction=${direction}` : ''}${ startdate ? `&fromdate=${moment(startdate).startOf('day').format(format)}` : '' }&todate=${moment(enddate).startOf('day').format(format)}`, {})
        .then(response => response.json());
    },
    changeInvoiceStatus: async function(id, status, headers = {}) {
        return RestService.post(Config.change_invoice_status_url.replace('{id}', id).replace('{status}', status), headers, {})
        .then(response => response.json());
    },
    regenerateInvoice: async function(id) {
        return RestService.post(Config.invoice_id_url.replace("{id}", id), {});
    },
    generateInvoice: async function(id, date) {
        return RestService.post(Config.generate_invoice_url.replace("{paId}", id).replace("{date}", date), {})
        .then(response => response.json());
    },
};