import React from 'react';
import MyDialog from "./MyDialog";
import PropTypes from 'prop-types';
import { Button, Grid, ListItemText } from '@material-ui/core';
import { PropertyService } from '../Services/PropertyService';
import { useContext } from 'react';
import { AppContext } from '../AppContextProvider';
import ItemSelection from './ItemSelection';
import MonthYearPicker from './MonthYearPicker';
import moment from 'moment';
import { format_display_fancy_notime, format_without_time } from '../Utils';

const GenerateInvoice = ({open, fullWidth, maxWidth, refresh, handleClose}) => {

    const {showConfirmDialog, closeConfirmDialog, showProgressDialog, closeProgressDialog, showSnackBar, showConfirmDialog1D, closeConfirmDialog1D} = useContext(AppContext);
    const [properties, setProperties] = React.useState([]);
    const [selectedProperty, setSelectedProperty] = React.useState(null);
    const [date, setDate] = React.useState(moment().startOf('month'));
    const subtitle = '';
    const [fetch, setFetch] = React.useState(false);
    const [pa, setPA] = React.useState(null);

    React.useEffect(() => {
        if(properties.length === 0){
            PropertyService.getPropertyOccupiedNames()
            .then(data => {
                console.log(data);
                setProperties(data);
            })
        }
        return () => {
        }
    }, [true]);

    React.useEffect(() => {
        if(properties.length === 0){
            PropertyService.getPropertyOccupiedNames()
            .then(data => {
                console.log(data);
                setProperties(data);
            })
        }
        return () => {
        }
    }, [open]);
    
    React.useEffect(() => {
        if(fetch && selectedProperty){
            showProgressDialog('Fetching Agreement...');
            PropertyService.getPTAList(selectedProperty.id, null, true, null, '', 'pa.end_date', 'desc', 0, 10)
            .then(data => {
                console.log(data);
                if(data.length === 1){
                    setPA(data[0]);
                } else if(data.length > 1){
                    setPA(null);
                    showConfirmDialog1D('There are more than 1 active Agreements for the property. Cannot generate Invoice.', undefined, () => {
                        closeConfirmDialog1D();
                    }, 'OK')
                } else {
                    showConfirmDialog1D('There are no agrements created against the Property', undefined, () => {
                        closeConfirmDialog1D();
                    }, 'OK')
                }
                closeProgressDialog();
            })
            .catch(error => {
                showConfirmDialog1D('Something went wrong. Please try again', undefined, () => {
                    closeConfirmDialog1D();
                }, 'OK');
                console.log(error);
                closeProgressDialog();
            })
            setFetch(false);
        } else {
            setPA(null);
        }
    }, [fetch, selectedProperty])

    const generateInvoice = () => {
        showConfirmDialog("Confirm Generate?", undefined, () => {
            showProgressDialog();
            PropertyService.generateInvoice(pa.id, date.startOf('month').format(format_without_time))
            .then(data => {
                if(data.success){
                    handleClose();
                    refresh();
                } else if(data.exists){
                    showConfirmDialog1D('Invoice has already been Generated for the Property / Date selected.', undefined, () => {
                        closeConfirmDialog1D();
                    }, 'OK');
                } else {
                    showSnackBar('Something went wrong. Please try again later.', 'error');
                }
                closeProgressDialog();
            })
            .catch(error => {
                showSnackBar('Something went wrong. Please try again later.', 'error');
                handleClose();
                closeProgressDialog();
            })
            closeConfirmDialog();
        });
    }

    return (
        <MyDialog
            maxWidth={maxWidth || false}
            fullWidth={fullWidth || false}
            content={
                <div style={{paddingLeft: 16, paddingRight: 16}}>
                    <Grid container spacin={2} alignItems="center">
                        <Grid item xs={12} lg={5}>
                            <ItemSelection
                                clearable={true} 
                                label='Select Property' 
                                value={selectedProperty} 
                                optionLabel='name' 
                                options={properties} 
                                style={{marginBottom: 16, marginRight: 16 }}
                                selected={(property) => {
                                    setSelectedProperty(property);
                                    setFetch(true);
                                }}/>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <MonthYearPicker 
                                label="Rental Month"
                                margin="dense"
                                date={date}
                                minDate={pa ? moment(pa.start_date) : moment().startOf('month')}
                                maxDate={pa ? moment(pa.end_date) : moment().startOf('month')}
                                setDate={(date) => {
                                    setDate(date);
                                }}/>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Button disabled={!pa} color="primary" variant="contained" style={{marginBottom: 16, marginLeft: 8}} onClick={generateInvoice}>Generate</Button>
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            {
                                pa && (
                                    <Grid container spacin={2} alignItems="center">
                                        <Grid item>
                                            <ListItemText
                                                primary={`Property: ${pa.property_name || ''}`}
                                                secondary={`Agreement End Date: ${pa.end_date ? moment(pa.end_date).format(format_display_fancy_notime) : '-'}`}
                                                secondaryTypographyProps={{style: {color: 'red'}}}
                                            />
                                        </Grid>
                                    </Grid>
                                )
                            }
                        </Grid>
                    </Grid>
                    
                </div>
            }
            title={'Generate Invoice'}
            subtitle={subtitle || ''}
            open={open}
            close={() => {
                handleClose();
            }}/>
    )
}

GenerateInvoice.propTypes = {
    handleClose: PropTypes.func.isRequired,
    refresh: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};

export default GenerateInvoice;