import React, { useContext, useEffect } from 'react'
import { AppContext } from '../AppContextProvider'

function ViewTenant({match}) {

    const {openViewTenant, closeViewTenant} = useContext(AppContext);
    const id = match.params.id || null;

    useEffect(() => {
        if(id){
            openViewTenant(id);
        }
        return () => {
            closeViewTenant();
        }
    }, [id])

    return (
        <div>
            
        </div>
    )
}

export default ViewTenant;
