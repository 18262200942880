import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Card, Typography, Avatar, colors, useTheme } from '@material-ui/core';

import Label from './Label';
import gradients from '../utils/gradients';
import { red, yellow, green } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 100
  },
  details: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  label: {
    marginLeft: theme.spacing(1)
  },
  avatar: {
    backgroundImage: gradients.green,
    height: 48,
    width: 48
  },
  subtitle: {
    width: '100%'
  },
  square: {
    borderRadius: 0,
  },
  red: {
    color: red[700]
  },
  yellow: {
      color: yellow[800]
  },
  green: {
      color: green[600]
  },
  black: {
      color: theme.palette.black
  }
}));

const CardViewBlock = props => {
  const { className, shape, ...rest } = props;
  const classes = useStyles();
  const theme = useTheme();

  const { color } = props;

    let colorStyle = classes.black;
    switch (color) {
        case 'red':
            colorStyle = classes.red;
            break;
        case 'yellow':
            colorStyle = classes.yellow;
            break;
        case 'green':
            colorStyle = classes.green;
            break;
        default:
            colorStyle = classes.black;
            break;
    }; 

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className, shape === 'round' ? null: classes.square)}
    >
      <div style={{width: '100%'}}>
        <Typography
          component="h3"
          gutterBottom
          variant="overline"
        >
          {props.title}
        </Typography>
        <div className={classes.details}>
          <Typography 
            variant="h3" 
            align={props.difference !== undefined ? "left" : "center"} 
            className={props.difference !== undefined ? colorStyle : clsx(classes.subtitle, colorStyle)}>
            {props.subtitle}
          </Typography>
          {props.difference !== undefined  && (
              <Label
                className={classes.label}
                color={props.difference > 0 ? colors.green[600] : props.difference < 0 ? colors.red[600] : theme.palette.black}
                variant="contained"
              >
                {`${props.difference.toFixed(1)} %`}
              </Label>
          )}
        </div>
      </div>
      { false &&
        props.currency && (
            <Avatar className={classes.avatar}>
              {props.currency}
            </Avatar>
        )
      }
    </Card>
  );
};

CardViewBlock.propTypes = {
  className: PropTypes.string,
  difference: PropTypes.number,
  currency: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.any.isRequired,
  shape: PropTypes.oneOf(['rounded', 'square']),
  color: PropTypes.oneOf(['red', 'black', 'yellow', 'green'])
};

CardViewBlock.defaultProps = {
  shape: 'square',
  color: 'black'
};

export default CardViewBlock;
