import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import PropTypes from 'prop-types';
import { Grid, Paper, CardHeader, FormControl, TextField, Icon } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { AppContext } from '../AppContextProvider';
import ItemSelection from '../components/ItemSelection';
import { TenantService } from '../Services/TenantService';

let counter = 0;
const useStyles = makeStyles((theme) => ({
	appBar: {
		position: 'relative',
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1,
		color: theme.palette.white
	},
	page: {
		height: '100%',
		backgroundColor: theme.palette.divider,
		overflow: 'auto'
	},
	section: {
		margin: 16,
		minHeight: 200,
	},
	avatar: {
		backgroundColor: red[500],
	},
	image: {
		margin: 12,
		position: 'relative',
		height: 200,
		width: '90% !important',
		[theme.breakpoints.down('xs')]: {
			width: '100% !important', // Overrides inline-style
			height: 100,
		},
		'&:hover,  &Mui-focusVisible': {
			zIndex: 1,
		'& $imageBackdrop': {
			opacity: 0.7,
		},
		'& $imageMarked': {
			opacity: 0,
		},
		'& $imageTitle': {
			border: '4px solid currentColor',
		},
		},
	},
	imageBackdrop: {
		borderRadius: 8,
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		backgroundColor: theme.palette.common.black,
		opacity: 0.3,
		transition: theme.transitions.create('opacity'),
	},
	imageTitle: {
		position: 'relative',
		padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`,
	},
	imageMarked: {
		height: 3,
		width: 18,
		backgroundColor: theme.palette.common.white,
		position: 'absolute',
		bottom: -2,
		left: 'calc(50% - 9px)',
		transition: theme.transitions.create('opacity'),
	},
	imageButton: {
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: theme.palette.common.white,
	},
	imageButtonBottom: {
		position: 'absolute',
		left: 0,
		right: 0,
		// top: 0,
        // bottom: 0,
        bottom: 10,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: theme.palette.common.white,
	},
	formAutoClass: {
		zIndex: 3,
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
	card: {
		margin: 8
	}
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModifyTenant(props) {
	const {openViewTenant, showSnackBar, showProgressDialog, closeProgressDialog, showConfirmDialog, closeConfirmDialog, showConfirmDialog1D, closeConfirmDialog1D} = useContext(AppContext);
	const {id} = props;
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);
	const [tenant, setTenant] = React.useState({});
	// const [loading, setLoading] = React.useState(true);
	const [errorList, setErrorList] = React.useState({});
	const [itemRows, setItemRows] = React.useState([]);
	const [errorItemRow, setErrorItemRow] = React.useState([]);
	const [types, setTypes] = React.useState([]);

	React.useEffect(() => {
		setOpen(props.open);
		if(props.open){
			setTenant({});
			fetchMaster();
			// setLoading(true);
		}
		return () => {
			setTenant({});
			setOpen(false);
		}
	},[props.open]);

	const fetchMaster = () => {
		showProgressDialog();
		let types = [{name: 'Individual', value: 'INDIVIDUAL'}, {name: 'Company', value: 'COMPANY'}];
		setTypes(types);
		if(id){
			TenantService.getTenantById(id)
			.then(data => {
				if(data){
					setTenant({...data, typeOb: types.find(t => t.value === data.type)});
					let errRows = [], itemRows = [];
					data.contacts.forEach((contact, index) => {
						let x = {
							id: contact.id,
							rowid: counter++,							
							name: contact.name,
							mobile: contact.mobile,
							email: contact.email,
							department: contact.department,
							address: contact.address,
						};
						itemRows.push(x);
						errRows.push({...x,
							name: false, mobile: false, department: false, address: false
						})
					});
					setErrorItemRow(errRows);
					setItemRows(itemRows);
				}
				closeProgressDialog();
				// setLoading(false);
			})
			.catch(error => {
				console.log(error);
				showConfirmDialog1D('Something went wrong. Please try again.', undefined, () => {
					handleClose(false);
					closeConfirmDialog1D();
				}, 'OK');
				// setLoading(false);
				closeProgressDialog();
			});
		} else {
			closeProgressDialog();
		}
	}

	const handleClose = (showConfirm = false) => {
		if(showConfirm){
			showConfirmDialog('Are you sure you want to close. Any changes made will be lost?', undefined, () => {
				closeConfirmDialog();
				close();
			});
		} else {
			close();
		}
		
	};

	const close = () => {
		setTenant({});
		setErrorList({});
		setItemRows([]);
		setErrorItemRow([]);
		props.onClose();
		setOpen(false);
	}

	const handleChange = field => e => {
		let value = e.target.value;
		if(field === 'loan_amount'){
			setTenant(loan => ({...loan, [field]: value, rate: ((value / loan.total_weight) || 0)}));
		} else {
			setTenant(loan => ({...loan, [field]: value}));
		}
		setErrorList(e => ({...e, [field] : !value}));
	}

	const addRow = () => {
		let row = {
            rowid : counter++,
            name: null,
            mobile: null,
			email: null,
			department: null,
			address: null,
        }
        setItemRows([...itemRows, row]);
        setErrorItemRow([...errorItemRow, {...row, name: true, mobile: true, department: true, address: true}]);
	}

	const handleRowChange = rowid => e => {
		let name = e.target.name;
		let value = e.target.value;
		setItemRows(itemRows.map(row => 
			row.rowid === rowid
			?
				{...row, [name]: value}
			:	
				row
			)
		);
		if(name !== 'email'){
			setErrorItemRow(rows => rows.map(row => 
				row.rowid === rowid
				?
				{...row, [name]: !value}
				:row
			));
		}
	}
	
	const handleRowRemove = rowid => e => {
		setItemRows(itemRows.filter(r => r.rowid !== rowid));
	}

	const saveTenant = () => {
		if(isValid()){
			showConfirmDialog('Confirm Save Tenant', undefined, () => {
				closeConfirmDialog();
				// setLoading(true);
				save();
			})
		}
	}

	const isValid = () => {
		let valid = true;
		if(!tenant.name){
			valid = false;
		}
		setErrorList(e => ({...e, name: !tenant.name}))
		if(!tenant.mobile){
			valid = false;
		}
		setErrorList(e => ({...e, mobile: !tenant.mobile}));
		// if(!tenant.email){
		// 	valid = false;
		// }
		// setErrorList(e => ({...e, email: !tenant.email}));
		if(!tenant.typeOb){
			valid = false;
		}
		setErrorList(e => ({...e, typeOb: !tenant.typeOb}));
		if(tenant.typeOb.value !== 'INDIVIDUAL'){
			if(!tenant.gst){
				valid = false;
			}
			setErrorList(e => ({...e, gst: !tenant.gst}));
			
		}
		if(!tenant.address1){
			valid = false;
		}
		setErrorList(e => ({...e, address1: !tenant.address1}))
		// if(!tenant.address2){
		// 	valid = false;
		// }
		// setErrorList(e => ({...e, address2: !tenant.address2}))
		if(!tenant.city){
			valid = false;
		}
		setErrorList(e => ({...e, city: !tenant.city}))
		if(!tenant.state){
			valid = false;
		}
		setErrorList(e => ({...e, state: !tenant.state}))
		if(!tenant.pincode){
			valid = false;
		}
		setErrorList(e => ({...e, pincode: !tenant.pincode}))
		
		if(tenant.typeOb.value !== 'INDIVIDUAL'){
			if(itemRows.length === 0){
				showConfirmDialog('Please add atleast 1 Contact');
				valid = false;
			} else {
				let i = itemRows.filter(i => !i.name || !i.mobile || !i.department);
				if(i.length > 0){
					valid = false;
					i.forEach(i => {
						setErrorItemRow(rows => rows.map(row => 
							row.rowid === i.rowid
							?
							{...row, name: !i.name, mobile: !i.mobile, department: !i.department}
							:row
						));
					})
				}
			}
		}

		return valid;
	}

	const save = () => {
		const formData = {};
		formData.id = tenant.id || null;
		formData.name = tenant.name || null;
		formData.type = (tenant.typeOb && tenant.typeOb.value) || 'INDIVIDUAL';
		formData.designation = tenant.designation || null;
		formData.gst = tenant.gst || null;
		formData.address1 = tenant.address1 || null;
		formData.address2 = tenant.address2 || null;
		formData.city = tenant.city || null;
		formData.state = tenant.state || null;
		formData.pincode = tenant.pincode || null;
		formData.mobile = tenant.mobile || null;
		formData.email = tenant.email || null;
		formData.comments = tenant.comments || null;
		
		let items = itemRows.map(i => ({
			id: i.id || null,
			name: i.name,
			mobile: i.mobile,
			email: i.email,
			department: i.department,
			address: i.address,
		}));
		formData.contacts = items;

		console.log(formData);

		TenantService.saveTenant(formData)
        .then(data => {
			handleClose(false);
			if(tenant.id){
				openViewTenant(tenant.id);
				if(data.success){
					showSnackBar('Tenant Saved', 'info');
				} else {
					showSnackBar('Failed to Save Tenant. Please try again.', 'error');
				}
			}
			closeProgressDialog();
			// setLoading(false);
        })
        .catch(error => {
            console.log(error);
            showSnackBar('Failed to Save Tenant. Please try again.', 'error');
			closeProgressDialog();
			// setLoading(false);
        })
	}

  	return (
		<Dialog fullScreen disableBackdropClick disableEscapeKeyDown open={open} onClose={() => handleClose(true)} TransitionComponent={Transition}>
			<AppBar className={classes.appBar}>
				<Toolbar>
				<IconButton edge="start" color="inherit" onClick={() => handleClose(true)} aria-label="close">
					<CloseIcon />
				</IconButton>
				<Typography variant="h6" className={classes.title}>
					{`${id ? '' : 'New '}Tenant`}
				</Typography>
				{/* <Button autoFocus color="inherit" onClick={handleClose}>
					save
				</Button> */}
				</Toolbar>
			</AppBar>
			{/* <Backdrop className={classes.backdrop} open={loading} onClick={() => {}}>
				<div style={{padding: 50, backgroundColor: 'white', borderRadius: 8}}>
					<CircularProgress 
							size={40}
							thickness={4}/>
				</div>
			</Backdrop> */}
			<Grid container className={classes.page} justify="center">
				<Grid container item spacing={2} xs={12} lg={10} alignItems="flex-start" justify="center" direction="row">
					<Grid item xs={12} lg={12}>
						<Paper variant="outlined" className={classes.section} elevation={3}>
							<CardHeader
								title="Information"
								titleTypographyProps={{variant: 'h4'}}
							/>
							<Divider/>
							<div style={{minHeight: 100, marginRight: 16, marginLeft: 16, marginTop: 8, marginBottom: 8}}>
								<Grid container justify="center" spacing={2} alignItems="flex-start">
									<Grid item xs={12} lg={12} container justify="center" spacing={2} alignItems="center">
										<Grid item xs={12} lg={4}>
											<FormControl variant="outlined" fullWidth>
												<TextField
													label="Name"
													id="outlined-adornment-customer-name"
													value={tenant.name || ''}
													required
													error={errorList.name}
													margin="none"
													onChange={handleChange('name')}
													aria-describedby="outlined-weight-helper-text"
													variant="outlined"
												/>
											</FormControl>
										</Grid>
										<Grid item xs={12} lg={4}>
											<FormControl variant="outlined" fullWidth>
												<TextField
													label="Mobile"
													margin="none"
													id="outlined-adornment-customer-mobile"
													value={tenant.mobile || ''}
													error={errorList.mobile}
													required
													onChange={handleChange('mobile')}
													aria-describedby="outlined-weight-helper-text"
													variant="outlined"
												/>
											</FormControl>
										</Grid>
										<Grid item xs={12} lg={4}>
											<FormControl variant="outlined" fullWidth>
												<TextField
													label="Email"
													margin="none"
													id="outlined-adornment-customer-email"
													value={tenant.email || ''}
													onChange={handleChange('email')}
													aria-describedby="outlined-weight-helper-text"
													InputProps={{
														'aria-label': 'customer-email'
													}}
													variant="outlined"
												/>
											</FormControl>
										</Grid>
										<Grid item xs={12} lg={4}>
											<ItemSelection
												required 
												size="medium"
												label='Type'
												value={tenant.typeOb || null} 
												optionLabel='name'
												error={errorList.typeOb}
												options={types} 
												style={{zIndex: 500, marginTop: 0, marginLeft: 0}}
												formClass={classes.formAutoClass}
												selected={(value) => {
													setTenant(tenant => ({...tenant, typeOb: value}));
												}}/>

										</Grid>
										<Grid item xs={12} lg={4}>
											<FormControl variant="outlined" fullWidth>
												<TextField
													label="Designation"
													margin="none"
													id="outlined-adornment-customer-occupation"
													value={tenant.designation || ''}
													onChange={handleChange('designation')}
													aria-describedby="outlined-weight-helper-text"
													InputProps={{
														'aria-label': 'customer-occupation'
													}}
													variant="outlined"
												/>
											</FormControl>
										</Grid>
										<Grid item xs={12} lg={4}>
											{
												tenant.typeOb && tenant.typeOb.value === 'COMPANY' && (
													<FormControl variant="outlined" fullWidth>
														<TextField
															label="GST No"
															margin="none"
															id="outlined-adornment-customer-gst"
															value={tenant.gst || ''}
															onChange={handleChange('gst')}
															aria-describedby="outlined-weight-helper-text"
															InputProps={{
																'aria-label': 'customer-nominee'
															}}
															variant="outlined"
														/>
													</FormControl>
												)
											}
										</Grid>
										<Grid item xs={12} lg={6}>
											<FormControl variant="outlined" fullWidth>
												<TextField
													label="Address Line 1"
													margin="none"
													id="outlined-adornment-customer-address1"
													value={tenant.address1 || ''}
													required
													error={errorList.address1}
													onChange={handleChange('address1')}
													aria-describedby="outlined-weight-helper-text"
													InputProps={{
														'aria-label': 'customer-address1',
														maxLength: 100
													}}
													variant="outlined"
												/>
											</FormControl>
										</Grid>
										<Grid item xs={12} lg={6}>
											<FormControl variant="outlined" fullWidth>
												<TextField
													label="Address Line 2"
													margin="none"
													id="outlined-adornment-customer-address2"
													value={tenant.address2 || ''}
													onChange={handleChange('address2')}
													aria-describedby="outlined-weight-helper-text"
													InputProps={{
														'aria-label': 'customer-address2',
														maxLength: 100
													}}
													variant="outlined"
												/>
											</FormControl>
										</Grid>
										<Grid item xs={12} lg={4}>
											<FormControl variant="outlined" fullWidth>
												<TextField
													label="City"
													margin="none"
													id="outlined-adornment-customer-city"
													value={tenant.city || ''}
													required
													error={errorList.city}
													onChange={handleChange('city')}
													aria-describedby="outlined-weight-helper-text"
													InputProps={{
														'aria-label': 'customer-address3',
														maxLength: 100
													}}
													variant="outlined"
												/>
											</FormControl>
										</Grid>
										<Grid item xs={12} lg={4}>
											<FormControl variant="outlined" fullWidth>
												<TextField
													label="State"
													margin="none"
													id="outlined-adornment-customer-state"
													value={tenant.state || ''}
													required
													error={errorList.state}
													onChange={handleChange('state')}
													aria-describedby="outlined-weight-helper-text"
													InputProps={{
														'aria-label': 'customer-address3',
														maxLength: 100
													}}
													variant="outlined"
												/>
											</FormControl>
										</Grid>
										<Grid item xs={12} lg={4}>
											<FormControl variant="outlined" fullWidth>
												<TextField
													label="Pincode"
													margin="none"
													required
													error={errorList.pincode}
													id="outlined-adornment-customer-pincode"
													value={tenant.pincode || ''}
													onChange={handleChange('pincode')}
													aria-describedby="outlined-weight-helper-text"
													inputProps={{
														'aria-label': 'customer-pincode',
														maxLength: 6
													}}
													variant="outlined"
												/>
											</FormControl>
										</Grid>
									</Grid>
									<Grid item xs={12} lg={12}>
										<Divider style={{marginTop: 8}}/>
									</Grid>
									<Grid item xs={12} lg={12}>
										<CardHeader
											title="Contact information"
											titleTypographyProps={{variant: 'h4'}}
											action={
												<IconButton aria-label="add-row" onClick={addRow}>
													<AddCircleIcon />
												</IconButton>
											}
										/>
									</Grid>
										{
											itemRows.map(row => 
												<Grid container spacing={1} key={row.rowid} item xs={12} lg={12}>
													<Grid item xs={12} lg={2}>
														<TextField 
															id="outlined-basic-name" 
															label="Name" 
															name="name"
															variant="outlined"
															margin="dense"
															error={errorItemRow.find(i => i.rowid === row.rowid).name || false}
															value={row.name ? row.name : ''} 
															required
															fullWidth
															style={{marginTop: 8}}
															type="text"
															onChange={handleRowChange(row.rowid)}/>
													</Grid>
													<Grid item xs={12} lg={2}>
														<TextField 
															id="outlined-basic-mobile" 
															label="Mobile" 
															name="mobile"
															variant="outlined"
															margin="dense"
															error={errorItemRow.find(i => i.rowid === row.rowid).mobile || false}
															value={row.mobile ? row.mobile : ''} 
															required
															fullWidth
															style={{marginTop: 8}}
															type="text"
															onChange={handleRowChange(row.rowid)}/>
													</Grid>
													<Grid item xs={12} lg={2}>
														<TextField 
															id="outlined-basic-email" 
															label="Email" 
															name="email"
															variant="outlined"
															margin="dense"
															value={row.email ? row.email : ''}
															fullWidth
															style={{marginTop: 8}}
															type="text"
															onChange={handleRowChange(row.rowid)}/>
													</Grid>
													<Grid item xs={12} lg={2}>
														<TextField 
															id="outlined-basic-department" 
															label="Department" 
															name="department"
															variant="outlined"
															fullWidth
															margin="dense"
															error={errorItemRow.find(i => i.rowid === row.rowid).department || false}
															value={row.department ? row.department : ''} 
															required
															style={{marginTop: 8}}
															type="text"
															onChange={handleRowChange(row.rowid)}/>
													</Grid>
													<Grid item xs={12} lg={3}>
														<TextField 
															id="outlined-basic-address" 
															label="Address" 
															name="address"
															fullWidth
															variant="outlined"
															margin="dense"
															value={row.address ? row.address : ''}
															style={{marginTop: 8}}
															type="text"
															onChange={handleRowChange(row.rowid)}/>
													</Grid>
													<Grid item xs={12} lg={1}>
														<Icon style={{marginTop: 14}} onClick={handleRowRemove(row.rowid)}>delete</Icon>
													</Grid>													
												</Grid>
											)
										}
									<Grid item xs={12} lg={12}>
										<Divider style={{marginTop: 8}}/>
									</Grid>
									<Grid item xs={12} lg={12}>
										<div style={{float: 'right'}}>
											<Button variant="contained" color="primary" onClick={saveTenant}>Save</Button>
										</div>
									</Grid>
								</Grid>
							</div>
						</Paper>
					</Grid>
				</Grid>
			</Grid>
		</Dialog>
	);
}

ModifyTenant.prototype = {
    open: PropTypes.bool.isRequired,
    id: PropTypes.string,
    onClose: PropTypes.func.isRequired
};