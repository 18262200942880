import Config from '../Config';
import {RestService} from './RestService';

export const UserService = {

    authenticate: async function(body, headers = {}){
        return RestService.post(Config.authenticate_url, headers, body)
                .then(response => response.json());
    },
    authenticateToken: async function(body, headers = {}){
        return RestService.post(Config.authenticate_token_url, headers, body)
                .then(response => response.json());
    },
    changePassword: async function(body, headers = {}) {
        return RestService.post(Config.change_password_url, headers, body)
        .then(response => response.json());
    },
    resetPassword: async function(body, headers = {}) {
        return RestService.post(Config.reset_password_url, headers, body)
        .then(response => response.json());
    },
    requestResetPassword: async function(body, headers = {}) {
        return RestService.post(Config.request_reset_password_url, headers, body)
        .then(response => response.json());
    },
    getInvoiceConfig: async function(){
        return RestService.get(Config.invoice_config_url, {})
        .then(response => response.json());
    },
    saveInvoiceConfig: async function(body, headers = {}){
        return RestService.post(Config.invoice_config_url, headers, body)
        .then(response => response.json());
    },
    saveUserData: async function(body, headers = {}){
        return RestService.post(Config.user_info_url, headers, body)
        .then(response => response.json());
    },
    uploadLogo: async function(body, headers = {}) {
        return RestService.postFormData(Config.logo_url, headers, body)
        .then(response => response.json());
    },
    removeLogo: async function(headers = {}) {
        return RestService.delete(Config.logo_url, headers);
    },
    getUsageQuota: async function(){
        return RestService.get(Config.user_quota_url, {})
        .then(response => {
            return response.ok ? response.json() : Promise.reject({success: false});
        });
    },
};