import React, { useState } from "react";
import { Card, CardHeader, Divider, CardContent, makeStyles, Button, CardActions, List, ListItem, Typography, ListItemText, Grid, CircularProgress } from "@material-ui/core";
import { format_display_fancy_notime, openLink, BootstrapTooltip, getTimeInIST, format_display_fancy_my, formatNumber } from "../Utils";
// import VisibilityIcon from '@material-ui/icons/Visibility';
// import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from "prop-types";
import clsx from 'clsx';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import moment from "moment";
import { PropertyService } from "../Services/PropertyService";

const useStyles = makeStyles(theme => ({
    root: {
        minHeight: 100,
    },
    icon: {
        padding: 2
    },
    content: {
        paddingTop: 0,
        paddingBottom: 0,
        minHeight: 100,
        paddingLeft: 0,
        paddingRight: 0,
    },
    itemDivider: {
        borderBottomColor: 'rgba(0,0,0,0.1)'
    },
    actions: {
        paddingTop: 0,
        justifyContent: 'flex-end'
    },
    arrowForwardIcon: {
        marginLeft: theme.spacing(1)
    },
    select: {
        marginTop: 8
    },
    griditem: {
        cursor: 'move'
    },
    loader: {
        marginTop: 40
    },
    inner: {
        overflow: 'auto',
    },
    comments: {
        display: 'flex',
        alignItems: 'center'
    },
    tableHead: {
        fontSize: 16,
        color: '#263238',
        fontWeight: 500,
        lineHeight: '1.5rem'
    },
    tableCell: {
        fontSize: '0.875rem',
        color: '#263238',
        fontWeight: 400,
        lineHeight: '1.43'
    }
}));

const RentalReceipts = ({ count, ...props }) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const {className} = props;
    const [data, setData] = useState([]);

    React.useEffect(() => {
        setLoading(true);
        PropertyService.getInvoiceList(null, null, null, moment().startOf('month'), moment().endOf('month'), 'rental_month', 'desc', 0, count)
        .then((data) => {
            console.log(data);
            if(data.length > 0){
                setData(data);
            }
            setLoading(false);
        }).catch(error => {
            console.log(error);
            setLoading(false);
        })
    }, [true]);

    return (
        <Grid item xl={12} lg={12} xs={12}>
            <Card 
                className={clsx(classes.root, className)}
                //onMouseEnter={() => setIsShown(true)}
                //onMouseLeave={() => setIsShown(false)}
                >
                <CardHeader 
                    title={`Recent Invoices`}
                    className={classes.griditem}
                    titleTypographyProps={{align: 'left'}}/>
                <Divider />
                <CardContent className={classes.content}>
                    {
                        loading ?
                            <div className={classes.loader}>
                                <CircularProgress/>
                            </div>
                        :
                        <div className={classes.inner}>
                            <List>
                                {data.length > 0 ? 
                                    data.map((row, index) => (
                                        <ListItem
                                            classes={{ divider: classes.itemDivider }}
                                            style={{paddingLeft: 10, paddingRight: 10}}
                                            divider
                                            dense
                                            key={index}
                                        >
                                            <ListItemText
                                                disableTypography
                                                primary={
                                                    <Typography align="left" component="legend"><b>{`Property: `}</b>{row.property_name}</Typography>
                                                }
                                                secondary={
                                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                                        <Typography 
                                                            variant="caption" 
                                                            style={{flex: 1}}
                                                            component="div" >
                                                            {`Rental Period: ${row.rental_month ? getTimeInIST(row.rental_month).format(format_display_fancy_my) : '-'}`}
                                                        </Typography>
                                                        <BootstrapTooltip title={`Click to view Invoice`}>
                                                            <Typography 
                                                                variant="caption" 
                                                                style={{marginRight: 8, cursor: 'pointer'}} 
                                                                align="right" 
                                                                component="div"
                                                                onClick={(e) => {
                                                                    if(row.link){
                                                                        openLink(row.link);
                                                                    }
                                                                }} >
                                                                {formatNumber(row.amount)}
                                                            </Typography>
                                                        </BootstrapTooltip>
                                                        <Typography variant="caption" align="right" component="div">
                                                            {`Due: ${row.due_date ? moment(row.due_date).format(format_display_fancy_notime) : '-'}`}
                                                        </Typography>
                                                    </div>
                                                }/>
                                        </ListItem>
                                    ))
                                    :
                                    (
                                        <ListItem
                                            classes={{ divider: classes.itemDivider }}
                                            style={{paddingLeft: 24, paddingRight: 10}}
                                            dense
                                        >
                                            <ListItemText
                                                primary={'No Invoices'}
                                                />
                                        </ListItem>
                                    )
                                }
                            </List>
                        </div>
                    }
                </CardContent>
                <CardActions className={classes.actions}>
                    <Button
                        color="inherit"
                        component={RouterLink}
                        size="small"
                        variant="text"
                        to="/view/invoice"
                    >
                        See all
                        <ArrowForwardIcon className={classes.arrowForwardIcon} />
                    </Button>
                </CardActions>
            </Card>
        </Grid>
    );
};

RentalReceipts.propTypes = {
    count: PropTypes.number.isRequired,
};

export default RentalReceipts;