import React, { useContext } from "react";
import { Card, CardHeader, Divider, CardContent, makeStyles, List, ListItem, Typography, ListItemText, Grid } from "@material-ui/core";
import { getTimeInIST, format_display_fancy_notime } from "../Utils";
import clsx from 'clsx';
import { AppContext } from "../AppContextProvider";

const useStyles = makeStyles(theme => ({
    root: {
        
        minHeight: 100,
    },
    icon: {
        padding: 2
    },
    content: {
        paddingTop: 0,
        paddingBottom: 0,
        minHeight: 50,
        paddingRight: 0,
        paddingLeft: 0,
    },
    itemDivider: {
        borderBottomColor: 'rgba(0,0,0,0.1)'
    },
    itemRoot: {
        textDecoration: 'none'
    },
    actions: {
        paddingTop: 0,
        justifyContent: 'flex-end'
    },
    arrowForwardIcon: {
        marginLeft: theme.spacing(1)
    },
    select: {
        marginTop: 8
    },
    griditem: {
        cursor: 'move'
    },
    loader: {
        marginTop: 40
    }
}));

const LicenseDetails = (props) => {
    
    const classes = useStyles();
    const {className} = props;
    const {state} = useContext(AppContext);

    return (
        <Grid item xl={12} lg={12} xs={12}>
            <Card
                className={clsx(classes.root, className)}
                //onMouseEnter={() => setIsShown(true)}
                //onMouseLeave={() => setIsShown(false)}
                >
                <CardHeader 
                    title={'License Details'}
                    className={classes.griditem}
                    titleTypographyProps={{align: 'left'}} />
                <Divider />
                <CardContent className={classes.content}>
                    <List>
                        <ListItem
                            classes={{ divider: classes.itemDivider }}
                            style={{textDecoration: 'none'}}
                            divider
                            dense
                        >
                                <ListItemText
                                    primary={`Property limit`}
                                    primaryTypographyProps={{ variant: 'subtitle2' }}
                                />
                            <Typography color="inherit">{state.user.property_limit}</Typography>
                        </ListItem>
                        <ListItem
                            classes={{ divider: classes.itemDivider }}
                            style={{textDecoration: 'none'}}
                            divider
                            dense
                        >
                                <ListItemText
                                    primary={`Tenant History per property`}
                                    primaryTypographyProps={{ variant: 'subtitle2' }}
                                />
                            <Typography color="inherit">{state.user.tenant_history_limit}</Typography>
                        </ListItem>
                        <ListItem
                            classes={{ divider: classes.itemDivider }}
                            style={{textDecoration: 'none'}}
                            divider
                            dense
                        >
                                <ListItemText
                                    primary={`Document limit per property`}
                                    primaryTypographyProps={{ variant: 'subtitle2' }}
                                />
                            <Typography color="inherit">{state.user.property_document_limit}</Typography>
                        </ListItem>
                        <ListItem
                            classes={{ divider: classes.itemDivider }}
                            style={{textDecoration: 'none'}}
                            divider
                            dense
                        >
                                <ListItemText
                                    primary={`Photo limit per property`}
                                    primaryTypographyProps={{ variant: 'subtitle2' }}
                                />
                            <Typography color="inherit">{state.user.photo_limit}</Typography>
                        </ListItem>
                        <ListItem
                            classes={{ divider: classes.itemDivider }}
                            style={{textDecoration: 'none'}}
                            divider
                            dense
                        >
                                <ListItemText
                                    primary={`License Expiry`}
                                    primaryTypographyProps={{ variant: 'subtitle2' }}
                                />
                            <Typography color="inherit">{getTimeInIST(state.user.licence_expiry_date).format(format_display_fancy_notime)}</Typography>
                        </ListItem>
                    </List>
                </CardContent>
            </Card>
        </Grid>
    );
};

export default LicenseDetails;