import React, { useContext, useState } from "react";
import "../css/home.css";
import {BootstrapTooltip} from '../Utils';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import {
	Grid,
} from '@material-ui/core';
import { AppContext } from "../AppContextProvider";
import CardViewBlock from "../components/CardViewBlock";
import CardViewLoader from "../components/CardViewLoader";
import { PropertyService } from "../Services/PropertyService";
import { UserService } from "../Services/UserService";
import { TenantService } from "../Services/TenantService";
import LicenseDetails from "./LicenseDetails";
import RentalReceipts from "./RentalReceipts";
import { useHistory } from "react-router-dom";

function Home(props){
	const history = useHistory();
	const {state, setTitle} = useContext(AppContext);
	const [showBlockLoader, setShowBlockLoader] = useState(false);

	const [vacantPropertyCount, setVacantPropertyCount] = useState(0);
	const [maintenancePropertyCount, setMaintenancePropertyCount] = useState(0);
	const [usedPropertyCount, setUsedPropertyCount] = useState(0);
	const [tenantCount, setTenantCount] = useState(0);
	const [agreementsEndingXDays, setAgreementsEndingXDays] = useState(0);
	const [rentalPending, setRentalPendingCount] = useState(0);

	const days = 60;
	
	React.useEffect(() => {
		if(state.isAuthenticated){
			setTitle('Dashboard');
			fetchDashBoard();
		}
		return () => {
		}
	}, [state.isAuthenticated, true]);

	const fetchDashBoard = () => {
		setShowBlockLoader(true);
		Promise.all([
			PropertyService.getAgreementEndingInXDaysCount(days), 
			UserService.getUsageQuota(), 
			PropertyService.getPendingPaymentsCount(),
			TenantService.getTenantCount(null, null, true, ''), 
		    PropertyService.getPropertyCount('VACANT', null, ''),
		    PropertyService.getPropertyCount('UNDER_MAINTENANCE', null, '')
		])
		.then(datas => {
			console.log(datas);
			if(datas[0]){
				setAgreementsEndingXDays(datas[0].count || 0);
			}
			if(datas[1]){
				setUsedPropertyCount(datas[1].property_usage_count || 0);
			}
			if(datas[2]){
				setRentalPendingCount(datas[2].count || 0);
			}
			if(datas[3]){
				setTenantCount(datas[3].count || 0);
			}
			if(datas[4]){
				setVacantPropertyCount(datas[4].count || 0);
			}
			if(datas[5]){
				setMaintenancePropertyCount(datas[5].count || 0);
			}
			setShowBlockLoader(false);
		})
		.catch(error => {
			console.log(error);
		})
	}

	return (
		<div className="Home">
			<div className="lander">
				{
					state.user && (
						<div>
							<Grid container direction="row" spacing={1} justify={"center"}>
								<Grid container item xl={8} lg={10} xs={12} spacing={2} justify={"space-between"}>
									{/* <Grid item xl={12} lg={12} xs={12}>
										<div style={{float: "right"}}>
											<Typography variant="caption">
												Last Refreshed: 
												{
													lastRefreshed && lastRefreshedString
												}
											</Typography>
											<IconButton aria-label="close" data-tour="refresh" onClick={refresh}>
												<Icon>refresh</Icon>
											</IconButton>
										</div>
									</Grid> */}
									<Grid container item spacing={0} data-tour="today" xs={12} lg={12} xl={12}>
										{/* <Grid item lg={3} xs={12}>
											<CardViewBlock title={moment().format(format_display_fancy_notime_withDay)} subtitle={'Today'} />
										</Grid> */}
										<Grid item lg={2} xs={6}>
											{
												showBlockLoader ?
													<CardViewLoader />
												:
													<CardViewBlock 
														title={'Properties Listed'} 
														style={{cursor: 'pointer'}}
														onClick={() => history.push('/properties')}
														subtitle={`${usedPropertyCount || `0`} / ${state.user.property_limit}`} />
											}
										</Grid>
										<Grid item lg={2} xs={6}>
											{
												showBlockLoader ?
													<CardViewLoader />
												:
													<CardViewBlock 
														color={vacantPropertyCount > 0 ? "red" : "green"} 
														title={'Vacant Spaces'} 
														style={{cursor: 'pointer'}}
														onClick={() => history.push('/properties?status=VACANT')}
														subtitle={vacantPropertyCount || `0`} />
											}
										</Grid>
										<Grid item lg={2} xs={6}>
											{
												showBlockLoader ?
													<CardViewLoader />
												:
													<CardViewBlock 
														color={maintenancePropertyCount > 0 ? "red" : "green"} 
														title={'Under Maintenance'} 
														style={{cursor: 'pointer'}}
														onClick={() => history.push('/properties?status=UNDER_MAINTENANCE')}
														subtitle={maintenancePropertyCount || `0`} />
											}
										</Grid>
										<Grid item lg={2} xs={6}>
											{
												showBlockLoader ?
													<CardViewLoader />
												:
													<CardViewBlock 
														style={{cursor: 'pointer'}}
														onClick={() => history.push('/tenants')}
														color="black" 
														title={'Current Tenants'} 
														subtitle={tenantCount || `0`} />
											}
										</Grid>
										<Grid item lg={2} xs={6}>
											{
												showBlockLoader ?
													<CardViewLoader />
												:
													<CardViewBlock color={rentalPending > 0 ? "red" : "green"} title={'Rentals Pending'} subtitle={rentalPending || `0`} />
											}
										</Grid>
										<Grid item lg={2} xs={6}>
											{
												showBlockLoader ?
													<CardViewLoader />
												:
													<BootstrapTooltip title="Click to view all Agreements">
														<div>
															<CardViewBlock 
																color={agreementsEndingXDays > 0 ? "red" : "green"} 
																title={`Agreements Ending in next ${days} days`} 
																style={{cursor: 'pointer'}}
																onClick={() => history.push('/agreements')}
																subtitle={agreementsEndingXDays || `0`} />
														</div>
													</BootstrapTooltip>
											}
										</Grid>
									</Grid>
								</Grid>
								{/* <Grid container item xl={12} lg={12} xs={12} spacing={0} justify={"flex-start"}>
									<Grid container item data-tour="week" xs={12} lg={12} xl={12}>
										<Grid item lg={3} xs={12}>
											{
												showBlockLoader ?
													<CardViewLoader />
												:
													<CardViewBlock title={`Last updated on: ${goldRate ? getTimeInIST(goldRate.created_date).format(format_fancy) : 'Never' }`} subtitle={`Gold Rate ${formatNumber(goldRate ? goldRate.rate : 0)}`} />
											}
										</Grid>
										{
											showBlockLoader ? 
												[...Array(3).keys()].map(i => <CardViewLoader key={i}/>)
											:
												schemes.map((scheme, index) => 
													<Grid key={index} item lg={2} xs={3}>
														<CardViewBlock title={scheme.name} subtitle={formatNumber(scheme.amount)} />
													</Grid>
												)
										}
										{
											state.user.roles.includes(ROLES.ADMIN) && (
												<Grid item lg={1} xs={6}>
													<CardViewBlock 
														title={'Change'} 
														subtitle={
															<IconButton aria-label="Edit Gold Rate" onClick={openDialog}>
																<Icon>edit</Icon>
															</IconButton>
														} />
												</Grid>
											)

										}
									</Grid> 
								</Grid>*/}
								<Grid container item xl={10} lg={10} xs={12} spacing={1} justify={"space-between"} alignItems="flex-start">
									<Grid item data-tour="license" xs={12} lg={6} xl={6}>
										<LicenseDetails/>
									</Grid>
									<Grid item data-tour="license" xs={12} lg={6} xl={6}>
										<RentalReceipts count={5}/>
									</Grid>
								</Grid>
							</Grid>
						</div>
					)
				}
			</div>
		</div>
	);
}

export default Home;