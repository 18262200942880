import React from "react";
import { useContext } from "react";
import { AppContext } from "../AppContextProvider";
import { makeStyles, Grid, Paper, Table, TablePagination, TableRow, TableBody, Typography, Divider, Icon, ListItemText, Tooltip, IconButton, Fab } from "@material-ui/core";
import { blue, red, grey } from "@material-ui/core/colors";
import { TABLE_COUNTS } from "../Constants";
import { RowCountOptions } from "../Config";
// import EnhancedTableToolbar from "../components/EnhancedTableToolbar";
import EnhancedTableHead from "../components/EnhancedTableHead";
import { toInt, StyledTableCell, BootstrapTooltip, format_display_fancy_notime, getVacancyStatusBadge, openMap } from "../Utils";
import moment from "moment";
import ItemSelection from "../components/ItemSelection";
// import DateRangePickerCustom from "../components/DateRangePickerCustom";
import SearchBar from "../components/SearchBar";
import { PropertyService } from "../Services/PropertyService";
import AddIcon from '@material-ui/icons/Add';
import ChangeStatus from "../components/ChangeStatus";
import queryString from 'query-string';

const useStyles = makeStyles(theme => ({
    section: {
        width: '100%',
        height: '100%',
        marginTop: theme.spacing(1),
        paddingTop : theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    qtyField: {
        width: "40%",
        position: "relative",
        [theme.breakpoints.up('md')]: {
            right: '100px',
            width: "10%",
            marginRight: 10
        },
        right: "22px"
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginBottom : '20px',
    },
    grid: {
        [theme.breakpoints.up('md')]: {
            marginTop: '10px',
        },
        marginTop: '50px',
        marginBottom: '5px'
    },
    icon: {
        padding: '0px',
        [theme.breakpoints.up('md')]: {
            padding: '12px',
        },
    },
    tablecell: {
        fontSize: '16pt',
        padding: theme.spacing(4)
    },
    container: {
        paddingTop : theme.spacing(1)
    },
    redAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: red[500],
    },
    blueAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: blue[500],
    },
    instructions: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },
    button: {
        marginTop: theme.spacing(1),
        // height: theme.spacing(7),
        float: 'right',
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            float: 'inherit',
            marginLeft: theme.spacing(1)
        },
        marginRight: theme.spacing(4),
        
    }, 
    fab: {
        margin: theme.spacing(1),
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    tableWrapper: {
        maxHeight: 407,
        overflow: 'auto',
    },
    list: {
        width: '100%',
        height: 250,
        overflow: "auto"
    },
    summary: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    title: {
        fontSize: 18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    subtitle: {
        fontSize:18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    selectRoot: {
        fontSize: 12,
        [theme.breakpoints.down('md')]: {
            marginLeft: 0,
            marginRight: 0
        },
    },
}));

export default function ViewProperties(props){

    const {state, setTitle, setLinearLoading, showProgressDialog, closeProgressDialog, showConfirmDialog, closeConfirmDialog, showSnackBar, openNewProperty, openViewProperty, showConfirmDialog1D, closeConfirmDialog1D} = useContext(AppContext);
    const classes = useStyles();
    const [selectedType, setSelectedType] = React.useState([]);
    const [selectedVacancyStatuses, setSelectedVacancyStatuses] = React.useState([]);
    const [filter, setFilter] = React.useState('');
    const vacancyStatuses = [{name: 'Vacant', value: 'VACANT'}, {name: 'Occupied', value: 'OCCUPIED'}, {name: 'Under Maintenance', value: 'UNDER_MAINTENANCE'} ]
    const [order, setOrder] = React.useState('asc');
    const [types, setTypes] = React.useState([]);
    const [orderBy, setOrderBy] = React.useState('name');
    const [currentRows, setCurrentRows] = React.useState([]);
    const [generate, setGenerate] = React.useState(true);
    const [loadedMaster, setLoadedMaster] = React.useState(false);
    const columns = [
        { id: 'index', align: 'left', numeric: false, disablePadding: false, label: 'Sl.No.', sortable: false },
        { id: 'name', align: 'justify', numeric: false, disablePadding: false, label: 'Name', sortable: true },
        { id: 'address1', align: 'center', numeric: false, disablePadding: true, label: 'Address', sortable: true },
        { id: 'geo_coordinates', align: 'center', numeric: false, disablePadding: true, label: 'Location', sortable: false },
        { id: 'type', align: 'center', numeric: false, disablePadding: true, label: 'Type', sortable: true },
        { id: 'measurement', align: 'center', numeric: false, disablePadding: true, label: 'Measurement', sortable: true },
        { id: 'area', align: 'center', numeric: false, disablePadding: true, label: 'Area', sortable: true },
        { id: 'vacancy_status', align: 'center', numeric: false, disablePadding: true, label: 'Vacancy Status', sortable: true },
        { id: 'agreement_end_date', align: 'center', numeric: false, disablePadding: false, label: 'Agreement Ending On', sortable: true },
        { id: 'action', align: 'right', numeric: true, disablePadding: false, label: '', sortable: false },
    ];

    //Pagination
    const [totalItems, setTotalItems] = React.useState(0);
    const [numItemsPerPage, setNumItemsPerPage] = React.useState(localStorage.getItem(TABLE_COUNTS.property_tab_count) ? toInt(localStorage.getItem(TABLE_COUNTS.property_tab_count)) : RowCountOptions[0]);
    const [currentPageNumber, setCurrentPageNumber] = React.useState(0);
    const [fetchPage, setFetchPage] = React.useState(false);

    //Dialog
    const [openChangeStatus, setOpenChangeStatus] = React.useState(false);
    const [selectedProperty, setSelectedProperty] = React.useState({});

    const handleChangeRowsPerPage = event => {
        setNumItemsPerPage(event.target.value);
        setCurrentPageNumber(0);
        setFetchPage(true);
        localStorage.setItem(TABLE_COUNTS.property_tab_count, event.target.value);
    }

    const handleChangePage = (event, page) => {
        if(currentPageNumber > page){
            fetchRow(currentPageNumber - 1);
        } else {
            fetchRow(currentPageNumber + 1);
        }
    };

    const fetchRow = (newCurrentPage) => {
        setCurrentPageNumber(newCurrentPage);
        setFetchPage(true);
    }

    React.useEffect(() => {
        setTitle('Properties');
        if(props.location && props.location.search){
            let qString = queryString.parse(props.location.search);
            if(qString.status){
                let status = vacancyStatuses.find(s => s.value === qString.status);
                if(status){
                    setSelectedVacancyStatuses([status]);
                }
			}
		}
        let data = [
            {
                id: "RESIDENTIAL",
                name: "RESIDENTIAL"
            },
            {
                id: "COMMERCIAL",
                name: "COMMERCIAL"
            },
            {
                id: "INDUSTRIAL",
                name: "INDUSTRIAL"
            },
            {
                id: "LAND",
                name: "LAND"
            },
        ];
        setTypes(data);
        setLoadedMaster(true);
        return () => {
            setTitle('');
        }
    }, [true]);

    React.useEffect(() => {
        // console.log(generate, loadedMaster)
        if(generate && loadedMaster){
            setCurrentRows([]);
            setLinearLoading(true);
            setCurrentPageNumber(0);
            setTotalItems(0);
            // setToolTitle(undefined);
            showProgressDialog();
            let types = selectedType && selectedType.length > 0 ? selectedType.map(s => s.id).join(',') : null;
            let vacancies = selectedVacancyStatuses && selectedVacancyStatuses.length > 0 ? selectedVacancyStatuses.map(s => s.value).join(',') : null;
            let fc = filter && filter.length > 0 ? filter : '';
            PropertyService.getPropertyCount(vacancies, types, fc)
            .then(data => {
                console.log(data);
                setTotalItems(toInt(data.count));
                setFetchPage(true);
                if(toInt(data.count) === 0){
                    setCurrentRows([]);
                }
                setLinearLoading(false);
            })
            .catch(error => {
                console.log(error);
                setLinearLoading(false);
            });
            setGenerate(false);
        }
    }, [generate, loadedMaster]);

    const openDialog = (id) => {
        openViewProperty(id, (shouldRefresh) => {
            if(shouldRefresh){
                setGenerate(true);
            }
        });
    }

    const handleEdit = (id) => {
        if(id || (state && totalItems < toInt(state.user.property_limit))){
            console.log(id);
            openNewProperty(id, () => {
                console.log('Refresh');
                setGenerate(true)
            });
        } else {
            showConfirmDialog1D(`You have Utilized your property Limits. Maximum allowed is ${state.user.property_limit}.`, undefined, () => {
                closeConfirmDialog1D();
            }, 'OK')
        }
    }

	const remove = (id) => {
		showProgressDialog('Removing Property...');
		PropertyService.removeProperty(id)
		.then(data => {
            showSnackBar('Property Removed', 'info');
            closeProgressDialog();
            setGenerate(true);
		})
		.catch(error => {
			console.log(error);
			showConfirmDialog('Something went wrong. Please try again.', undefined, () => {
				closeConfirmDialog();
			});
			closeProgressDialog();
		})
    }
    
    const handleRemove = (id) => {
        console.log(id);
        showConfirmDialog(`Confirm Removal of Property?`, undefined, () => {
            closeConfirmDialog();
            remove(id);
        });
    }

    React.useEffect(() => {
        let rows = [];
        if(fetchPage) {
            setLinearLoading(true);
            let types = selectedType && selectedType.length > 0 ? selectedType.map(s => s.id).join(',') : null;
            let vacancies = selectedVacancyStatuses && selectedVacancyStatuses.length > 0 ? selectedVacancyStatuses.map(s => s.value).join(',') : null;
            let fc = filter && filter.length > 0 ? filter : '';
            PropertyService.getPropertyList(vacancies, types, fc, orderBy, order, currentPageNumber * numItemsPerPage, numItemsPerPage)
            .then((data) => {
                console.log(data);
                if(data){
                    data.forEach((element, index) => {
                        let days = element.agreement_end_date ? moment(element.agreement_end_date).diff(moment().startOf('d'), 'days') : 0;
                        rows.push(
                            <TableRow 
                                key={index} 
                                hover
                                style={{cursor: 'pointer'}} 
                                onClick={(e) => {
                                    e.stopPropagation();
                                    openDialog(element.id);
                                }}>
                                <StyledTableCell scope="row" align="center" padding='none' style={{width: 20}}>
                                    {index + 1 + (currentPageNumber * numItemsPerPage)}
                                </StyledTableCell>
                                <StyledTableCell scope="row" align="justify" padding='none'>
                                    <ListItemText
                                        primary={element.name || ''}
                                        secondary={element.nickname}
                                        secondaryTypographyProps={{style: {color: 'red'}}}
                                    />
                                </StyledTableCell>
                                <StyledTableCell scope="row" align="justify" padding='none'>
                                    <ListItemText
                                        primary={<span>{element.address1}<br/>
                                                    {element.address2 && (<span>{element.address2}<br/></span>)}
                                                    {element.city && (<span>{element.city}<br/></span>)}
                                                    {element.state && (<span>{element.state}<br/></span>)}
                                                    </span>}
                                        secondary={element.pincode}
                                        secondaryTypographyProps={{style: {color: grey[600]}}}
                                    />
                                </StyledTableCell>
                                <StyledTableCell scope="row" align="center" padding='none' onClick={(e) => {element.geo_coordinates && openMap(element.geo_coordinates)}}>
                                    <ListItemText
                                        primary={element.geo_coordinates ? 'Click to View' : ''}
                                        secondary={element.geo_coordinates ? element.geo_coordinates : ''}
                                        secondaryTypographyProps={{style: {color: grey[600]}}} />
                                </StyledTableCell>
                                <StyledTableCell scope="row" align="center" padding='none' style={{paddingRight: 16}}>
                                    {element.type}
                                </StyledTableCell>
                                <StyledTableCell scope="row" align="center" padding='none' style={{paddingRight: 16}}>
                                    {element.measurement ? element.measurement : '-'}
                                </StyledTableCell>
                                <StyledTableCell scope="row" align="center" padding='none'>
                                    {element.area ? `${element.area} ${element.area_unit || ''}` : '-'}
                                </StyledTableCell>
                                <StyledTableCell 
                                    scope="row" 
                                    align="center" 
                                    padding='none' 
                                    style={{paddingRight: 16}} 
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        changeStatus(element)
                                    }}>
                                    {element.vacancy_status ? getVacancyStatusBadge(element.vacancy_status) : '-'}
                                </StyledTableCell>
                                <StyledTableCell scope="row" align="center" padding='none'>
                                    <ListItemText
                                        primary={element.agreement_end_date ? moment(element.agreement_end_date).format(format_display_fancy_notime) : '-'}
                                        secondary={days !== 0 ? (days > 0 ? `${days} days left` : `${days} days ago`) : ''}
                                        secondaryTypographyProps={{style: {color: grey[600]}}}
                                    />
                                </StyledTableCell>
                                <StyledTableCell scope="row" align="right" padding='none' style={{paddingRight: 16}}>
                                    <BootstrapTooltip arrow title="Edit">
                                        <Icon 
                                            fontSize="small" 
                                            style={{marginTop: 8, marginRight: 8}} 
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleEdit(element.id)
                                            }}>edit</Icon>
                                    </BootstrapTooltip>
                                    <BootstrapTooltip arrow title="Remove">
                                        <Icon 
                                            fontSize="small" 
                                            style={{marginTop: 8, marginRight: 16}} 
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleRemove(element.id)
                                            }}>delete</Icon>
                                    </BootstrapTooltip>
                                </StyledTableCell>
                            </TableRow>
                        );
                    });
                }
                setCurrentRows(rows);
                setLinearLoading(false);
                closeProgressDialog();
            })
            .catch(error => {
                console.log(error);
                setLinearLoading(false);
                closeProgressDialog();
            });
        }
        setFetchPage(false);
    }, [fetchPage]);

    const sortBy = (event, property) => {
        let o = 'desc';
        if (orderBy === property && order === 'desc') {
            o = 'asc';
        }
        setOrder(o);
        setOrderBy(property);
        setGenerate(true);
    }

    const changeStatus = (property) => {
        setOpenChangeStatus(true);
        setSelectedProperty(property);
    }

    return (
        <div className="Home">
            <div className="lander">
                <Grid container direction="row" spacing={1} justify="center">
                    <Grid item xl={11} lg={12} xs={12}>
                        <Paper className={classes.section}>
                            <Grid container spacin={1} alignItems="center">
                                <Grid item xs={12} lg={2}>
                                    <ItemSelection 
                                        multiple 
                                        clearable={true} 
                                        label='Type' 
                                        value={selectedType} 
                                        optionLabel='name' 
                                        options={types} 
                                        selected={setSelectedType}/>
                                </Grid>
                                <Grid item xs={12} lg={2}>
                                    <ItemSelection 
                                        multiple 
                                        clearable={true} 
                                        label='Vacancy Status' 
                                        value={selectedVacancyStatuses} 
                                        optionLabel='name' 
                                        options={vacancyStatuses} 
                                        selected={setSelectedVacancyStatuses}/>
                                </Grid>
                                <Grid item xs={12} lg={3}>
                                        {
                                            loadedMaster && (
                                                <div style={{float: 'right', marginTop: 8, marginRight: 8}}>
                                                    <SearchBar 
                                                        hint="Search..."
                                                        searchText={filter}
                                                        onTextChange={(e) => {
                                                        setFilter(e.target.value);
                                                        setGenerate(true);
                                                    }}/>
                                                </div>
                                            )
                                        }
                                </Grid>
                                <Grid item>
                                    <Tooltip title="Refresh">
                                        <IconButton size="medium" style={{marginTop: 8}} aria-label="Refresh" color="primary" onClick={() => {!generate && setGenerate(true)}}>
                                            <Icon>refresh</Icon>
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <Divider style={{marginBottom: 0, marginTop: 10}}/>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <div style={{minHeight: 100, overflowY: 'auto', display: 'block'}}>
                                        <Table className={classes.table} stickyHeader>
                                            <EnhancedTableHead
                                                order={order}
                                                orderBy={orderBy}
                                                onRequestSort={sortBy}
                                                rows={columns} />
                                            <TableBody>
                                                {currentRows}
                                            </TableBody>
                                        </Table>
                                    </div>
                                    {
                                        totalItems === 0 && 
                                            <Typography color="error" variant="subtitle2">No Properties to list</Typography>
                                    }
                                    <TablePagination
                                        rowsPerPageOptions={RowCountOptions}
                                        component="div"
                                        count={totalItems}
                                        rowsPerPage={numItemsPerPage}
                                        page={currentPageNumber}
                                        backIconButtonProps={{
                                            'aria-label': 'Previous Page',
                                        }}
                                        nextIconButtonProps={{
                                            'aria-label': 'Next Page',
                                        }}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                        classes={{ selectRoot : classes.selectRoot}}/>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
            <ChangeStatus 
                open={openChangeStatus} 
                id={selectedProperty.id} 
                subtitle={selectedProperty.name}
                fullWidth={true}
                maxWidth={'xs'} 
                refresh={() => {
                    setGenerate(true);
                }} 
                handleClose={() => {
                    setOpenChangeStatus(false);
                    setSelectedProperty({});
                }}/>
            {
                !state.linearLoading && (
                    <BootstrapTooltip arrow title="Add Property">
                        <Fab onClick={() => handleEdit(null)} color="primary" aria-label="Add" className={classes.fab}>
                            <AddIcon />
                        </Fab>
                    </BootstrapTooltip>
                )
            }
        </div>
    )
}