// let organisation = localStorage.getItem('caretaker.organisation') === null ? "sysbrew-test" : localStorage.getItem('caretaker.organisation');

const host = process.env.NODE_ENV === 'production' ? "http://webservice.sysbrew.in/caretaker" : "http://webservice.sysbrew.in/caretaker";
// const host = process.env.NODE_ENV === 'production' ? "http://goldloan.sysbrew.in/" : "http://localhost:6001/";

const Config = {

    authenticate_url: `${host}/authenticate`,
    authenticate_token_url: `${host}/authenticate/token`,
    register_fcm_url: `${host}/users/fcm/add`,
    unregister_fcm_url: `${host}/users/fcm/delete`,
    invoice_config_url: `${host}/user/invoice/config`,
    logo_url: `${host}/user/invoice/config/logo`,
    user_info_url: `${host}/user/info`,
    change_password_url: `${host}/user/change/password`,
    request_reset_password_url: `${host}/user/request/reset/password`,
    reset_password_url: `${host}/user/reset/password`,

    //External URL
    google_url: "http://maps.google.com?q=",

    // Application
    save_property_url: `${host}/property`,
    list_properties_url: `${host}/property/list`,
    list_property_names_url: `${host}/property/names`, 
    search_url: `${host}/search`,
    get_property_detail_url: `${host}/property/{id}`,
    save_property_alert_config_url: `${host}/property/alert/config`,
    add_property_document_url: `${host}/property/documents/{id}/{type}`,
    remove_property_document_url: `${host}/property/document/{id}`,
    add_property_asset_url: `${host}/property/asset`,
    get_property_asset_url: `${host}/property/asset/{id}`,
    list_property_asset_url: `${host}/property/asset/list/{propertyId}`,
    report_agreement_ending_count_url: `${host}/agreement/ending/{days}`,
    report_pending_payment_count_url: `${host}/property/agreement/pending/payments`,
    get_property_tenant_agreement_url: `${host}/property/agreement/{propertyId}/tenant/{tenantId}`,
    agreement_list_url: `${host}/agreement/list`,
    agreement_url: `${host}/agreement`,
    agreement_id_url: `${host}/agreement/{id}`,
    agreement_document_url: `${host}/agreement/documents/{id}/{type}`,
    agreement_document_id_url: `${host}/agreement/document/{id}`,
    change_property_status_url: `${host}/property/status/{id}/{status}`,
    change_invoice_status_url: `${host}/invoice/status/{id}/{status}`,
    list_invoices_url: `${host}/invoice/list`,
    invoice_id_url: `${host}/invoice/{id}`,
    generate_invoice_url: `${host}/generate/invoice/{paId}/{date}`,
    list_property_tenant_history_url: `${host}/property/tenant/list/{id}`,
    get_property_current_tenant_url: `${host}/property/tenant/current/{id}`,
    tenant_url: `${host}/tenant`,
    list_tenant_url: `${host}/tenant/list`,
    tenant_id_url: `${host}/tenant/{id}`,
    tenant_agreement_url: `${host}/tenant/agreements/{id}`,
    list_tenant_names_url: `${host}/tenant/names`,
    
    document_url: `${host}/document/{type}`,
    remove_document_url: `${host}/document/{type}/{docId}`,

    user_quota_url: `${host}/user/quota/usage`,
    
    REFRESH_INTERVAL: 300000
}

export default Config;

export const RowCountOptions = [10, 25, 50, 100];