import React from "react";
import { useContext } from "react";
import { AppContext } from "../AppContextProvider";
import { makeStyles, Grid, Paper, Table, TablePagination, TableRow, TableBody, Typography, Divider, Icon, ListItemText, Tooltip, IconButton, Fab, Button, ButtonGroup } from "@material-ui/core";
import { blue, red, grey } from "@material-ui/core/colors";
import { TABLE_COUNTS } from "../Constants";
import { RowCountOptions } from "../Config";
// import EnhancedTableToolbar from "../components/EnhancedTableToolbar";
import EnhancedTableHead from "../components/EnhancedTableHead";
import { toInt, StyledTableCell, BootstrapTooltip, format_display_fancy_notime, formatNumber, openLink } from "../Utils";
import moment from "moment";
import ItemSelection from "../components/ItemSelection";
// import DateRangePickerCustom from "../components/DateRangePickerCustom";
import SearchBar from "../components/SearchBar";
import { PropertyService } from "../Services/PropertyService";
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles(theme => ({
    section: {
        width: '100%',
        height: '100%',
        marginTop: theme.spacing(1),
        paddingTop : theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    qtyField: {
        width: "40%",
        position: "relative",
        [theme.breakpoints.up('md')]: {
            right: '100px',
            width: "10%",
            marginRight: 10
        },
        right: "22px"
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
        textAlign: 'center',
        color: theme.palette.text.secondary,
        marginBottom : '20px',
    },
    grid: {
        [theme.breakpoints.up('md')]: {
            marginTop: '10px',
        },
        marginTop: '50px',
        marginBottom: '5px'
    },
    icon: {
        padding: '0px',
        [theme.breakpoints.up('md')]: {
            padding: '12px',
        },
    },
    tablecell: {
        fontSize: '16pt',
        padding: theme.spacing(4)
    },
    container: {
        paddingTop : theme.spacing(1)
    },
    redAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: red[500],
    },
    blueAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: blue[500],
    },
    instructions: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },
    button: {
        marginTop: theme.spacing(1),
        // height: theme.spacing(7),
        float: 'right',
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            float: 'inherit',
            marginLeft: theme.spacing(1)
        },
        marginRight: theme.spacing(4),
        
    }, 
    fab: {
        margin: theme.spacing(1),
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    tableWrapper: {
        maxHeight: 407,
        overflow: 'auto',
    },
    list: {
        width: '100%',
        height: 250,
        overflow: "auto"
    },
    summary: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    title: {
        fontSize: 18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    subtitle: {
        fontSize:18,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    selectRoot: {
        fontSize: 12,
        [theme.breakpoints.down('md')]: {
            marginLeft: 0,
            marginRight: 0
        },
    },
}));

export default function ViewPAs(props){

    const {state, setTitle, setLinearLoading, showProgressDialog, closeProgressDialog, showConfirmDialog, closeConfirmDialog, showSnackBar, openNewPA, openViewProperty, openViewTenant} = useContext(AppContext);
    const classes = useStyles();
    const [selectedType, setSelectedType] = React.useState([]);
    // const [selectedVacancyStatuses, setSelectedVacancyStatuses] = React.useState([]);
    const [filter, setFilter] = React.useState('');
    const [order, setOrder] = React.useState('desc');
    const [types, setTypes] = React.useState([]);
    const [orderBy, setOrderBy] = React.useState('pa.end_date');
    const [selectedProperty, setSelectedProperty] = React.useState(null);
    const [properties, setProperties] = React.useState([]);
    const selectedTenant = null;
    // const [selectedTenant, setSelectedTenant] = React.useState(null);
    // const [tenants, setTenants] = React.useState([]);
    const [agreementStatus, setAgreementStatus] = React.useState([]);
    const [selectedStatus, setSelectedStatus] = React.useState(null);
    const [currentRows, setCurrentRows] = React.useState([]);
    const [generate, setGenerate] = React.useState(true);
    const [loadedMaster, setLoadedMaster] = React.useState(false);
    const columns = [
        { id: 'index', align: 'left', numeric: false, disablePadding: false, label: 'Sl.No.', sortable: false },
        { id: 'property_name', align: 'center', numeric: false, disablePadding: false, label: 'Property', sortable: true },
        { id: 'tenant_name', align: 'center', numeric: false, disablePadding: true, label: 'Tenant', sortable: true },
        { id: 'pa.start_date', align: 'center', numeric: false, disablePadding: true, label: 'Start', sortable: true },
        { id: 'pa.end_date', align: 'center', numeric: false, disablePadding: true, label: 'End', sortable: true },
        { id: 'pa.amount', align: 'center', numeric: false, disablePadding: true, label: 'Rent', sortable: true },
        { id: 'pa.rate', align: 'center', numeric: false, disablePadding: true, label: 'Rate per Area Unit', sortable: true },
        { id: 'pa.deposit', align: 'center', numeric: false, disablePadding: true, label: 'Deposit', sortable: true },
        { id: 'pa.escalation', align: 'center', numeric: false, disablePadding: false, label: 'Escalation', sortable: true },
        { id: 'pa.renewal_date', align: 'center', numeric: false, disablePadding: false, label: 'Renewal', sortable: true },
        { id: 'pa.notice_period', align: 'center', numeric: false, disablePadding: false, label: 'Notice Period', sortable: true },
        { id: 'pa.due_day', align: 'center', numeric: false, disablePadding: false, label: 'Due On', sortable: true },
        { id: 'pa.link', align: 'center', numeric: false, disablePadding: false, label: 'File', sortable: false },
        { id: 'action', align: 'right', numeric: true, disablePadding: false, label: '', sortable: false },
    ];

    //Pagination
    const [totalItems, setTotalItems] = React.useState(0);
    const [numItemsPerPage, setNumItemsPerPage] = React.useState(localStorage.getItem(TABLE_COUNTS.property_tab_count) ? toInt(localStorage.getItem(TABLE_COUNTS.property_tab_count)) : RowCountOptions[0]);
    const [currentPageNumber, setCurrentPageNumber] = React.useState(0);
    const [fetchPage, setFetchPage] = React.useState(false);

    const handleChangeRowsPerPage = event => {
        setNumItemsPerPage(event.target.value);
        setCurrentPageNumber(0);
        setFetchPage(true);
        localStorage.setItem(TABLE_COUNTS.property_tab_count, event.target.value);
    }

    const handleChangePage = (event, page) => {
        if(currentPageNumber > page){
            fetchRow(currentPageNumber - 1);
        } else {
            fetchRow(currentPageNumber + 1);
        }
    };

    const fetchRow = (newCurrentPage) => {
        setCurrentPageNumber(newCurrentPage);
        setFetchPage(true);
    }

    React.useEffect(() => {
        setTitle('Property Agreements');
        let data = [
            {
                id: "RESIDENTIAL",
                name: "RESIDENTIAL"
            },
            {
                id: "COMMERCIAL",
                name: "COMMERCIAL"
            },
            {
                id: "INDUSTRIAL",
                name: "INDUSTRIAL"
            },
            {
                id: "LAND",
                name: "LAND"
            },
        ];
        setTypes(data);
        let s = [{name: 'All', value: false}, {name: 'Active', value: true}];
        setAgreementStatus(s);
        setSelectedStatus(s[0]);
        PropertyService.getPropertyNames()
        .then(data => {
            setProperties(data);
            setLoadedMaster(true);
        })
        return () => {
            setTitle('');
        }
    }, [true]);

    React.useEffect(() => {
        // console.log(generate, loadedMaster)
        if(generate && loadedMaster){
            setCurrentRows([]);
            setLinearLoading(true);
            setCurrentPageNumber(0);
            setTotalItems(0);
            // setToolTitle(undefined);
            showProgressDialog();
            let types = selectedType && selectedType.length > 0 ? selectedType.map(s => s.id).join(',') : null;
            let fc = filter && filter.length > 0 ? filter : '';
            let property = selectedProperty ? selectedProperty.id : null;
            let current = selectedStatus.value ? true : null;
            PropertyService.getPTACount(property, selectedTenant, current, types, fc)
            .then(data => {
                console.log(data);
                setTotalItems(toInt(data.count));
                setFetchPage(true);
                if(toInt(data.count) === 0){
                    setCurrentRows([]);
                }
                setLinearLoading(false);
            })
            .catch(error => {
                console.log(error);
                setLinearLoading(false);
            });
            setGenerate(false);
        }
    }, [generate, loadedMaster]);

    const handleEdit = (id) => {
        console.log(id);
        openNewPA(id, () => {
            console.log('Refresh');
            setGenerate(true)
        });
        
    }

	const remove = (id) => {
		showProgressDialog('Removing Agreement...');
		PropertyService.removePTA(id)
		.then(data => {
            showSnackBar('Agreement Removed', 'info');
            closeProgressDialog();
            setGenerate(true);
		})
		.catch(error => {
			console.log(error);
			showConfirmDialog('Something went wrong. Please try again.', undefined, () => {
				closeConfirmDialog();
			});
			closeProgressDialog();
		})
    }
    
    const handleRemove = (id) => {
        console.log(id);
        showConfirmDialog(`Confirm Removal of Agreement?`, undefined, () => {
            closeConfirmDialog();
            remove(id);
        });
    }

    const uploadAgreement = (id, file) => {
        let field = 'AGREEMENT';
        console.log(field, file);
		const formData = new FormData();
		formData.append('file', file || null);
		showProgressDialog('Uploading...');
		// setLoading(true);
		PropertyService.addPTADocument(id, field, formData)
		.then(data => {
			// setLoading(false);
			closeProgressDialog();
			showSnackBar('Uploaded document', 'info', 1000);
			setGenerate(true);
		})
		.catch(error => {
            console.log(error);
            closeProgressDialog();
			showSnackBar('Something went wrong. Please try again.', 'error', 1000);
		})
    }

    const removeAgreement = (id) => {
        console.log(id);
		showConfirmDialog('Are you sure you want to remove file?', undefined, () => {
			closeConfirmDialog();
			PropertyService.removePTADocument(id)
			.then(() => {
				showSnackBar('Removed document', 'info', 1000);
				setGenerate(true);
			})
			.catch(error => {
				console.log(error);
				showSnackBar('Something went wrong. Please try again.', 'error', 1000);
			})
		});
    }

    React.useEffect(() => {
        let rows = [];
        if(fetchPage) {
            setLinearLoading(true);
            let types = selectedType && selectedType.length > 0 ? selectedType.map(s => s.id).join(',') : null;
            let property = selectedProperty ? selectedProperty.id : null;
            let current = selectedStatus.value ? true : null;
            let fc = filter && filter.length > 0 ? filter : '';
            PropertyService.getPTAList(property, selectedTenant, current, types, fc, orderBy, order, currentPageNumber * numItemsPerPage, numItemsPerPage)
            .then((data) => {
                console.log(data);
                if(data){
                    data.forEach((element, index) => {
                        let days = element.end_date ? moment(element.end_date).diff(moment().startOf('d'), 'days') : 0;
                        let due = '-';
                        if(element.due_day){
                            due = moment().date(element.due_day).format('Do');
                        }
                        let agreement = null;
                        if(element.documents.length > 0){
                            agreement = element.documents.find(d => d.document_type === 'AGREEMENT');
                        }

                        rows.push(
                            <TableRow 
                                key={index} 
                                hover
                                style={{cursor: 'pointer'}} 
                                //onClick={(e) => {
                                //    e.stopPropagation();
                                //    openDialog(element.id);
                                //}}
                                >
                                <StyledTableCell scope="row" align="center" padding='none' style={{width: 20}}>
                                    {index + 1 + (currentPageNumber * numItemsPerPage)}
                                </StyledTableCell>
                                <StyledTableCell 
                                    scope="row" 
                                    align="justify" 
                                    padding='none'
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        openViewProperty(element.property_id);
                                    }}>
                                    <BootstrapTooltip arrow title="Click to View Property">
                                        <ListItemText
                                            primary={element.property_name || ''}
                                            secondary={element.property_area ? `${element.property_area} ${element.property_area_unit || ''}` : '-'}
                                            secondaryTypographyProps={{style: {color: 'red'}}}
                                        />
                                    </BootstrapTooltip>
                                </StyledTableCell>
                                <StyledTableCell 
                                    scope="row" 
                                    align="justify" 
                                    padding='none'
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        openViewTenant(element.tenant_id);
                                    }}>
                                    <BootstrapTooltip arrow title="Click to View Tenant">
                                        <div>
                                            {element.tenant_name}
                                        </div>
                                    </BootstrapTooltip>
                                </StyledTableCell>
                                <StyledTableCell scope="row" align="center" padding='none'>
                                    {element.start_date ? moment(element.start_date).format(format_display_fancy_notime) : '-'}
                                </StyledTableCell>
                                <StyledTableCell scope="row" align="center" padding='none'>
                                    <ListItemText
                                            primary={element.end_date ? moment(element.end_date).format(format_display_fancy_notime) : '-'}
                                            secondary={days !== 0 ? (days > 0 ? `${days} days left` : `${days} days ago`) : ''}
                                            secondaryTypographyProps={{style: {color: grey[600]}}}
                                        />
                                </StyledTableCell>
                                <StyledTableCell scope="row" align="center" padding='none' style={{paddingRight: 16}}>
                                    {formatNumber(element.amount)}
                                </StyledTableCell>
                                <StyledTableCell scope="row" align="center" padding='none' style={{paddingRight: 16}}>
                                    {formatNumber(element.rate)}
                                </StyledTableCell>
                                <StyledTableCell scope="row" align="center" padding='none' style={{paddingRight: 16}}>
                                    <ListItemText
                                        primary={formatNumber(element.deposit)}
                                        secondary={element.transaction_id ? element.transaction_id : '-' }
                                        secondaryTypographyProps={{style: {color: grey[600]}}}
                                    />
                                </StyledTableCell>
                                <StyledTableCell scope="row" align="center" padding='none' style={{paddingRight: 16}}>
                                    {element.escalation ? element.escalation : '0' }%
                                </StyledTableCell>
                                <StyledTableCell scope="row" align="center" padding='none'>
                                    {element.renewal_date ? moment(element.renewal_date).format(format_display_fancy_notime) : '-' }
                                </StyledTableCell>
                                <StyledTableCell scope="row" align="center" padding='none' style={{paddingRight: 16}}>
                                    {element.notice_period ? element.notice_period : '0' } Months
                                </StyledTableCell>
                                <StyledTableCell scope="row" align="center" padding='none' style={{paddingRight: 16}}>
                                    {due} of every Month
                                </StyledTableCell>
                                <StyledTableCell scope="row" align="center" padding='none' style={{paddingRight: 16}}>
                                    {/* {
                                        agreement.link ? (
                                            <Button 
                                                variant="text" 
                                                color="primary" 
                                                onClick={() => {
                                                    openLink(agreement.link)
                                                }}>
                                                View
                                            </Button>)
                                        : (
                                            <Button 
                                                variant="text" 
                                                color="primary" 
                                                onClick={() => {
                                                    openLink(link)
                                                }}>
                                                Upload
                                            </Button>)
                                    } */}
                                    <DocumentPlaceHolder
                                        title="Add Agreement"
                                        link={agreement ? agreement.link : null} 
                                        change={(file) => {
                                            uploadAgreement(element.id, file);
                                        }} 
                                        remove={() => {
                                            removeAgreement(agreement.id)
                                        }}
                                    />
                                </StyledTableCell>
                                <StyledTableCell scope="row" align="right" padding='none' style={{paddingRight: 16}}>
                                    <BootstrapTooltip arrow title="Edit">
                                        <Icon 
                                            fontSize="small" 
                                            style={{marginTop: 8, marginRight: 8}} 
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleEdit(element.id)
                                            }}>edit</Icon>
                                    </BootstrapTooltip>
                                    <BootstrapTooltip arrow title="Remove">
                                        <Icon 
                                            fontSize="small" 
                                            style={{marginTop: 8, marginRight: 8}} 
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleRemove(element.id)
                                            }}>delete</Icon>
                                    </BootstrapTooltip>
                                </StyledTableCell>
                            </TableRow>
                        );
                    });
                }
                setCurrentRows(rows);
                setLinearLoading(false);
                closeProgressDialog();
            })
            .catch(error => {
                console.log(error);
                setLinearLoading(false);
                closeProgressDialog();
            });
        }
        setFetchPage(false);
    }, [fetchPage]);

    const sortBy = (event, property) => {
        let o = 'desc';
        if (orderBy === property && order === 'desc') {
            o = 'asc';
        }
        setOrder(o);
        setOrderBy(property);
        setGenerate(true);
    }

    return (
        <div className="Home">
            <div className="lander">
                <Grid container direction="row" spacing={1} justify="center">
                    <Grid item xl={11} lg={12} xs={12}>
                        <Paper className={classes.section}>
                            <Grid container spacin={1} alignItems="center">
                                <Grid item xs={12} lg={2}>
                                    <ItemSelection 
                                        multiple 
                                        clearable={true} 
                                        label='Property Type' 
                                        value={selectedType} 
                                        optionLabel='name' 
                                        options={types} 
                                        selected={setSelectedType}/>
                                </Grid>
                                <Grid item xs={12} lg={2}>
                                    <ItemSelection
                                        clearable={true} 
                                        optionLabel='name'
                                        label='Property' 
                                        value={selectedProperty} 
                                        labelTemplate={(option) => `${option.name} ${option.nickname ? `(${option.nickname})`: ''}`}
                                        options={properties} 
                                        selected={(value) => {
                                            setSelectedProperty(value);
                                            setGenerate(true);
                                        }}/>
                                </Grid>
                                <Grid item xs={12} lg={2}>
                                    <ItemSelection
                                        label='Agreement Status' 
                                        optionLabel='name'
                                        value={selectedStatus || null}
                                        options={agreementStatus} 
                                        selected={(value) => {
                                            setSelectedStatus(value);
                                            setGenerate(true);
                                        }}/>
                                </Grid>
                                <Grid item xs={12} lg={3}>
                                        {
                                            loadedMaster && (
                                                <div style={{float: 'right', marginTop: 8, marginRight: 8}}>
                                                    <SearchBar 
                                                        hint="Search..."
                                                        searchText={filter}
                                                        onTextChange={(e) => {
                                                        setFilter(e.target.value);
                                                        setGenerate(true);
                                                    }}/>
                                                </div>
                                            )
                                        }
                                </Grid>
                                <Grid item>
                                    <Tooltip title="Refresh">
                                        <IconButton size="medium" style={{marginTop: 8}} aria-label="Refresh" color="primary" onClick={() => {!generate && setGenerate(true)}}>
                                            <Icon>refresh</Icon>
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <Divider style={{marginBottom: 0, marginTop: 10}}/>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <div style={{minHeight: 100, overflowY: 'auto', display: 'block'}}>
                                        <Table className={classes.table} stickyHeader>
                                            <EnhancedTableHead
                                                order={order}
                                                orderBy={orderBy}
                                                onRequestSort={sortBy}
                                                rows={columns} />
                                            <TableBody>
                                                {currentRows}
                                            </TableBody>
                                        </Table>
                                    </div>
                                    {
                                        totalItems === 0 && 
                                            <Typography color="error" variant="subtitle2">No Agreements to list</Typography>
                                    }
                                    <TablePagination
                                        rowsPerPageOptions={RowCountOptions}
                                        component="div"
                                        count={totalItems}
                                        rowsPerPage={numItemsPerPage}
                                        page={currentPageNumber}
                                        backIconButtonProps={{
                                            'aria-label': 'Previous Page',
                                        }}
                                        nextIconButtonProps={{
                                            'aria-label': 'Next Page',
                                        }}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                        classes={{ selectRoot : classes.selectRoot}}/>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
            <BootstrapTooltip arrow title="Add Agreement">
                <Fab disabled={state.linearLoading} onClick={() => handleEdit(null)} color="primary" aria-label="Add" className={classes.fab}>
                    <AddIcon />
                </Fab>
            </BootstrapTooltip>
        </div>
    )
}

function DocumentPlaceHolder(props){
	// let classes = useStyles();
	// const {error} = props;
	const [link, setLink] = React.useState(null);
	const [newFile, setNewFile] = React.useState(null);

	const hiddenFileInput = React.useRef(null);

	React.useEffect(() => {
		setLink(props.link);
	}, [props.link]);

    const handleClick = () => {
        hiddenFileInput.current.click();
	}
	
	const change = (event) => {
		const fileUploaded = event.target.files[0];
		setNewFile(fileUploaded);
		// setLink(URL.createObjectURL(fileUploaded));
		props.change(fileUploaded);
	}
	


	return (
		<div>
			<ButtonGroup disableElevation size="small" color="inherit" aria-label="outlined primary button group">
                {
					link && (
						<Button onClick={() => openLink(link)}>View</Button>
					)
				}
				{
					link && (
						<Button style={{color: red[500]}} onClick={() => props.remove(false)}>Remove</Button>
					)
				}
				{
					!link && !newFile && (
						<Button onClick={handleClick}>Upload</Button>
					)
				}
			</ButtonGroup>
			<input accept="image/*,application/pdf" type="file" onChange={change} onClick={(event) =>  event.target.value = null} ref={hiddenFileInput} style={{display:'none'}} />
		</div>
	)
}