import Config from '../Config';
import {RestService} from './RestService';

export const DocumentService = {
    getList: async function(type = null){
        return RestService.get(Config.document_url.replace('{type}', type), {})
        .then(response => response.json());
    },
    addDocument: async function(type, body, headers = {}) {
        return RestService.postFormData(Config.document_url.replace('{type}', type), headers, body)
        .then(response => response.json());
    },
    removeDocument: async function(id, type, headers = {}) {
        return RestService.delete(Config.remove_document_url.replace('{type}', type).replace('{docId}', id), headers);
    },
};